.global-contact {
  .contact-title-block {
    padding: 0 ($space-xs + 2) ($space - 4);
    
    @include break-reverse(768) {
      padding: 0 ($space-xs + 2) $space-lg;
    }
  }



  /*----- form block ----- */
  .contact-form-block {
    padding: ($space - 4) ($space-xs + 2);
  
    @include break-reverse(768) {
      padding: $space-lg ($space-xs + 2);
    }
  }
  
  
  
  /*----- members block ----- */
  .contact-members {
    padding: 0 0 ($space - 4);
  }
  
  
  
  /*----- founders block ----- */
  .contact-founders {
    padding: 0 0 $space-lg;
  
    @include break-reverse(768) {
      padding: 0 0 ($space-lg * 2);
    }
  }
}