.react-widget {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: $workSans;
}

.content {
  flex: 1 1 0;

  .go-top-btn {
    @include break-reverse(768) {
      display: none;
    }
  }
}

*:focus {
  box-shadow: 0 0 0 2px $color-primary, 0 0 0 4px $color-secondary;
  outline: none;
}

.mouse-used,
.touch-used {
  * {
    outline: none;

    &:focus {
      box-shadow: none;
      outline: none!important;
    }
  }
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-text-size-adjust: none;
  color: $color-text-primary;

  * {
    margin: 0;
    padding: 0;
    font-family: $workSans;
  }
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

button {
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
  border-radius: 0;
  box-shadow: none;
  -webkit-appearance: none;
}

textarea::placeholder,
input::placeholder {
  font: 400 $fz-16 $workSans;
  color: $color-text-secondary;
  opacity: .4;
}

a {
  text-decoration: underline;
  color: $color-primary;

  &:hover {
    text-decoration: none;
    color: $color-primary;
  }
}

b {
  font-weight: 600;
}

sup {
  font-size: $fz-11 !important;
}

img {
  display: block;
  max-width: 100%;
  width: auto;
}



/*----- scroll ----- */
* {
  scrollbar-color: $color-primary rgba($color-primary, .2);
  scroll-behavior: smooth;
  -webkit-tap-highlight-color: rgba($color-black--dark, 0);

  @include break(767) {
    scrollbar-width: thin;
  }
}

*::-webkit-scrollbar {
  width: 12px;
  height: 12px;

  @include break(767) {
    width: 8px;
    height: 8px;
  }
}

*::-webkit-scrollbar-thumb {
  background: $color-primary;
  border-radius: 10px;
}

*::-webkit-scrollbar-track {
  background: rgba($color-primary, .2);
}



/*----- swiper slider ----- */
.swiper-slide {
  height: auto;

  > div {
    height: 100%;
  }
}