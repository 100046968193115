.registration-price {
  position: relative;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  padding: $space-sm $space;
  min-width: 200px;
  font-size: $fz-14;
  font-weight: 600;
  background: $color-background;
  color: $color-blue--extra;
  border: 1px solid $color-light;
  border-radius: 0 20px;
}
.registration-price__title {
  margin: 0 0 $space-xs;
  text-align: center;
  text-transform: uppercase;
}
.registration-price__separator {
  margin: 0 ($space-xs - 2);
  font-weight: 400;
}

.registration-price__rate {
  font-size: $fz-21;
  white-space: nowrap;
  color: $color-blue--dark;

  @include break(767) {
    font-size: $fz-16;
  }

  span {
    font-size: $fz-12;
    font-weight: 400;
  }
}

.registration-price__amount {
  display: flex;
  align-items: baseline;
  margin: 0 auto;
  font-size: $fz-32;

  @include break(767) {
    font-size: $fz-22;
  }

  .loader__wrapper {
    display: inline;
  }
}

.registration-price__donation {
  font-size: $fz-12;
  font-weight: 400;
  color: $color-blue--dark;

  b {
    font-size: $fz-32;
    font-weight: 600;
    color: $color-blue--extra;

    @include break(767) {
      font-size: $fz-22;
    }
  }
}

.registration-price--compact {
  .registration-price__title {
    font-size: $fz-22;
    text-transform: none;
  }

  .registration-price__amount {
    justify-content: center;
    flex-wrap: wrap;
  }

  .registration-price__donation {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}
