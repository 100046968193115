.class-details__title-wrapper {
  padding: 0 0 $space;

  .offering-title-block__title {
    padding: 0;
  }
}

.class-details {
  .card-info__image-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 (-$space-xl);
    min-height: 200px;
    background: $color-grey--soft;
    overflow: hidden;

    @include break(630) {
      margin: 0 (-$space-sm);
    }

    .icon--decorative-item {
      position: absolute;
      max-width: 200px;
      width: 100%;
      height: auto;

      &:first-child {
        left: 24px;
        bottom: 70px;
      }

      &:last-child {
        right: 24px;
        top: 70px;
      }
    }

    .card-info__image {
      z-index: 10;
    }
  }

  .card-info__columns-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .card-info__wrapper {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    margin-bottom: $space-sm;
    padding-right: $space-sm;

    &.card-info__options {
      .card-info__title {
        @include break(767) {
          display: block;
        }
      }
    }

    .card-info__title {
      @include break(767) {
        display: none;
      }
    }
  }

  .card-info__options {
    flex-wrap: wrap;
    margin: 0 -48px $space-md;
    padding: $space-md $space-xxl $space;
    background: $color-blue--soft;

    @include break(630) {
      flex-basis: 100%;
      margin: 0 (-$space-sm) $space-md;
      padding: $space-md $space-sm $space;
    }
  }
  
  .card-info__semicolumn {
    display: flex;
    flex-direction: column;
    flex-basis: 50%;

    @include break(767) {
      flex-basis: 100%;
    }
  }

  .card-info__item {
    display: flex;

    @include break(767) {
      flex-direction: column;
    }

    & + .card-info__semicolumn {
      .card-info__title {
        flex-basis: 175px;

        @include break(767) {
          flex-basis: 0;
        }
      }

      .card-info__item {
        padding-right: $space;
      }
    }
  }

  .card-info__item--full-width {
    width: 100%;

    .card-info__title {
      flex-basis: 175px;

      @include break(767) {
        flex-basis: 0;
      }
    }
  }

  .card-info__title,
  .card-info__description {
    display: inline-block;
    margin: 0 0 $space-sm;
    font-size: $fz-16;
    line-height: 1.5;

    img {
      display: inline-block;
    }
  }

  .card-info__title {
    flex: 0 0 110px;
    font-weight: 600;

    @include break(767) {
      flex: 0;
    }
  }

  .card-info__description {
    font-weight: 400;
  }
}

.class-details__contractor {
  margin: $space-xs 0 0;
  display: block;
  font-size: $fz-14 !important;
  line-height: 1.5;
}