.donation {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 300px;
  color: $color-text-primary
}

.donation__banner {
  width: 200px;
  height: 200px;
}

.donation__title {
  margin: 0 0 $space-sm;
  text-align: center;
}

.donation__subtitle {
  margin: 0 0 $space;
  font-size: $fz-14;
  text-align: center;
}

.donation__amount {
  margin: 0 0 $space;
  width: 100%;
}

.donation__anonymous {
  display: flex;
  align-items: flex-end;
  margin: 0 0 $space;
  width: 100%;
  font-size: $fz-14;

  .tooltip {
    margin: 0 0 0 ($space-xs + 2);
  }

  .tooltip__wrapper {
    left: 7% !important;
    padding: ($space-xs + 2);
    min-width: 320px;
    font-size: $fz-12;

    @include break(800) {
      min-width: 260px;
    }
  }
}

.donation__login {
  margin: 0 0 $space;
  font-size: $fz-14;

  .btn {
    display: inline;
  }
}

.donation__note {
  margin: 0 0 $space-md;
  padding: ($space-xs + 4);
  font-size: $fz-12;
  line-height: 1.5;
  background: $color-blue--soft;
}

.donation__actions {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.donation__payment-selector {
  width: 100%;
}

.donation__proceed-button {
  margin: 0 0 0 auto;
}