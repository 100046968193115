.offering-details__main-info {
  .offering-main__body {
    display: flex;
    flex-direction: column;
    margin: 0 0 $space-lg;

    @include break-reverse(768) {
      flex-direction: row;
      margin: 0 0 ($space-lg * 2);
    }
  }

  .offering-main__top {
    margin: 0 0 $space;
    height: fit-content;

    @include break-reverse(768) {
      margin: 0;
      min-width: 320px;
      max-width: 320px;
      background: $color-background;
      border-radius: 50px 0;
      box-shadow: 0 4px 18px 0 rgba($neutral--30, .5);
    }

    @include break-reverse(1050) {
      min-width: 420px;
      max-width: 420px;
    }

    .offering-main__options {
      display: flex;
      flex-wrap: wrap;
      margin: 0 (-$space-xs);
  
      @include break-reverse(768) {
        padding: $space $space 0;
      }
  
      @include break-reverse(1050) {
        padding: $space-lg $space-lg $space-sm;
      }
  
      .options-item {
        padding: 0 $space-xs;
        width: 100%;
      }
  
      .options-item--age-range,
      .options-item--type,
      .options-item--participant-level,
      .options-item--enrollment-type {
        @include break-and-break-reverse(576, 767) {
          width: 50%;
        }
  
        @include break-reverse(1050) {
          width: 50%;
        }
      }
    }
  }

  .offering-main__visual {
    background: $color-light;
    border-radius: 50px 0;
    overflow: hidden;

    @include break(767) {
      margin: 0 0 $space-lg;
      border-radius: 50px 0 0 0;
    }
  }

  .offering-main__image,
  .offering-main__image-stub {
    width: 100%;
    height: 100%;
  }

  .offering-main__image-stub {
    height: 325px;

    @include break-reverse(768) {
      height: 400px; 
    }

    @include break-reverse(1050) {
      height: 450px; 
    }
  }

  .offering-main__options {
    .options-item {
      margin: 0 0 $space-sm;

      @include break-reverse(768) {
        margin: 0 0 $space;
      }
    }

    .options-item__title {
      margin: 0 0 $space-xs;
      font-weight: 600;
    }

    .options-item__description {
      > span {
        color: $color-text-primary;
      }

      .btn,
      .link {
        &:hover {
          span {
            color: $color-secondary;
          }
        }

        span {
          font-size: $fz-16;
          line-height: 1.25;
          color: $color-primary;

          &:not(.cdm-text--semibold) {
            font-weight: 400;
          }
        }
      }
    }
  }

  .offering-main__bottom {
    width: 100%;

    @include break-reverse(768) {
      margin: 0 0 0 $space-lg;
    }

    @include break-reverse(1050) {
      margin: 0 0 0 6vw;
    }

    @include break-reverse(1441) {
      margin: 0 0 0 ($space-lg * 2);
    }

    .offering-main__options {
      position: relative;
      display: flex;
      flex-direction: column;
      padding: $space 0 0;
      background: $color-light;

      @include break-reverse(768) {
        padding: $space $space 0;
        background: $color-background;
        border-radius: 50px 0;
        box-shadow: 0 4px 18px 0 rgba($neutral--30, .5);
      }
  
      @include break-reverse(1050) {
        flex-direction: row;
        padding: $space-lg $space-lg 0;
      }
  
      &:before {
        @include break(767) {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          right: 50%;
          transform: translate(50%, 0);
          width: 100vw;
          background: $color-light;
        }
      }
  
      & > * {
        position: relative;
      }
  
      .options-item {
        @include break(1049) {
          margin: 0 0 $space;
        }
      }
  
      .options-item--upcoming {
        @include break-reverse(1050) {
          width: 35%;
          white-space: nowrap;
        }
      }
  
      .options-item--location {
        @include break-reverse(1050) {
          padding: 0 0 0 $space-sm;
          width: 65%;
        }
      }
  
      .options-item__title {
        display: flex;
        align-items: center;

        .icon {
          margin: 0 ($space-xs + 2) 0 0;
        }
      }
  
      .options-item__description {
        > span {
          color: $color-text-primary;
        }
  
        .btn {
          &:not(.options-item__button) {
            &:hover {
              span {
                color: $primary--70;
              }
            }
    
            span {
              color: $color-text-primary;
            }
          }
        }

        .options-item__button {
          margin: $space-sm 0 0;
        }
      }
    }
  }

  .offering-main__title {
    margin: 0 0 ($space-sm - 4);
    font-size: $fz-20;
    font-weight: 600;

    @include break-reverse(768) {
      margin: 0 0 $space-sm;
      font-size: $fz-30;
    }
  }

  .offering-main__description {
    margin: $space-lg 0 0;

    @include break-reverse(768) {
      padding: 0 0 0 $space;
    }

    @include break-reverse(1050) {
      padding: 0 0 0 3vw;
    }

    @include break-reverse(1441) {
      padding: 0 0 0 $space-lg;
    }

    h2 {
      margin: 0 0 $space-md;
      font-size: $fz-20;
      font-weight: 600;
      line-height: 1.35;
    
      @include break-reverse(768) {
        font-size: $fz-30;
      }

      &:not(:first-of-type) {
        margin: $space-lg 0 $space-md;

        @include break-reverse(768) {
          margin: $space-xxl 0 $space-md;
        }
      }
    }
  
    h3 {
      margin: 0 0 $space-sm;
      font-size: $fz-16;
      font-weight: 600;
      line-height: 1.25;
    
      @include break-reverse(768) {
        font-size: $fz-20;
      }

      &:not(:first-of-type) {
        margin: $space-md 0 $space-sm;

        @include break-reverse(768) {
          margin: $space-lg 0 $space-sm;
        }
      }
    }

    p {
      margin: 0 0 $space-xs;
    }
  
    li {
      position: relative;
      padding: 0 0 0 ($space - 4);
  
      &:not(:last-of-type) {
        margin: 0 0 $space-xs;
      }
  
      &:before {
        content: "";
        position: absolute;
        top: 7.5px;
        left: 9px;
        width: 4px;
        height: 4px;
        background: $neutral--80;
        border-radius: 50%;
      }
    }

    a {
      cursor: pointer;
  
      &:hover {
        color: $color-secondary;
      }
    }
  }

  .offering-main__sponsors {
    padding: 0 0 $space-lg;

    @include break-reverse(768) {
      padding: 0 0 ($space-lg * 2);
    }

    .offering-main__title {
      margin: 0 0 $space-xs;
    }

    .sponsors-block__buttons {
      padding: 0;
    }
  }
}