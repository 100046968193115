.table {
  position: relative;
  overflow: hidden;
}

.table__container {
  overflow-x: auto;
  overflow-y: hidden;
}

.table__content {
  display: inline-flex;
  flex-direction: column;
  min-width: 100%;
}

.table__row {
  display: flex;

  &:first-child {
    .table__cell {
      border-top: 1px solid $color-black--dark;
    }
  }
}

.table__row--title-sticky {
  position: absolute;
  z-index: 4;
  left: 0;
  top: 0;
  will-change: transform, top, left;
  transition: top .2s ease-out;

  @media print {
    transform: none!important;
  }
}

.table__cell {
  display: flex;
  align-items: center;
  flex: 1 1 0;
  padding: $space-xs;
  min-width: 120px;
  border-right: 1px solid $color-black--dark;
  border-bottom: 1px solid $color-black--dark;

  &:first-child {
    border-left: 1px solid $color-black--dark;
  }
}

.table__cell-content {
  text-overflow: ellipsis;
  overflow: hidden;
}

.table--curved {
  margin: (-$space-sm - 4);

  .table__container {
    padding: ($space - 4);
  }

  .table__row {
    margin-bottom: ($space-xs + 4);
    background: $color-background;
    border: 1px solid $color-blue;
    border-radius: $b-rad-table-curved-row 0;
    box-shadow: 0px 4px 20px rgba($color-grey--medium, .5);

    &:first-child {
      .table__cell {
        border: none;
      }
    }
  }

  .table__row--title {
    margin-bottom: 0;
    background: transparent;
    border: none;
    border-radius: 0;
    box-shadow: none;
  }

  .table__cell {
    border: none;
  }
}