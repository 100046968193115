.account-modal-slider {
  .account-modal {
    max-width: 100%;
  }
}

.account-modal-slider__buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 $space-sm;

  .icon {
    position: relative;
    width: 40px;
    height: 16px;
    fill: $primary--40;
  }
}

.account-modal-slider__counter {
  margin: 0 ($space-xs + 2);
}

.account-modal-slider__prev-button,
.account-modal-slider__next-button {
  transition: all .3s ease;
  cursor: pointer;
  
  &:not(.swiper-button-disabled):hover {
    .icon {
      fill: $color-primary;
    }
  }
}

.account-modal {
  margin: 0 auto;
  max-width: 404px;
  width: 100%;
  font-size: $fz-14;
  background: $color-background;

  @include break-reverse(768) {
    padding: $space $space-md;
    border: 1px solid $color-grey--darkish;
    border-radius: 8px;
  }
}

.account-modal--directions {
  margin: $space 0 0;
  padding: $space $space-md;
  line-height: 1.5;
  background: $color-blue--lightish;
  border-radius: 16px;
}

.account-modal__title {
  margin: $space-md 0 $space-sm;
}

.account-modal__block {
  margin: 0 0 $space;
}

.account-modal__row {
  display: flex;
  justify-content: space-between;
  margin: 0 0 $space-xs;
}

.account-modal__label {
  margin: 0 $space-sm 0 0;
  white-space: nowrap;
  opacity: .6;
}

.account-modal__value {
  text-align: end;

  div + div {
    margin: $space-min 0 0;
  }
}

.account-modal__description {
  line-height: 1.5;
}

.account-modal__actions {
  display: flex;
  justify-content: flex-end;
}

.account-modal__print-button {
  margin: $space 0 0;
  padding: $space-xs ($space - 4);
  font-size: $fz-14;
  font-weight: 600;
  background: $color-primary;
  color: $color-text-light;

  &:hover {
    background: $color-secondary;
  }

  .icon {
    fill: $color-background;
  }
}

.account-modal__print-button--save {
  margin: $space 0 0;

  @include break-reverse(768) {
    margin: $space 0 0 $space-md;
  }
}