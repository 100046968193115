.global-providers {
  padding: 0 0 $space-sm;

  @include break-reverse(768) {
    padding: 0 0 ($space-xxl);
  }



   /*----- title block ----- */
  .providers-title-block,
  .providers-title-block__title {
    margin: 0 0 $space;

    @include break-reverse(768) {
      margin: 0 0 $space-lg;
    }
  }

  .providers-title-block__actions {
    display: flex;
    flex-direction: column;

    @include break-reverse(481) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .providers-title-block__search {
    margin: 0 0 $space;
    width: 100%;

    @include break-reverse(481) {
      margin: 0;
      max-width: 294px;
    }
  }

  .providers-title-block__sorting-menu {
    align-self: flex-end;
    width: fit-content;
  }

  .providers-title-block__sorting {
    display: flex;
  }

  .providers-title-block__sorting-select {
    min-width: 255px;
    width: 100%;
  }

  .providers-title-block__sorting-button {
    margin: 0 0 0 $space-sm;

    .icon {
      width: 31px;
      height: 17px;
    }
  }



  /*----- items block ----- */
  .providers-items {
    display: flex;
    flex-direction: column;

    @include break-reverse(576) {
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0 (-$space-sm + 4);
    }
  }

  .providers-item {
    margin: 0 0 $space;

    @include break-reverse(576) {
      padding: 0 ($space-sm - 4);
      width: 50%;
    }

    @include break-reverse(992) {
      width: calc(100% / 3);
    }

    .providers-card {
      overflow: hidden;
    }

    .providers-card__visual {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 175px;
      max-height: 175px;
      background: $primary--20;
      border-radius: 50px 0;
      overflow: hidden;

      @include break-reverse(576) {
        min-height: 200px;
        max-height: 200px;
      }

      @include break-reverse(992) {
        min-height: 225px;
        max-height: 225px;
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    .providers-card__content {
      display: flex;
      flex-direction: column;
      padding: $space $space-sm $space;
      height: 100%;

      @include break-reverse(992) {
        padding: $space;
      }
    }

    .providers-card__title,
    .providers-card__description {
      margin: 0 0 $space-sm;
    }

    .providers-card__title {
      .link {
        span {
          font-size: $fz-20;
          word-break: normal;
        }
      }
    }

    .providers-card__link {
      margin: auto 0 0;
    }
  }
}