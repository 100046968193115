.videos {
  margin: 0 0 $space-md;
}

.videos__title-block {
  display: flex;
  flex-direction: column;
  margin: 0 0 $space-sm;

  @include break-reverse(576) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  h3 {
    font-size: 24px;
    font-weight: 600;
    line-height: 2;

    @include break(575) {
      margin: 0 0 $space-xxs;
    }
  }
}

.video-gallery--old {
  padding: 0 0 $space-lg;

  h3 {
    font-size: 24px;
    font-weight: 600;
  }

  .video-gallery__slide {
    display: flex;
    justify-content: center;
  }
  
  .video-gallery__title {
    font-size: $fz-24;
    font-weight: 600;
    color: $color-text-primary;
  }
  
  .video-gallery__slider {
    position: relative;
    padding: $space-lg 0 ($space-md - 2);
  
    .swiper-button-prev,
    .swiper-button-next {
      position: absolute;
      top: 10px;
      left: auto;
      right: 85px;
      background: url('../../../assets/video-slider-prev-arrow.svg') center / 100% auto no-repeat;
  
      &:after {
        display: none;
      }
    }
  
    .swiper-button-next {
      right: 40px;
      background-image: url('../../../assets/video-slider-next-arrow.svg');
    }
  
    .swiper-pagination-fraction {
      position: absolute;
      top: 0;
      right: 143px;
      left: auto;
      max-width: 50px;
    }
  }
  
  .video-gallery__card {
    max-width: 792px;
    width: 100%;
    background: $color-background;
    border: 3px solid $color-grey--soft;
    border-radius: 0 40px;
    box-shadow: 0 4px 20px rgba($color-blue--darkish, .25);
    box-sizing: border-box;
    overflow: hidden;
  
    .video-gallery__video {
      position: relative;
      width: 100%;
      background: $color-black;
      border: 0 40px 0 0;
    }
  
    .video-gallery__video--vimeo {
      padding: 56.25% 0 0 0;
  
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  
    .video-gallery__info {
      display: flex;
      flex-direction: column;
      padding: ($space-xs + 2) $space ($space-md - 2);
      min-height: 162px;
  
      @include break-reverse(768) {
        padding: $space $space-lg ($space-md - 2);
      }
    }
  
    .video-gallery__title {
      margin: 0 0 8px;
      font-size: $fz-18;
      font-weight: 600;
      line-height: 1.5;
      color: $color-black;
    }
  
    .video-gallery__subtitle {
      font-size: $fz-16;
      line-height: 1.5;
      color: $color-black;
  
      a {
        font-size: inherit;
      }
    }
  }
}


.video-gallery--new {
  position: relative;
  padding: 0 0 $space;

  @include break-reverse(768) {
    padding: 0 0 $space-lg;
  }

  @include break-reverse(1050) {
    padding: 0 0 ($space-xxxl - 4);
  }

  .video-gallery__slide {
    display: flex;
    justify-content: center;
  }
  
  .video-gallery__title {
    font-size: $fz-24;
    font-weight: 600;
    color: $color-text-primary;
  }
  
  .video-gallery__slider {
    position: relative;
  
    .swiper-button-prev,
    .swiper-button-next {
      position: absolute;
      top: 10px;
      left: auto;
      right: 85px;
      background: url('../../../assets/video-slider-prev-arrow.svg') center / 100% auto no-repeat;
  
      &:after {
        display: none;
      }
    }
  
    .swiper-button-next {
      right: 40px;
      background-image: url('../../../assets/video-slider-next-arrow.svg');
    }
  }
  
  .video-gallery__card {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
  
    @include break-reverse(992) {
      flex-direction: row;
    }
  
    .video-gallery__video-wrapper {
      margin: 0 0 $space;
      width: 100%;
  
      @include break-reverse(992) {
        margin: 0;
        width: 66%;
      }
    }
  
    .video-gallery__video {
      position: relative;
      background: $color-black;
      border-radius: 10px;
      overflow: hidden;
  
      iframe
      img  {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
  
      .video-gallery__thumb-badge {
        transform: translate(30px, -20px);
      }
    }
  
    .video-gallery__info {
      display: flex;
      flex-direction: column;
      width: 100%;
  
      @include break-reverse(992) {
        padding: 0 0 0 ($space + 4);
        width: 29%;
      }
    }
  
    .video-gallery__title {
      font-size: $fz-20;
      font-weight: 600;
      line-height: 1.5;
      color: $color-black;
  
      @include break-reverse(992) {
        margin: 0 0 $space-xs;
      }
    }
  
    .video-gallery__subtitle {
      font-size: $fz-16;
      line-height: 1.5;
      color: $color-black;
  
      a {
        font-size: inherit;
      }
    }
  
    .videos__btn {
      margin: $space 0 0;
      padding: ($space-xs + 1) ($space - 4);
      max-width: 160px;
      width: 100%;
      font-size: $fz-12;
      line-height: 1;
  
      @include break-reverse(992) {
        padding: ($space-xs + 4) ($space - 4);
        max-width: 180px;
        font-size: $fz-14;
      }
    }
  }
  
  .video-gallery__thumbs {
    margin: 0 0 $space;
    width: 100%;
  
    @include break-reverse(992) {
      position: relative;
      margin: 0 0 ($space + 4);
    }
  }
  
  .video-gallery__thumb {
    position: relative;
    border-radius: 6px;
    overflow: hidden;
    cursor: pointer;
  }
  
  .video-gallery__thumb-badge {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translate(5px, -5px);
    padding: $space-xxs $space-xs;
    font-size: $fz-11;
    font-weight: 600;
    white-space: nowrap;
    color: $color-text-light;
    background: $color-background;
    border: 1px solid;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .video-gallery__thumb-badge--free,
  .video-gallery__thumb-badge--common {
    color: $color-secondary;
    border-color: $color-secondary;
  }
  
  .video-gallery__thumb-badge--paid {
    color: $color-primary;
    border-color: $color-primary;
  }
  
  .video-gallery__buttons {
    @include break(991) {
      display: flex;
      align-items: center;
      column-gap: $space;
      margin: $space 0 0;
    }
  }
  
  .video-gallery__prev-button,
  .video-gallery__next-button {
    &.swiper-button-prev, 
    &.swiper-button-next {
      margin: 0;
      width: $space-sm;
      height: $space-sm;
  
      @include break(991) {
        position: static;
      }
  
      &:after {
        font-size: $fz-16;
        font-weight: 700;
        color: $color-primary;
      }
    } 
  }
  
  .video-gallery__prev-button {
    &.swiper-button-prev {
      @include break-reverse(992) {
        left: 0;
        transform: translate(-120%, -50%);
      }
    } 
  }
  
  .video-gallery__next-button { 
    &.swiper-button-next {
      @include break-reverse(992) {
        right: 0;
        transform: translate(120%, -50%);
      }
    } 
  }
}


.video__card {
  display: flex;
  margin: 0 0 ($space-sm - 4);
  padding: ($space-md - 2);
  width: 100%;
  background: $color-background;
  border: 1px solid $color-blue;
  box-shadow: 0 2px 18px rgba($color-text-primary, .08);
  box-sizing: border-box;
  border-radius: 25px 0;
  overflow: hidden;

  @include break(767) {
    flex-direction: column;
    padding: $space;
  }

  &:last-of-type {
    margin: 0;
  }
}

.video__card--expanded {
  flex-direction: column;
  padding: 0 0 $space;
  border-radius: 12px 12px 25px 0;

  @include break-reverse(768) {
    padding: ($space-md - 2);
    border-radius: 25px 0;
  }

  .video__preview {
    position: relative;
    width: 100%;
    height: 100%;
    background: $color-black;
    border-radius: 12px 12px 0 0;
    overflow: hidden;

    @include break-reverse(768) {
      border-radius: 25px 0;
    }
  }

  .video__preview--vimeo {
    padding: 56.25% 0 0 0;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .video__description {
    margin: 0;
    padding: ($space-sm - 4) $space 0;
    max-width: 100%;
    line-height: 1.5;

    @include break-reverse(768) {
      padding: $space 0 0;
    }
  }

  .video__title, 
  .video__text {
    @include break-reverse(768) {
      max-width: 100%;
    }
  }
}

.video__preview-image {
  position: relative;
  margin: 0 0 ($space-xs + 4);
  width: 100%;
  height: 158px;
  cursor: pointer;

  @include break-reverse(768) {
    margin: 0;
    width: 203px;
    height: 168px;
  }

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 25px 0 25px 0;
    transition: all .3s ease-in;
  }
}

.video__description {
  position: relative;
  width: 100%;

  @include break-reverse(768) {
    margin: 0 0 0 $space;
  }

  a {
    font-size: inherit;
  }

  .details__button {
    position: absolute;
    bottom: 0;
    height: 20px;

    @include break-reverse(768) {
      height: 30px;
    }
  }
}

.video__title {
  margin: 0 0 $space-xs;
  font-size: $fz-18;

  @include break-reverse(768) {
    max-width: 570px;
  }
}

.video__subtitle {
  font-size: $fz-16;
  font-weight: 400;

  a {
    font-size: inherit;
  }
}

.video__text {
  margin: ($space-xs - 2) 0 0;
  padding: 0 0 $space-lg;

  @include break-reverse(768) {
    padding: 0 0 $space-xl;
    max-width: 570px;
  }

  & > p {
    font-size: $fz-14;
    line-height: 142.8%;
    color: $color-black;
  }
}

.video__logo {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 20px;

  @include break-reverse(768) {
    height: 30px;
  }
  
  a {
    display: inline-block;
  }
}

.videos__btn {
  padding: ($space-sm - 4) $space;
  width: fit-content;
  font-size: $fz-14;
}

.buying-video {
  .registration-step__total {
    margin: 0 0 ($space - 4);
  }
}

.buying-video__title {
  font-size: $fz-20;
  font-weight: 600;
  line-height: 1.2;
  color: $color-primary;
}

.buying-video__cards {
  position: relative;
  margin: $space 0 $space-xl;

  .error__wrapper {
    bottom: -24px;
  }
}

.buying-video__card {
  position: relative;
  display: flex;
  margin: 0 0 ($space-sm - 4);

  &:last-of-type {
    margin: 0;
  }

  & > .video__card {
    margin: 0 0 0 ($space-sm - 5);

    @include break(767) {
      width: 84%;
    }
  }

  .checkbox__container {
    top: 45px;
  }
}

.buying-video__rates {
  position: relative;

  .error__wrapper {
    bottom: -24px;
  }

  .columns__item {
    @include break(680) {
      margin: 0 0 ($space-sm - 2);
      width: calc(100% / 2);
    }
  }
}