.account {
  padding: 0 0 $space-md;
}

.account__header {
  .slide-menu.slide-menu .slide-menu__inner .slide-menu__body {
    padding: $space-xxl 0 0 ;
    width: 100%;
    overflow-x: hidden;

    @include break(1049) {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  
    &::-webkit-scrollbar {
      @include break(1049) {
        width: 0;
        height: 0;
      }
    }

    > div {
      width: 100%;
    }

    .tabs__container {
      width: 100%;
    }

    .account__tabs {
      padding: 0 0 0 $space-sm;

      .tabs__item {
        color: $color-grey;

        &:hover {
          color: $color-grey;
        }
      }
    }

    .tabs__item,
    .btn--navigation {
      margin: 0 0 $space;
      padding: 0 $space 0 ($space-md + 8);
      font-size: $fz-16;

      span {
        font-size: $fz-16;
      }
    }

    .tabs__item {
      &:hover {
        color: $color-text-primary;
      }
    }

    .tabs__item--active {
      color: $color-text-primary;
    }

    .account-opener {
      display: flex;
      justify-content: space-between;
      width: 100%;
      cursor: auto;

      &:hover {
        .span {
          color: $color-text-primary;
        }
      }

      span {
        color: $color-text-primary;
      }

      svg {
        fill: $color-text-primary;
      }
    }

    .list__item--log-out {
      .btn--navigation {
        &:hover {
          .btn__content {
            color: $color-primary;
          }

          svg {
            fill: $color-primary;
          }
        }
      }
    }
  }

  .tabs__container {
    margin: 0;
  }

  .list {
    @include break(1049) {
      padding: $space-md 0 0;
      border-top: 1px solid $color-blue--medium;
    }

    @include break-reverse(1050) {
      display: flex;
      align-items: center;
    }
  }

  .list__item {
    & + .list__item {
      @include break-reverse(1050) {
        margin: 0 0 0 2vw;
      }

      @include break-reverse(1441) {
        margin: 0 0 0 $space-lg;
      }
    }
  }

  .list__item--donation {
    svg {
      width: 17px;
    }
  }

  .list__item--user {
    button {
      cursor: auto;

      span {
        color: $color-text-primary;
      }

      svg {
        fill: $color-text-primary;
      }

      &:hover {
        span {
          color: $color-text-primary;
        }

        svg {
          fill: $color-text-primary;
        }
      }
    }
  }
}

.account__columns {
  flex-direction: column;
  align-items: center;
  margin: 0;

  @include break-reverse(1050) {
    flex-direction: row;
    align-items: flex-start;
  }
}

.account__navigation-column {
  display: flex;
  flex-direction: column;

  @include break(1049) {
    padding: 0;
  }

  @include break-reverse(1050) {
    max-width: 348px;
    padding: 0 ($space - 4) 0 0;
  }
}

.account__content-column {
  flex-grow: 1;
  padding: 0;

  @include break-reverse(1050) {
    padding: 75px 0 0;
    width: calc(100% - 348px);
  }
}

.account-navigation--scroll {
  @include break-reverse(1050) {
    position: fixed;
    top: 32px;
    max-width: 328px;
    width: 100%;
  }
}

.account__title {
  margin: 0 0 $space;

  @include break-reverse(768) {
    margin: 0 0 $space-md;
  }
}

.account__tabs {
  display: flex;
  flex-direction: column;
  align-items: start;

  .tabs__item {
    display: flex;
    align-items: center;
    padding: 0;
    width: 100%;
    font-size: $fz-16;
    font-weight: 600;
    text-align: left;
    transition: $transition-time;

    @include break-reverse(1050) {
      margin: 0;
      padding: ($space - 4) 0 $space ($space - 4);
    }

    &:not(.tabs__item--active):hover {
      @include break-reverse(1050) {
        padding: ($space - 4) 0 $space $space-lg;
        color: $color-primary;
        border-radius: 10px;
      }

      &:before {
        content: none;
      }

      svg {
        @include break-reverse(1050) {
          fill: $color-primary;
        }
      }
    }
  }

  .tabs__item--active {
    @include break-reverse(1050) {
      background: $color-light;
      color: $color-primary;
      border-radius: 10px;
    }

    svg {
      @include break-reverse(1050) {
        fill: $color-primary;
      }
    }
  }
}

.account__tab-icon {
  margin: 0 $space-sm 0 0;

  svg {
    fill: $color-text-primary;
  }
}

.account__content-title {
  margin: 0 0 $space-md;
  font-size: $fz-20;
  font-weight: 600;

  @include break-reverse(768) {
    font-size: $fz-24;
    line-height: 1;
  }
}

.account__no-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 26px;

  .icon {
    margin: 0 0 $space-md;
    width: 280px;
    height: 280px;
  }

  p {
    max-width: 550px;
    font-size: $fz-20;
    font-weight: 600;
    text-align: center;
  }
}

.account__more-button {
  margin: 0 0 0 auto;
}