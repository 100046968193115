.schedule {
  .tabs__container {
    margin: 0 0 $space;

    @include break-reverse(1050) {
      margin: 0 0 $space-lg;
    }

    @include break-reverse(1441) {
      margin: 0 0 $space;
    }
  }
}

.schedule__title {
  margin: 0 0 $space-sm;
}

.schedule__instructors-navigation {
  .tabs__container {
    margin: $space-lg (-$space-xs) 0;
  }
}