.form__group {
  position: relative;
  margin: 0 0 $space;

  & .form__group {
    margin: $space-sm 0 $space !important;
    padding: 0 0 0 ($space-sm * 1.75);
  }
}

.form__group--radio {
  display: flex;
  
  .radio + .radio {
    margin-left: $space-xxl;
  }
}

.form__group--checkox {
  .checkbox_label {
    .btn {
      display: inline-flex;

      span {
        font-size: $fz-14;
      }
    }
  }
}

.form__checkbox-wrapper {
  display: flex;

  .form__checkbox-btn {
    margin-right: $space-xs;

    .icon {
      fill: $color-primary;
    }
  }

  .form__checkbox-btn--open {
    transform: rotate(180deg);
  }
}

.form__checkbox-wrapper--no-button {
  padding: 0 0 0 ($space - 4);
}

.form__section {
  margin: 0 0 $space-md;
}

.form__group-title {
  position: relative;
  display: inline-block;
  margin-bottom: ($space-xs + 4);
  font-size: $fz-14;
  line-height: 1.14;
  font-weight: 600;

  .form__group-title-hint {
    margin-top: $space-xs;
  }
}

.form__group-title--big {
    font-size: $fz-21;
  }

.form__group-title-action {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-width: 70px;
}

.form__group-title-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: $space-sm;

  .form__group-title {
    margin-bottom: 0;
  }
}

.form__group-title-hint {
  display: block;
  font-size: $fz-12;
  font-weight: 400;
  line-height: 1.3333;
  color: $color-input-hint;
}

.form__hint {
  margin-top: $space-xxs;
  margin-bottom: $space-md;
  font-size: $fz-12;
  line-height: 1.35;
  color: $color-input-hint;
}

.form__button--add-phone {
  position: absolute;
  bottom: 48px;
  right: 5px;
  opacity: .5;
  transition: opacity .3s linear;

  &:hover {
    opacity: 1;
  }
}