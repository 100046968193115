.loader__wrapper {
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader__wrapper--global {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba($color-background, .7);
}

.loader__wrapper--attached,
.loader__wrapper--sticky {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba($color-background, .7);
}

.loader__wrapper--sticky {
  display: block;

  .loader {
    position: sticky;
    position: -webkit-sticky;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.loader {
  display: inline-block;
  width: $loader-w;
  height: $loader-h;
}

.loader--xs {
  width: $loader-w-xs;
  height: $loader-h-xs;
}

.loader--md {
  width: $loader-w-md;
  height: $loader-h-md;
}

.loader--lg {
  width: $loader-w-lg;
  height: $loader-h-lg;
}

.loader--xl {
  width: $loader-w-xl;
  height: $loader-h-xl;
}

.loader--payment-processing {
  position: absolute;
  bottom: $space-xs;
  left: $space-xs;
}
