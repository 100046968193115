/*----- base styles ----- */
.contact-title-block,
.contact-members,
.contact-founders {
  h3 {
    font-size: $fz-20;
    line-height: 1.3;
  }
} 



/*----- title block ----- */
.contact-title-block {
  p {
    font-size: $fz-20;
    line-height: 1.3;
  }
}
  
.contact-title-block__title {
  margin: 0 0 $space;
}

.contact-title-block__item {
  &:not(:last-of-type) {
    margin: 0 0 $space-lg;
  }
}
  
.contact-title-block__contact-title {
  margin: 0 0 $space-xs;
  font-weight: 400;
  color: $neutral--70;
  
  @include break-reverse(768) {
    margin: 0 0 $space-sm;
  }
}
  
.contact-title-block__organization {
  margin: 0 0 $space-md;

  @include break-reverse(768) {
    margin: 0 0 $space-lg;
  }
  
  h3 {
    font-weight: 600;
    color: $color-primary;
    overflow: hidden;
  
    span {
      position: relative;
      padding: 0 $space 0 0;
      width: fit-content;
  
      &:before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 50%;
        right: 0;
        transform: translate(100%, -50%);
        width: 100vw;
        height: 1px;
        background: $primary--30;
      }
    }
  }
  
  p {
    margin: $space-sm 0 0;
    max-width: 340px;
    font-size: $fz-16;

    @include break-reverse(768) {
      margin: $space 0 0;
      max-width: 530px;
      font-size: $fz-20;
    }
  }
}
  
.contact-title-block__contacts-body {
  display: flex;
  flex-direction: column;
  
  @include break-reverse(768) {
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -2%;
  }
}
  
  .contact-title-block__contacts-item {
    display: flex;
    flex-direction: column;
  
    @include break-reverse(768) {
      padding: 0 2%;
      width: 50%;
    }
  
    @include break-reverse(992) {
      width: 25%;
    }
  
    &:not(:last-of-type) {
      margin: 0 0 $space;

      @include break-reverse(768) {
        margin: 0 0 $space-lg;
      }
    }
  
    .contact-card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  
    .contact-card__content {
      font-weight: 600;
  
      .link {
        span {
          font-size: $fz-20;
          color: $color-text-primary;
        }

        &:hover {
          span {
            color: $primary--70;
          }
        }
      }
    }
  }

.contact-title-block__contacts-item--socials {
  .contact-card__content {
    display: flex;
    margin: 0 (-$space-xs);

    .link {
      margin: 0 $space-xs;
    }
  }
}



/*----- members block ----- */
.contact-members {
  padding: 0 0 ($space - 4);
}

.contact-members__slider {
  padding: ($space - 4) ($space-xs + 2) $space-md;

  @include break-reverse(768) {
    padding: $space-lg ($space-xs + 2);
  }
}

.contact-members__slide {
  display: flex;
  flex-direction: column;

  .member-card {
    padding: $space $space-sm;

    @include break-reverse(768) {
      padding: $space-md $space-lg;
    }
  }

  .member-card__title {
    margin: 0 0 $space-sm;
  }

  .member-card__description {
    margin: 0 0 $space;

    span {
      display: inline;
    }
  }

  .member-card__contacts {
    margin: auto 0 0;

    .link {
      display: flex;

      &:hover {
        .icon {
          fill: $color-secondary;
        }
      }

      &:not(:last-of-type) {
        margin: 0 0 $space-sm;
      }

      span {
        font-size: $fz-16;
      }

      .icon {
        fill: $color-primary;
      }
    }
  }
}



/*----- founders block ----- */
.contact-founders__slider {
  padding: ($space - 4) ($space-xs + 2) $space-md;

  @include break-reverse(768) {
    padding: ($space - 4) ($space-xs + 2) $space-lg;
  }

  .swiper-wrapper {
    align-items: center;
  }
}

.contact-founders__slide {
  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 0;
    transform: translate(-12px, -50%);
    width: 1px;
    height: 42px;
    background: $primary--40;
  }
  
  .founder-card {
    padding: 0 ($space-md - 2);
  }

  .founder-card__title {
    margin: 0 0 $space-xs;
  }
}



/*----- form block ----- */
.contact-form-block__body {
  display: flex;
  flex-direction: column;
  background: $color-background;
  box-shadow: 0 4px 20px rgba(#cdd1d9, .35);
  border-radius: 50px 0px;
  overflow: hidden;

  @include break-reverse(768) {
    flex-direction: row;
  }
}

.contact-form-block__visual {
  @include break-reverse(768) {
    width: 42%;
  }
  
  @include break-reverse(1441) {
    max-height: 590px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    // filter: grayscale(100%);
  }
}

.contact-form-block__content {
  display: flex;
  flex-direction: column;
  padding: $space-lg $space-sm;

  @include break-reverse(768) {
    width: 58%;
    padding: 2.6vw 4.2vw;
  }
  
  @include break-reverse(1441) {
    padding: $space-lg $space-xxxl;
  }
}

.contact-form-block__title {
  margin: 0 0 $space-md;

  @include break-reverse(768) {
    margin: 0 0 $space-xl;
  }

  span {
    display: block;
  }
}

.contact-form-block__form {
  height: 100%;

  .contact-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    input,
    textarea {
      padding: $space-xs 0;
      font-size: $fz-16;
      line-height: 1.5;
      color: $color-text-primary;
      border-color: $primary--30;

      &::placeholder {
        font-size: inherit;
        line-height: inherit;
        color: $neutral--70 !important;
      }
    }
  }

  .contact-form__group {
    margin: 0 0 $space;

    @include break-reverse(768) {
      margin: 0 0 $space-md;
    }
  }
  
  .contact-form__group--second {
    display: flex;
    flex-direction: column;

    @include break-reverse(1050) {
      flex-direction: row;
      margin: 0 (-$space-sm) $space-md;
    }
  
    .input {
      @include break-reverse(1050) {
        padding: 0 $space-sm;
        width: 50%;
      }

      &:first-of-type {
        @include break(1049) {
          margin: 0 0 $space;
        }
      }
    }
  }

  .contact-form__group--third,
  .contact-form__inform-message {
    margin: 0 0 $space-md;

    @include break-reverse(768) {
      margin: 0 0 $space-xl;
    }
  }

  .contact-form__inform-message {
    font-size: $fz-11;
    font-weight: 600;
    color: $neutral--70;

    .link {
      display: inline-flex;

      span {
        font-size: $fz-11;
        font-weight: 600;
      }
    }
  }

  .contact-form__submit-button {
    padding: ($space-sm - 2) ($space-md + 4);
    line-height: 1.15;

    @include break(767) {
      padding: $space-xs $space-lg;
      font-size: $fz-12;
    }
  }
}