.offering-details__additional-info {
  font-size: $fz-16;
  font-weight: 400;
  line-height: 1.25;

  .additional-info {
    padding: 0 0 $space-lg;

    @include break-reverse(768) {
      padding: 0 0 ($space-lg * 2);
    }
  }

  .additional-info__body {
    @include break-reverse(768) {
      column-count: 2;
      column-width: 50%;
      column-gap: $space;
    }

    h2,
    h3 {
      margin: 0 0 $space-sm;

      &:not(:first-of-type) {
        margin: $space-md 0 $space-sm;

        @include break-reverse(768) {
          margin: $space-lg 0 $space-sm;
        }
      }
    }

    h2 {
      font-size: $fz-20;
      font-weight: 600;
      line-height: 1.35;
    
      @include break-reverse(768) {
        font-size: $fz-30;
      }
    }
  
    h3 {
      font-size: $fz-16;
      font-weight: 600;
      line-height: 1.25;
    
      @include break-reverse(768) {
        font-size: $fz-20;
      }
    }

    p {
      margin: 0 0 $space-sm;
    }
  
    ul {
      margin: 0 0 $space-sm;
    }
  
    li {
      position: relative;
      padding: 0 0 0 ($space - 4);
  
      &:not(:last-of-type) {
        margin: 0 0 $space-xs;
      }
  
      &:before {
        content: "";
        position: absolute;
        top: 7.5px;
        left: 9px;
        width: 4px;
        height: 4px;
        background: $neutral--80;
        border-radius: 50%;
      }
    }

    a {
      cursor: pointer;
  
      &:hover {
        color: $color-secondary;
      }
    }
  }
}