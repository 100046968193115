.schedule-table {
  padding: 0 0 35px;

  .table__row--subtitle {
    background: $color-table-subtitle;

    .table__cell {
      padding: $space-sm;
    }
  }

  .table__content {
    margin: 0 auto;
  }

  .table__row--title {
    .table__cell {
      padding: $space $space-sm;
      background: $color-table-title;
    }
  }

  .table__cell {
    position: relative;
    flex-wrap: wrap;
    padding: $space $space-xs $space-md;
    font-size: $fz-18;
    white-space: nowrap;
    border-color: $color-table-border;

    @include break(767) {
      font-size: $fz-16;
    }
  }

  .table__row:first-child .table__cell {
    border-color: $color-table-border;
  }

  .instructor__img {
    border-radius: 50%;
    overflow: hidden;

    img,
    svg {
      width: 40px;
      height: 40px;
    }
  }
}

.schedule-table__wrapper {
  overflow: auto;
}

.schedule-table__scroll-wrapper {
  position: relative;
  overflow: hidden;
}

.schedule-table__cell {
  padding: $scheduleTableCellTopPadding $scheduleTableCellSidePadding;
  font-size: $fz-18;
  color: $color-text-secondary;
  border: $scheduleTableBorder;

  .schedule-table--title {
    font-weight: 400;
    text-align: left;
    background: $color-table-title;

    .schedule-table-hour {
      padding: $scheduleTableCellHourTopPadding $scheduleTableCellSidePadding;
      font-size: $fz-36;
      font-weight: 600;
      color: $color-primary;
    }
  }

  .schedule-table--subtitle {
    z-index: 2;
    font-size: $fz-24;
    font-weight: 600;
    text-transform: capitalize;
    background: $color-table-subtitle;
    color: $color-primary;
    border: $scheduleTableSubtitleBorder;

    .schedule-table__inner-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;

      .schedule-table__header-title {
        width: 100%;
      }

      .schedule-table__header-subtitle {
        color: $color-text-secondary;
      }
    }
  }

  .schedule-table--root {
    background: $color-table-root;

    .schedule-table > div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .schedule-table--card {
    padding: $scheduleTableCellDailyWeeklyCardPadding;
  }
}

.schedule-table__row {
  .schedule-table--title {
    .schedule-table__cell {
      text-align: left;
      background: $color-table-title;
    }

    .schedule-table-sticky {
      position: absolute;
      z-index: 4;
      top: 0;
      left: 0;
      will-change: transform;
    }
  }

  .schedule-table--subtitle {
    .schedule-table__cell {
      background: $color-table-subtitle;
    }
  }
}

.schedule-table__header-title {
  margin: 0 0 $space-xxs;
  width: 100%;
  font-weight: 600;
  text-overflow: ellipsis;
  color: $color-black;
  overflow: hidden;
}

.schedule-table__header-title--root {
  width: auto;
  color: $color-text-secondary;
}

.schedule-table__header-subtitle {
  width: 100%;
  font-size: $fz-12;
  font-weight: 400;

  @include break(767) {
    font-size: $fz-10;
  }
}

.schedule-table__header-icon {
  margin: 0 $space-xs 0 0;

  @include break(767) {
    margin: 0 $space-xxs 0 0;
  }

  .icon {
    fill: $neutral--70;
  }
}

.schedule-table__subheader-title {
  font-size: $fz-24;
  font-weight: 600;
  line-height: 1.4;
  color: $color-primary;

  .link {
    span {
      font-size: $fz-24;
      font-weight: 600;
      line-height: 1.4;
    }
  }
}

.schedule-table__subheader-subtitle {
  width: 100%;
  font-size: $fz-14;
  font-weight: 400;
  line-height: 1.4;
  color: $color-text-secondary;
}

.schedule-table__time {
  font-size: $fz-18;
  color: $neutral--70;

  @include break(767) {
    font-size: $fz-12;
  }
}

.schedule-table__time--even {
  font-size: $fz-24;
  font-weight: 600;
  color: $color-primary;

  @include break(767) {
    font-size: $fz-16;
  }

  .schedule-table__time-format {
    font-size: $fz-24;

    @include break(767) {
      font-size: $fz-10;
    }
  }
}

.schedule-table__time-format {
  font-size: $fz-14;
  text-transform: lowercase;

  @include break(767) {
    font-size: $fz-10;
  }

  .schedule-table--title {
    font-size: $fz-24;
  }
}

.schedule-table--day {
  .table__content {
    padding: 0 0 0 $scheduleDailyTimeCellWidth;

    @include break(767) {
      padding: 0 0 0 $scheduleDailyTimeCellWidthMobileView;
    }
  }

  .table__cell {
    padding: ($space-sm - 4);
    min-width: 185px;
    max-width: 185px;
    height: 55px;

    &:first-child {
      position: absolute;
      z-index: 3;
      left: 0;
      padding: $space-sm $space;
      max-width: $scheduleDailyTimeCellWidth;
      min-width: $scheduleDailyTimeCellWidth;
      background: $color-table-title;

      @include break(767) {
        max-width: $scheduleDailyTimeCellWidthMobileView;
        min-width: $scheduleDailyTimeCellWidthMobileView;
        padding: ($space-xs + 4) $space-xs;
      }
    }
  }

  .table__row--title {
    .table__cell {
      align-items: start;
      padding: $space;
      height: 96px;

      @include break(767) {
        padding: $space 0 $space $space-xs;
      }
    }
  }
}

.schedule-table--week-view {
  .table__cell {
    min-width: 185px;
    max-width: 185px;
  }

  .table__row--subtitle {
    .table__cell {
      max-width: 1295px;
    }
  }

  .schedule-table__header-subtitle {
    font-size: $fz-11;
  }
}

.schedule-table--instructors {
  .table__cell--instructor {
    display: flex;
    align-items: center;

    .instructor__img {
      margin: 0 $space-sm 0 0;
    }
  }
}