.provider-header {
  background: $color-background;
}

.provider-header--active {
  .provider-header__bottom {
    @include break(1049) {
      padding: ($space) 0;
    }
  } 
}

.provider-header__body {
  position: relative;
}

.provider-header__provider-logo {
  position: absolute;
  z-index: 40;
  top: 0;
  left: 0;
  transform: translate(-20px, 40px);
  width: 146px;
  height: 104px;
  background: $color-background;
  border-radius: 0 0 50px 0;
  box-shadow: 0 14px 20px rgba($neutral--90, .15);
  overflow: hidden;

  @include break-reverse(1050) {
    transform: translate(-20px, 0);
    width: 190px;
    height: 132px;
  }

  @include break-reverse(1341) {
    transform: translate(0, 0);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.provider-header__top,
.provider-header__bottom {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    z-index: 35;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100vw;
    background: $color-background;
    box-shadow: 0 4px 20px rgba(#cdd1d9, .35);
  }
}

.provider-header__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ($space-sm - 4) 0;
  background: $color-background;

  @include break-reverse(1050) {
    padding: $space-xs 0 $space-xs 190px;
  }
}

.provider-header__bottom {
  padding: ($space-xs - 2.5) 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @include break-reverse(1050) {
    padding: $space-sm 0 ($space-sm - 4) 190px;
  }

  &:before {
    z-index: 25;
  }

  .burger-btn {
    &.active {
      top: 45px;
    }
  }

  .slide-menu.mobile-nav {
    height: calc(100% - 88px);

    .slide-menu__inner {
      .slide-menu__body {
        justify-content: flex-start;

        .tabs__item {
          margin: 0 0 ($space - 4);
        }

        .provider-header__site-link {
          padding: $space-xs 0 ($space-sm - 4);

          span {
            font-size: $fz-20;
          }
        }
      }
    }
  }
}

.provider-header__wew-logo,
.provider-header__login {
  position: relative;
  z-index: 40;
}

.provider-header__wew-link {
  @include break-reverse(1050) {
    padding: 0 0 0 $space-sm;
  }

  &:hover {
    span {
      color: $neutral--50;
    }
  }

  span {
    display: flex;
    align-items: center;
    color: $neutral--50;

    @include break(1049) {
      font-size: $fz-12;
    }
  }

  img {
    width: 100px;
    height: 8px;
    filter: grayscale(100%);
    opacity: .6;

    @include break-reverse(1050) {
      width: 150px;
      height: 12px;
    }
  }
}

.provider-header__tabs {
  .tabs__container {
    margin: 0;
  }

  .tabs__item-shadow {
    top: auto;
    bottom: -13px;
    border-bottom-width: 3px;
  }

  .tabs__item {
    margin: 0 $space 0 0;
    font-size: $fz-16;
    line-height: 1;

    &:hover:not(.tabs__item--active) {
      &:before {
        bottom: -13px;
        border-bottom-width: 3px;
      }
    }
  }

  .tabs__item--active {
    color: $primary--70;
  }
}

.provider-header__site-link {
  padding: $space-xs 0 ($space-sm - 4);

  span {
    font-size: $fz-16;
    line-height: 1;
  }
}