.schedule-table__filters-wrapper {
  width: 100%;

  @include break-reverse(1441) {
    width: calc(100% - 320px);
  }
}

.schedule-table__filters-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.schedule-table__filters-title {
  text-transform: uppercase;
  font-size: $fz-12;
  opacity: 0.4;
  font-weight: 600;
}

.schedule-table__filters-body {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .input-group__wrapper {
    width: 25%;
    margin: 0;

    &:not(:last-of-type) {
      margin: 0 $space 0 0;
    }
  }
}