.offering-bio__body {
  display: flex;
  flex-direction: column;
}

.offering-bio__item {
  margin: 0 0 $space-lg;

  @include break-reverse(768) {
    margin: 0 0 ($space-lg * 2);
  }

  .offering-bio-card {
    display: flex;
    flex-direction: column;

    @include break-reverse(768) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .offering-bio-card__top {
    margin: 0 0 $space-lg;

    @include break-reverse(768) {
      order: 2;
      margin: 0 0 0 7vw;
    }
  }

  .offering-bio-card__bottom {
    @include break-reverse(768) {
      order: 1;
      margin: 0;
    }
  }

  .offering-bio-card__visual {
    position: relative;
    max-width: 325px;
    height: 450px;
    border-radius: 50px 0 0 0;
    overflow: hidden;

    @include break-reverse(768) {
      min-width: 325px;;
    }

    @include break-reverse(1050) {
      min-width: 415px;
      max-width: 415px;
      height: 600px;
    }
  
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .offering-card__visual--empty {
    background: $color-violet--soft;

    svg {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 17%);
      width: 240px;
      height: 285px;

      @include break-reverse(1050) {
        width: 312px;
        height: 370px;
      }
    }
  }

  .offering-bio-card__name {
    margin: 0 0 $space-xs;
    font-size: $fz-20;
    font-weight: 600;
    line-height: 1.4;

    @include break-reverse(768) {
      margin: 0 0 $space-sm;
      font-size: $fz-30;
    }
  }

  .offering-bio-card__instruction {
    margin: 0 0 $space-md;
    font-size: $fz-16;
    font-weight: 600;
    line-height: 1.25;

    @include break-reverse(768) {
      margin: 0 0 $space-lg;
      font-size: $fz-20;
    }
  }

  .offering-bio-card__socials {
    margin: 0 0 $space-md;

    @include break-reverse(768) {
      margin: 0 0 $space-lg;
    }
  }

  .offering-bio-card__socials-list {
    display: flex;
    align-items: center;
    margin: 0 (-$space-xs);

    @include break-reverse(768) {
      margin: 0 (-$space-xxs);
    }
  }

  .offering-bio-card__socials-item {
    padding: 0 $space-xs;

    @include break-reverse(768) {
      padding: 0 $space-xxs;
    }
  }

  .offering-bio-card__button {
    margin: 0 0 $space-lg;
    padding: ($space-xs + 2) $space-sm;
    width: fit-content;

    @include break-reverse(768) {
      margin: 0 0 $space-xxl;
      padding: ($space-sm - 4) $space-lg;
    }

    span {
      font-size: $fz-14;
    }
  }

  .offering-bio-card__bio {
    br {
      content: "";
      display: block;
      margin: 0 0 $space-sm;
    }
  }
}