@font-face {
  font-display: swap;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  src: 
    url('../../fonts/work-sans-400.woff2') format('woff2'),
    url('../../fonts/work-sans-400.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  src:
    url('../../fonts/work-sans-500.woff2') format('woff2'),
    url('../../fonts/work-sans-600.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  src:
    url('../../fonts/work-sans-600.woff2') format('woff2'),
    url('../../fonts/work-sans-600.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  src:
    url('../../fonts/work-sans-700.woff2') format('woff2'),
    url('../../fonts/work-sans-700.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 900;
  src:
    url('../../fonts/work-sans-900.woff2') format('woff2'),
    url('../../fonts/work-sans-900.ttf') format('truetype');
}