.global-footer {
  .footer__top-body {
    .footer__body-block--logo {
      .footer__logo {
        img {
          min-width: 227px;
          max-width: 227px;
          height: 20px;
          opacity: .6;
        }
      }
    }
  }

  .footer__bottom-body {
    .footer__body-block--logo {
      display: flex;
      flex-direction: column;
      align-items: center;
      order: 2;
  
      @include break-reverse(1050) {
        flex-direction: row;
        order: 1;
        margin: 0 (-$space-xs);
      }

      .footer__logo,
      .footer__copyright {
        @include break-reverse(1050) {
          padding: 0 $space-xs;
        }
      }

      .footer__logo {
        @include break(1049) {
          order: 2;
        }

        @include break-reverse(1050) {
          position: relative;
        }

        &:before {
          content: "";
          position: absolute;
          top: 50%;
          right: -1px;
          transform: translate(-50%, -50%);
          width: 1px;
          height: 12px;
          background: $neutral--50;
        }
      }

      .footer__copyright {
        @include break(1049) {
          order: 1;
          margin: 0 0 $space-sm;
        }
      }

      .footer__link--logo {
        &:hover {
          span {
            color: $neutral--50;
          }
        }
      
        span {
          color: $neutral--50;
        }
      
        img {
          margin: -2.5px 0 0 $space-xxs;
        }
      }
    }

    .footer__body-block--payment {
      @include break-reverse(1050) {
        order: 2;
      }
    }
  }
}