.sessions {
  padding: 0 0 $space-xl;

  .tabs__container {
    margin: 0 0 $space-md;
    border-bottom: 1px solid $color-grey--light;

    .tabs__item {
      &:not(:last-child) {
        @include break(450) {
          margin: 0 $space-xl 0 0;
        }
      }
    }
  }
}

.sessions__body {
  position: relative;
  display: flex;
  min-height: 100px;

  &:not(&.sessions__body--grouped) {
    flex-direction: column;
    margin: 0 (-$space-xs - 2);

    @include break-reverse(768) {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  .loader__wrapper {
    margin: 0 auto;
  }
}

.sessions__body--grouped {
  flex-wrap: wrap;

  &.sessions__body--no-active {
    @include break-and-break-reverse(575, 767) {
      margin: 0 (-$space-xs);
    }
  
    @include break-reverse(992) {
      margin: 0 (-$space-xs);
    }
  }

  .sessions__item {
    padding: 0;
  }
}

.sessions__title {
  margin: 0 0 $space-sm;
  font-size: $fz-24;
  font-weight: 600;
  line-height: 2;
}

.sessions__course-block {
  width: 100%;
}

.sessions__item {
  display: flex;
  margin: 0 0 ($space-sm + 4);
  padding: 0 ($space-xs + 2);
  flex: 0 0 100%;
  font-size: $fz-14;
  line-height: 1.4;

  @include break-reverse(768) {
    flex: 0 0 50%;
  }

  .ftable__cell {
    justify-content: flex-start;
    padding: 0;
    min-width: auto;
    border: none;
  }

  .ftable__cell-content {
    line-height: 1.4;
  }

  .sessions-item-card__cell-label {
    font-weight: 600;
  }

  .sessions-item-card {
    width: 100%;
    height: 100%;
  }

  .sessions-item-card__body {
    display: flex;
    flex-direction: column;
    padding: $space;
    width: 100%;
    height: 100%;
    background: $color-background;
    border-radius: $b-rad * 5 0;
    border: 1px solid $color-blue;
    box-shadow: 0 2px 18px rgba($color-black, .08);

    @include break-reverse(768) {
      justify-content: space-between;
    }

    @include break-reverse(1050) {
      padding: $space-md $space-lg;
    }
  }

  .sessions-item-card__top {
    padding: 0 0 $space-xs;
  
    @include break-reverse(1050) {
      padding: 0 0 ($space-xs + 4);
    }
  }

  .sessions-item-card__logo {
    justify-content: flex-end;
    margin: 0 0 $space;
  
    @include break-reverse(1050) {
      margin: 0 0 $space-sm;
    }
  }

  .sessions-item-card__date-number {
    position: relative;
    flex-direction: column;
    padding: 0 0 $space-sm;

    @include break-reverse(1050) {
      flex-direction: row;
      column-gap: $space-sm;
    }

    .ftable__cell {
      &:first-of-type {
        margin: 0 0 $space-sm;

        @include break-reverse(1050) {
          flex: 0 0 63%;
          margin: 0;
        }
      }
  
      &:last-of-type {
        @include break-reverse(1050) {
          flex: 0 0 33%;
        }
      }
    }
  }

  .sessions-item-card__date-number--no-location {
    padding: $space 0 $space-sm;
  }

  .sessions-item-card__free-badge {
    position: absolute;
    top: 0;
    left: 0;
  }

  .sessions-item-card__free-badge--active {
    .badge {
      background: $color-secondary;
      color: $color-text-light;
    }
  }

  .sessions-item-card__free-badge--no-active {
    .badge {
      background: $additional--blush;
      color: $neutral--70;
    }
  }

  .sessions-item-card__date-value {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }

  .sessions-item-card__inactivity-tooltip {
    .tooltip__content {
      @include break(600) {
        left: 0;
        transform: translateX(-50%);
      }
    }
  }

  .sessions-item-card__schedule-studio {
    flex-direction: column;
    padding: 0 0 $space-sm;

    .ftable__cell {
      display: block;

      @include break-reverse(1050) {
        display: flex;
        flex-direction: row;
        column-gap: $space-sm;
      }
    }

    .ftable__cell-content {
      padding: 0 $space-xs 0 0;

      &:first-of-type {
        @include break-reverse(1050) {
          flex: 0 0 63%;
          padding: 0;
        }
      }
  
      &:last-of-type {
        @include break-reverse(1050) {
          flex: 0 0 33%;
        }
      }
    }
  }

  .sessions-item-card__button--inactive-session {
    position: absolute;
    right: $space-sm;
    top: 50%;
    transform: translate(0, -50%);
  }

  .sessions-item-card__bottom {
    display: flex;
    flex-direction: column;
    padding: $space 0 0;
    border-top: 1px solid $primary--20;
  
    @include break-reverse(1050) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      column-gap: ($space - 4);
      padding: $space-sm 0 0;
    }
  }

  .sessions-item-card__rate {
    span {
      &:first-of-type {
        font-weight: 600;
      }
    }
  }

  .sessions-item-card__actions {
    display: flex;
    column-gap: $space-sm;
    margin: $space-sm 0 0;
  
    @include break-reverse(1050) {
      margin: 0;
    }
  
    .btn {
      padding: $space-xs $space-sm;
      font-size: $fz-12;
      white-space: nowrap;
  
      @include break-reverse(1050) {
        padding: ($space-xs + 2) ($space - 4);
        font-size: $fz-14;
      }
    }
  }
}

.sessions__date-separator {
  color: $color-grey;
}

.sessions__actions {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: $space-sm 0;

  .btn {
    font-size: $fz-16;

    .icon {
      height: auto;
      fill: $color-black;
    }
  }
}

.sessions__actions--active {
  .btn .icon {
    transform: rotate(180deg);
  }
}

.sessions__course-title {
  display: block;
  margin: ($space-xs + 4) 0;
  font-size: $fz-16;
  font-weight: 700;
  line-height: 1.3;

  .btn {
    font-size: initial;
    display: inline-flex;

    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.sessions-course-card {
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  margin: 0 0 $space-md;

  @include break-and-break-reverse(575, 767) {
    padding: 0 $space-xs;
    flex: 0 0 50%;
  }

  @include break-reverse(992) {
    padding: 0 $space-xs;
    flex: 0 0 50%;
  }

  .sessions-course-card__body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: $space;
    height: 100%;
    border: 1px solid $color-blue;
    box-shadow: 0px 4px 20px rgba($color-grey--medium, .5);
    border-radius: 40px 0px;
  }

  .offering-title-block__id {
    font-size: $fz-12;
  }

  .offering-title-block__title {
    margin: 0 0 $space-sm;
    padding: 0;
    font-size: $fz-18;
  }

  .sessions__item-button--learn-more-btn {
    margin: auto 0 0;
  }

  .sessions-course-card__info-block {
    display: flex;
    flex-direction: column;
    font-size: $fz-14;
    line-height: 1.4;
  }

  .sessions-course-card__info-block {
    margin: 0 0 $space;
  }

  .sessions-course-card__value {
    font-weight: 600;
  }
}