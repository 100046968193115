.account-profile {
  .checkbox__container {
    margin: $space-sm 0 0;
  }

  .info-box {
    background: $color-background;
  }
}

.account-profile__body {
  display: flex;
  flex-direction: column;

  @include break-reverse(768) {
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -2.5vw;
  }
}

.account-profile__item {
  margin: 0 0 ($space-sm - 4);

  @include break-reverse(768) {
    flex: 0 0 50%;
    margin: 0 0 ($space-xxxl * 2);
    padding: 0 2.5vw;
  }
}

.account-profile__policy-button {
  font-size: $fz-12;
}

.account-profile__block {
  position: relative;
  max-width: 100%;
  background: $color-background;

  .loader__wrapper {
    background: $color-background;
  }
}

.account-profile__block--open {
  .account-profile__block-header {
    @include break(767) {
      border-radius: 9px 9px 0 0;
    }

    > .icon {
      @include break(767) {
        transform: rotate(180deg);
      }
    }
  }

  .account-profile__block-body {
    @include break(767) {
      display: block;
    }
  }
}

.account-profile__block--edited {
  @include break-reverse(768) {
    padding: $space $space-sm;
    border: 1px solid $color-blue;
    border-radius: 8px;
    box-shadow: 0 2px 18px rgba($color-text-primary, .08);
  }

  .list-item:not(:last-child) {
    margin: 0 0 $space;
  }

  .input__label {
    opacity: 1;
  }

  .input__element {
    &::placeholder {
      font-size: $fz-14;
    }
  }

  .select__input-container {
    .select__placeholder {
      font-weight: 400;
    }

    .select__chevron {
      width: 10px;
      height: 10px;
    }
  }

  .list-item--state {
    @include break-reverse(450) {
      margin: 0 !important;
    }
  }
}

.account-profile__block--contact,
.account-profile__block--emergencyContacts,
.account-profile__block--parentInfo {
  .account-profile__block-body {
    .list-item {
      width: 100%;
    }
  }
}

.account-profile__block--snailMailAdress {
  .account-profile__block-edit-action {
    @include break(375) {
      transform: translate(-16px, 15px);
    }
  }

  .account-profile__info-text {
    margin: 0 0 ($space - 4);

    @include break(375) {
      max-width: 200px;
    }

    @include break-reverse(768) {
      margin: 0 0 $space;
    }
  }
}

.account-profile__block--emailSubscription,
.account-profile__block--mediaWaiver {
  margin: 0 0 ($space-sm - 4);

  @include break-reverse(768) {
    margin: 0 0 ($space-xl * 2);
  }

  .account-profile__subcription-note {
    margin: 0;
  }
}

.account-profile__block--mediaWaiver {
  @include break(767) {
    margin: 0 0 $space;
  }
}

.account-profile__block-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $space-sm;

  @include break(767) {
    background: $neutral--10;
    border: 1px solid $color-blue;
    border-radius: 9px;
    cursor: pointer;
  }

  @include break-reverse(768) {
    margin: 0 0 $space-sm;
    padding: 0 0 ($space-sm - 4);
    border-bottom: 1px solid $color-text-primary;
  }
}

.account-profile__block-title {
  font-size: $fz-16;
  font-weight: 600;
}

.account-profile__block-edit-action,
.account-profile__change-password-button {
  span {
    font-size: $fz-12;
    font-weight: 600;
  }
}

.account-profile__block-edit-action {
  display: flex;
  align-items: center;
  
  @include break(767) {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    transform: translate(-16px, 19px);
  }
}

.account-profile__block-body {
  position: relative;

  @include break(767) {
    display: none;
    padding: $space-sm;
    border: 1px solid $color-blue;
    border-top: none;
    border-radius: 0 0 9px 9px;
    cursor: pointer;
  }

  .list {
    display: flex;
    flex-direction: column;

    @include break-reverse(768) {
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }

  .list-item {
    margin: 0 0 ($space-sm - 4);
    width: 100%;
    font-size: $fz-16;

    @include break-reverse(768) {
      width: 48%;
      margin: 0 0 $space-sm;
    }

    &:last-of-type:not(.list-item--underAge) {
      margin: 0;
    }
  }

  .list-item--underAge {
    margin: ($space-sm - 4) 0 0;

    .checkbox__container {
      margin: 0;
    }
  }

  .input__element {
    font-size: $fz-14;
    font-weight: 600;
    cursor: pointer;
  }

  .input__label {
    font-size: $fz-14;
  }

  .select--disabled {
    pointer-events: none;
  }

  .input__element:disabled,
  .select--disabled .select-input,
  .input--phone .input__wrapper--disabled {
    border: none;
    cursor: auto;
  }
  .input--phone {
    .input__wrapper--disabled {
      margin: 0 0 0 (-$space-xs - 2);
    }
  }
}

.account-profile__block-footer {
  display: flex;
  justify-content: flex-end;
  margin: $space-lg 0 0;

  .btn {
    padding: $space-xs ($space-sm + 4);

    span {
      font-size: $fz-12;
    }

    &:last-of-type {
      margin: 0 0 0 $space-sm;
    }
  }
}

.account-profile__subcription-note,
.account-profile__info-text {
  display: inline-block;
  font-size: $fz-12;

  .btn {
    display: inline;
    
    span {
      font-size: $fz-12;
    }
  }

  .info-box {
    margin: $space-sm 0 0;
  }
}

.account-profile__info-text {
  margin: 0 0 $space-sm;
}

.account-profile__subcription-note {
  margin: ($space-sm - 4) 0 0;

  @include break-reverse(768) {
    margin: $space-sm 0 0;
  }

  .account-profile__subcription-support {
    position: relative;
    display: inline-block;
    margin: 0 $space-sm 0 0;

    .link {
      span {
        font-size: 12px;
        font-weight: 400;
        text-decoration: underline;
      }

      &:hover {
        span {
          text-decoration: none;
          color: $color-primary;
        }
      }
    }
  }

  .tooltip {
    position: absolute;
    right: -20px;
    top: 0;
    text-align: center;
    color: initial;

    .tooltip__wrapper {
      left: 3%;
    }

    a {
      display: block;
      font-size: inherit;
    }
  }
}