.select {
  display: block;
  position: relative;
  width: 100%;
  color: $color-text-primary;
  transition: $transition-time * 1.5 all;
}

.select__placeholder {
  font-family: $workSans;
  color: $color-text-secondary;
}

.select__value {
  position: relative;
  padding-right: $space + $space-xxs;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  letter-spacing: normal;
}

.select-input {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: ($space + 1);
  transition: $transition-time * 1.5 all;
  overflow: hidden;
  cursor: default;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: -$space-md;
    transform: translateY(-50%);
    width: 4px;
    height: 4px;
    background: $color-primary;
    border-radius: 100%;
    transition: $transition-time * 1.5 all;
  }

  &:focus {
    border-color: $color-primary;
  }
}

.select__input-container {
  position: relative;
}

.select__chevron {
  position: absolute;
  z-index: 2;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  user-select: none;
  transition: $transition-time * 1.5 transform;
  pointer-events: none;

  path {
    fill: $neutral--70;
    transition: $transition-time * 1.5 fill;
  }
}

.select__inner {
  display: block;
  z-index: 0;
  position: absolute;
  top: 0;
  padding-top: $select-height;
  width: 100%;
  background: $color-background;
  border-top: none;
  border-radius: $b-rad;
  box-shadow: 0 4px 20px $primary--30;
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
}

.select--expand {
  background: $color-background;

  .select__inner {
    z-index: 10;
    display: block;
    opacity: 1;
    transition: $transition-time * 1.5 all, visibility 0s, z-index 0s;
    visibility: visible;
  }

  .select__inner-list {
    li {
      padding-left: $space;
      padding-right: $space;
    }
  }

  .select-input {
    z-index: 11;
    padding-left: $space;
    border-bottom-color: $primary--30;
  }

  .select__chevron {
    z-index: 11;
    transform: translateY(-50%) rotate(180deg);
  }

  .select-input {
    &:before {
      left: ($space / 2);
    }
  }

  .input__label {
    color: $color-primary!important;
  }
}

.select__loader {
  margin: $space-xs;

  &.loader {
    width: $icon-w-lg;
    height: $icon-h-lg;
  }
}

.select__inner-list {
  position: relative;
  max-height: 200px;
  background: $color-background;
  overflow: auto;
}

.select__inner-list-item {
  padding: ($space-sm - 4) 0;
  font-size: $fz-14;
  transition: $transition-time * 1.5 all;
  cursor: pointer;

  &:hover {
    background: $primary--30;
  }
}

.select__inner-list-item--selected {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 1px;
    width: 1.5px;
    background: $color-primary;
    border-radius: 10px;
    transition: $transition-time * 1.5 all;
  }
}

.select__inner-list-item--clear {
  color: $color-primary;
}

.select__no-data {
  padding: $space-xs 0;
  color: $color-text-secondary;
  font-size: $fz-14;
  cursor: not-allowed;
}

.select--disabled {
  .select-input {
    opacity: .7;
    pointer-events: none;
    cursor: not-allowed;
  }
}

.select__search {
  .select__search-input-element {
    margin: $space-xs;
    padding: ($space-xs + 4) $space-sm;
    width: calc(100% - #{$space-sm});
    background: $primary--30;
    border: none;
    border-radius: $b-rad;
  
    &:focus {
      border: none;
    }
  }
}