.policy {
  padding: $space-md;

  h1, 
  li {
    margin: 0 0 $space-xs;
  }

  h1 {
    &:first-of-type {
      margin: 0 0 $space-md;
    }

    &:not(:first-of-type) {
      margin: $space-xl 0 $space-xs;
    }

    & + span {
      margin: 0 0 $space;
    }
  }

  h2 {
      margin: $space-md 0 $space-sm;
      font-size: $fz-18;
  }

  p {
    margin: 0 0 $space;
  }

  span {
    display: block;
  }

  ul,
  ol {
    margin: 0 0 $space-md;
  }

  ul {
    &:not(:first-of-type) {
      padding: 0 0 0 $space-lg;
      list-style: square;
    }
  
  }

  ol {
    padding: 0 0 0 $space-lg;
    list-style: decimal;
  }

  .link {
    display: inline-flex;
    
    span {
      font-size: $fz-16;
      font-weight: 400;
      text-decoration: underline;
    }

    &:hover {
      span {
        color: $color-primary;
        text-decoration: none;
      }
    }
  }

  table {
    margin: 0 0 $space-sm;
    border-spacing: 0;

    p {
      margin: 0;
    }
  }

  th,
  td {
    padding: 0.5em 1em;
    border-bottom: 1px solid $color-black--dark;
    border-left: 1px solid $color-black--dark;
  }

  tbody {
    tr {
      &:first-child td {
        border-top: 1px solid $color-black--dark;
      }

      td {
        &:last-child {
          text-align: center;
          border-right: 1px solid $color-black--dark;
        }
      }
    }
  }

  .btn {
    display: inline;
  }
}

.policy-footer {
  display: flex;
  justify-content: flex-end;
  margin: $space 0 0;

  .btn--transparent {
    margin: 0 $space-xs 0 0;

    .icon {
      transform: rotate(90deg)
    }
  }
}
