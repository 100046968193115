.drag-scroll--hold {
  user-select: none;
  cursor: grabbing !important;
}

.blurred {
  scroll-behavior: unset;
  cursor: grab;

  &:after {
    content: '';
    position: absolute;
    z-index: 4;
    top: 0;
    right: 0;
    width: 74px;
    height: 100%;
    background: linear-gradient(270deg, $color-background 0%, rgba($color-background, 0) 100%);
    transition: .5s all;
  }
}

.blurred--inactive {
  &:after {
    right: -100px;
  }
}