.schedule-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 100%;
  white-space: normal;
}

.schedule-card__logo {
  top: 0;
  right: 0;
  width: 52px;
  height: 36px;
  border-radius: 0 0 0 20px;
  filter: drop-shadow(0 2px 20px rgba($neutral--90, .15));
}

.schedule-card__id {
  margin: 0 0 $space-xs;
  font-size: $fz-11;
  line-height: 1.25;
  text-transform: uppercase;
  color: $color-text-secondary;
}

.schedule-card__title {
  margin: $space-sm 0 $space 0;
  font-size: $fz-16;
  font-weight: 600;
  line-height: 1.25;
  word-break: break-word;
  text-overflow: ellipsis;
  color: $color-text-primary;
  transition: all .5s;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    color: $primary--70;
  }
}

.schedule-card__instructors,
.schedule-card__group {
  font-size: $fz-14;
  line-height: 1;
}

.schedule-card__group {
  display: flex;
  align-items: center;
  margin: 0 0 $space-sm;

  .icon {
    margin: 0 $space-xxs 0 0;
    min-width: 16px;
    min-height: 16px;
    fill: $color-primary;
  }
}

.schedule-card__instructors {
  display: flex;
  flex-wrap: wrap;

  .link {
    span {
      font-weight: 400;
      color: $color-text-primary;
    }

    &:hover {
      span {
        color: $primary--70;
      }
    }
  }
}

.schedule-card__container {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  padding: $space-sm $space;
  width: 100%;
  border-top: $scheduleTableBorder;
  border-bottom: $scheduleTableBorder;
  transition: $transition-time * 2 height;
  overflow: hidden;

  @include break(767) {
    padding: $space-xs ($space-xs + 4);
  }
}

.schedule-card__footer {
  margin: $space-sm 0 0;
}