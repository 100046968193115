.input-range__slider {
  position: absolute;
  top: 50%;
  display: block;
  margin-left: -0.5rem;
  margin-top: -8px;
  width: 13px;
  height: 13px;
  background: $color-primary;
  border: 1px solid $color-primary;
  border-radius: 100%;
  outline: none;
  transition: $transition-time all;
  appearance: none;
  cursor: pointer;

  &:active {
    transform: scale(1.3);
  }

  &:focus {
    box-shadow: 0 0 0 5px rgba($color-purple--light, .2);
  }
}

.input-range--disabled {
  transform: none;
  opacity: .4;
  box-shadow: none;
}

.input-range__label {
  transform: translateZ(0);
  font-size: $fz-12;
  white-space: nowrap;
}

.input-range__label--min,
.input-range__label--max {
  position: absolute;
  bottom: -1.4rem;
}

.input-range__label--min {
  left: 0;
}

.input-range__label--max {
  right: 0;
}

.input-range__label--value {
  position: absolute;
  top: -1.8rem;
}

.input-range__label-container {
  position: relative;
  left: -50%;
}
.input-range__label--max .input-range__label-container {
  left: 50%;
}

.input-range__track {
  position: relative;
  display: block;
  height: 3px;
  background: $color-input-range-background;
  cursor: pointer;
}
.input-range--disabled .input-range__track {
  opacity: .4;
}

.input-range__track--background {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  margin-top: -0.15rem;
}

.input-range__track--active {
  background: $color-primary;
}

.input-range {
  position: relative;
  width: 100%;
  height: 1rem;
}

/*# sourceMappingURL=index.css.map */