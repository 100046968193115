.switcher--disabled {
  .switcher__content {
    border-color: $color-switcher-disabled;
    cursor: not-allowed;
  }
}

.switcher--active {
  .switcher__content {
    color: $color-secondary;
    border-color: $color-secondary;
  }
}

.switcher__content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ($space-xs + 4);
  min-width: $switcher-min-w;
  font-size: $fz-14;
  font-weight: 600;
  line-height: 1.15;
  white-space: nowrap;
  color: $color-primary;
  border: 2px solid $color-primary;
  border-radius: $b-rad;
  transition: $transition-time;
  cursor: pointer;
}

.switcher__hint {
  display: block;
  margin-top: $space-xxs;
  font-size: $fz-14;
  text-align: left;
}