.provider-contact { 
  margin: (-$space-md - 4) 0 0;


  
  /*----- map block ----- */
  .contact-map__body {
    .leaflet-container {
      width: 100%;
      height: 240px;

      @include break-reverse(768) {
        height: 400px;
      }

      @include break-reverse(1441) {
        height: 450px;
      }
    }
  }



  /*----- title block ----- */
  .contact-title-block {
    padding: $space-lg ($space-xs + 2) ($space - 4);
    
    @include break-reverse(768) {
      padding: $space-lg ($space-xs + 2);
    }
  }



  /*----- founders block ----- */
  .contact-founders {
    padding: 0 0 ($space - 4);
    
    @include break-reverse(768) {
      padding: 0 0 $space-lg ;
    }
  }



  /*----- form block ----- */
  .contact-form-block {
    padding: ($space - 4) ($space-xs + 2) $space-lg;
    
    @include break-reverse(768) {
      padding: $space-lg ($space-xs + 2) ($space-lg * 2);
    }
  }

  .leaflet-container {
    width: 100%;
    height: 100vh;
    z-index: 1;
  }
}