/*----- banner block ----- */
.provider-banner {
  position: relative;
  padding: 0 0 240px;

  @include break-reverse(768) {
    padding: 0 0 300px;
  }

  @include break-reverse(1441) {
    padding: 0 0 325px;
  }
}

.provider-banner__body {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}



/*----- logo block ----- */
.provider-logo {
  position: absolute;
  z-index: 5;
  display: flex;
  align-items: center;
  padding: $space-xxs;
  background: $color-background;
  border: 1px solid $primary--20;

  img {
    width: 100%;
    height: auto;
  }
}