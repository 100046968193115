.sponsors-block__title-block {
  h2 {
    margin: 0 0 $space-xs;

    @include break-reverse(768) {
      margin: 0 0 $space-sm;
    }
  }
}

.sponsors-block__slider {
  padding: $space-md 0;
}

.sponsors-block__slide {
  display: flex;
  flex-direction: column;
  height: auto;

  .sponsors-card {
    padding: $space;
  }

  .sponsors-card__title-block {
    img {
      height: 40px;

      @include break-reverse(768) {
        height: 55px;
      }
    }
  }
}