.popup {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.popup__overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba($color-black--dark, .7);
}

.popup__window {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  margin: auto;
  padding: $space;
  width: fit-content;
  max-width: 870px;
  background: $color-background;
  box-shadow: 0px 4px 20px rgba($color-grey--medium, .25);
  border-radius: 8px;

}

.popup__close-button {
  position: absolute;
  top: 14px;
  right: 14px;
}

.popup__return-button {
  margin: ($space - 4) 0 0;
}

.popup__icon {
  margin-right: $space;
  min-width: 40px;
  height: 40px;
}

.popup__text {
  font-size: $fz-18;
  line-height: 1.5;

  b {
    display: block;
    margin-bottom: $space-xs;
    font-size: 20px;
  }
}
