.registration-details {
  .instructor-name {
    display: flex;
  }
}

.registration-details__title-wrapper {
  display: flex;
  padding: 0 0 $space;
}

.registration-details__location {
  position: relative;
  align-self: baseline;
  margin: $space-xs 0 0;
  padding: ($space-xs - 2) ($space-xs + 4) ($space-xs - 2) $space;
  font-size: $fz-14;
  background: $color-background;
  color: $color-text-primary;
  border: 1px solid $color-blue--dark;
  border-radius: 4px;
}

.registration-details__icon {
  position: absolute;
  left: 6px;
}

.registration-details__name {
  font-weight: 600;
}

.registration-details__contact-wrapper {
  display: flex;
  flex-direction: column;

  .offering-title-block__title {
    padding: 0;
  }
}

.class-details__class-type {
  position: relative;
  margin: 0 0 0 $space-xs;
  font-size: $fz-14;
  text-transform: capitalize;
  color: $color-grey;
}

.registration-details__login-text {
  margin: auto 0 ($space-xs + 2);
  max-width: 330px;
  width: 100%;
  font-size: $fz-14;
  text-align: right;

  .btn.btn--link {
    display: inline;
    
    span {
      font-weight: 600;
    }
  }
}
