@include print {
  .receipt-print .react-widget__wrapper {
    padding: 0;

    & > *:not(.modal--registration-step5) {
      display: none;
    }

    .modal--registration-step5 {
      position: static;

      .modal__window {
        margin: 0;
        max-width: 100%;
      }
    }
  }
}
