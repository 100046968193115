.faculty__title {
  margin: 0 0 ($space - 4);

  @include break(1049) {
    display: inline-block;
    margin: 0;
    width: 50%;
  }
}

.faculty__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 $space-lg;

  @include break-reverse(1050) {
    flex-direction: column;
    align-items: flex-start;
  }

  .tabs__container {
    @include break-reverse(1050) {
      margin: 0 (-$space-xs);
    }
  }

  .alphabet-filter-tabs {
    margin: 0;
  }

  .btn--filters-menu {
    margin: 0 0 0 auto;
  }
}

.faculty__header-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.faculty__filters-header {
  display: flex;
  justify-content: space-between;

  @include break-reverse(1050) {
    width: 100%;
  }
}

.faculty__filters-title {
  font-size: $fz-12;
  font-weight: 600;
  text-transform: uppercase;
  opacity: .4;
}

.faculty__filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 $space-lg;
  width: 100%;

  .select {
    width: calc(100% / 4 - 40px);

    @include break(1049) {
      margin: ($space-xs + 2) 0;
    }
  }
}

.faculty__search-input {
  max-width: 295px;
  width: 100%;
}

.faculty__body {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0 0 ($space-xxxl + 6);

  @include break-reverse(768) {
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 (-$space-xs - 2);
  }
}

.faculty__item {
  margin: 0 0 $space;
  padding: 0;

  @include break-reverse(768) {
    padding: 0 ($space-xs + 2);
    width: 50%;
  }

  @include break-reverse(1201) {
    width: calc(100% / 3);
  }

  .faculty-card {
    flex-direction: row;
    padding: $space-sm;
    min-height: 145px;
    overflow: hidden;
  
    @include break-reverse(768) {
      padding: $space-sm $space;
      min-height: 185px;
    }
  }
  
  .faculty-card__visual {
    justify-content: center;
    min-width: 64px;
    max-width: 64px;
    height: 64px;
    background: $color-background;
    border-radius: 20px;
    overflow: hidden;
  
    @include break-reverse(768) {
      min-width: 108px;
      max-width: 108px;
      height: 108px;
    }
  
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  
  .faculty-card__visual--empty {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $color-blue--medium;
  
    svg {
      width: 36px;
      height: 36px;
  
      @include break-reverse(768) {
        width: 68px;
        height: 68px;
      }
    }
  }
  
  .faculty-card__content {
    display: flex;
    flex-direction: column;
    margin: 0 0 0 $space-sm;
  
    @include break-reverse(768) {
      margin: 0 0 0 $space;
    }
  }
  
  .faculty-card__title {
    margin: 0 0 $space-xs;
    font-size: $fz-18;
    font-weight: 600;
    line-height: 1.3;
  
    @include break-reverse(768) {
      font-size: $fz-22;
    }
  }
  
  .faculty-card__subtitle {
    font-size: $fz-16;
    line-height: 1.25;
  }
  
  .faculty-card__actions {
    display: flex;
    align-items: center;
    margin: auto (-$space-xxs) 0;
    padding: $space 0 0;
    max-width: 240px;
  
    @include break-reverse(768) {
      padding: $space-sm 0 0;
    }
  
    .link,
    .btn {
      padding: $space-xs ($space-xs + 4);
      margin: 0 $space-xxs;
  
      &:first-child {
        svg {
          stroke: $color-background;
        }
      }
  
      
      &:last-child {
        svg {
          fill: $color-background;
        }
      }
  
      span {
        font-size: $fz-12;
        word-break: normal;
      }
    }

      
    .link {
      svg {
        fill: $color-background;
      }
    }

    .btn {
      svg {
        stroke: $color-background;
      }
    }
  }
  
  .faculty-card__open-profile {
    margin: 0 0 0 $space-xs;
  
    .icon {
      fill: none;
      stroke: $color-background;
    }
  }
}

.faculty__load-more-wrapper {
  display: flex;
  justify-content: center;
  margin: $space-xl 0;
}

.faculty__load-more {
  font-size: $fz-18;
}