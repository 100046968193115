.provider-footer {
  .footer__top-body {
    .footer__body-block--logo {
      .footer__logo {
        img {
          min-width: 91px;
          max-width: 91px;
          height: auto;
          opacity: .9;
        }
      }
    }
  }

  .footer__bottom-body {
    .footer__body-block--logo {
      @include break(1049) {
        order: 3;
      }

      .footer__link--logo {
        &:hover {
          span {
            color: $neutral--40;
          }
        }
      
        span {
          color: $neutral--40;
        }

        img {
          width: 150px;
          height: 12px;
          filter: grayscale(100%);
          opacity: .6;
        }
      }
    }

    .footer__body-block--copyright {
      @include break(1049) {
        order: 2;
        margin: 0 0 $space-sm;
      }
    }
  }
}