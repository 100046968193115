.instructor-details {
  font-size: $fz-16;
  line-height: 1.25;
  color: $neutral--80;
}

.instructor-details__body {
  display: flex;
  flex-direction: column;

  @include break-reverse(768) {
    flex-direction: row;
  }
}

.instructor-details__visual {
  position: relative;
  border-radius: 50px 0 0 0;
  margin: 0 0 $space-lg;
  min-width: 270px;
  max-width: 270px;
  height: fit-content;
  background: $primary--20;
  overflow: hidden;

  @include break-and-break-reverse(371, 767) {
    min-width: 330px;
    max-width: 330px;
  }
  
  @include break-reverse(768) {
    margin: 0 6vw 0 0;
  }

  @include break-reverse(1050) {
    margin: 0 ($space-xxxl * 2) 0 0;
    min-width: 330px;
    max-width: 330px;
  }

  @include break-reverse(1441) {
    min-width: 420px;
    max-width: 420px;
  }

  img {
    width: 100%;
    object-fit: cover;
  }

  .icon {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: 85%;
    height: auto;
  }
}

.instructor-details__visual--empty {
  height: 332px;

  @include break-reverse(371) {
    height: 378px;
  }
  
  @include break-and-break-reverse(768, 1049) {
    height: 308px;
  }

  @include break-reverse(1050) {
    height: 378px;
  }

  @include break-reverse(1441) {
    height: 480px;
  }
}

.instructor-details__content {
  width: 100%;
}

.instructor-details__block {
  margin: 0 0 $space-lg;

  @include break-reverse(768) {
    margin: 0 0 $space-xl;
  }
}

.instructor-details__profile {
  display: flex;
  flex-direction: column;

  @include break-reverse(768) {
    flex-direction: row;
    align-items: center;
  }
}

.instructor-details__profile-image {
  margin: 0 0 $space-lg;
  min-width: 120px;
  max-width: 120px;
  min-height: 120px;
  max-height: 120px;
  border-radius: 50%;
  overflow: hidden;

  @include break-reverse(768) {
    margin: 0 $space 0 0;
    min-width: 90px;
    max-width: 90px;
    min-height: 90px;
    max-height: 90px;
  }

  img {
    object-fit: cover;
  }

  .icon {
    width: 90px;
    height: 90px;
  }
}

.instructor-details__title {
  margin: 0 0 $space-sm;
  font-weight: 600;
}

.instructor-details__title--main {
  font-size: $fz-20;

  @include break-reverse(1050) {
    font-size: $fz-30;
  }
}

.instructor-details__title--secondary {
  font-size: $fz-20;
}

.instructor-details__title--single {
  margin: 0;
}

.instructor-details__socials-list {
  display: flex;
  align-items: center;
  margin: 0 (-$space-xs);
}

.instructor-details__socials-item {
  margin: 0 $space-xs;
}

.instructor-details__contacts-item {
  margin: 0 0 $space-sm;

  .link {
    span {
      font-size: $fz-16;
    }
  }
}

.instructor-details__about {
  line-height: 24px;

  [itemscope] {
    position: relative;
    margin: 0 0 $space-sm;
    padding: 56.25% 0 0;
    width: 100%;
    overflow: hidden;

    @include break-reverse(768) {
      margin: 0 0 $space;
    }
  
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }
}