.programs {
  .table__row.programs-course {
    padding: 12px 0;
  }
}

.programs__columns {
  align-items: flex-start;
  padding: 0 0 ($space-xxxl + 6);
}

.programs__title {
  margin: 0 0 $space-lg;
}

.programs__header {
  margin: 0 0 $space;

  @include break-reverse(1050) {
    margin: 0 0 $space-lg;
  }

  .tabs__container {
    position: relative;
    margin: 0 0 $space;

    @include break-reverse(1050) {
      margin: $space-sm 0 $space-lg;
    }

    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translate(0, -.5px);
      width: 100%;
      height: 1px;
      background: $neutral--40;
    }

    .tabs__item-shadow {
      height: 29px;
    }

    .tabs__item {
      padding: 0 0 $space-xs;
      
      &:not(:first-of-type) {
        @include break(450) {
          margin: 0 0 0 10vw;
        }
      };
    }
  }
}

.programs__actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.programs__search {
  width: 100%;

  @include break-reverse(768) {
    max-width: 348px;
  }
  @include break(767) {
    margin: 0 0 $space;
  }

  .input__wrapper {
    max-width: 300px;
  }
}

.programs__sorting {
  display: flex;
}

.programs__sorting-select {
  min-width: 250px;
  width: 100%;
}

.programs__sorting-button {
  margin: 0 0 0 $space-sm;

  .icon {
    width: 31px;
    height: 17px;
    transition: transform .2s ease-out;

    path {
      &:last-of-type {
        fill: $color-grey--darkish;
      }
    }
  }
}

.programs__sorting-button--desc {
  .icon {
    transform: rotate(180deg);
  }
}