.calendar-dropdown {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &.calendar-dropdown--date-picker-hide {
    .calendar-dropdown__dropdown {
      height: 0;
      visibility: hidden;
    }
  }

  &.calendar-dropdown--month-picker-hide {
    .calendar-dropdown__month-picker {
      display: none;
    }
  
    .toolbar__date-preview-month {
      svg {
        transform: rotate(0);
      }
    }
  }

  &.calendar-dropdown--year-picker-hide {
    .calendar-dropdown__year-picker {
      display: none;
    }
  
    .toolbar__date-preview-year {
      svg {
        transform: rotate(0);
      }
    }
  }

  .MuiButtonBase-root.MuiPickersDay-root {
    position: relative;

    &.past-event,
    &.future-event {
      &:after {
        content: "";
        position: absolute;
        bottom: 10%;
        left: 50%;
        transform: translate(-50%, 0);
        width: 5px;
        height: 5px;
        background: $color-grey--darkish;
        border-radius: 50%;
      }
    }

    &.past-event {
      &:after {
        background: $color-grey--darkish;
      }
    }

    &.future-event {
      &:after {
        background: $color-secondary;
      }
    }
  }
}

.calendar-dropdown__date-preview {
  margin: 0 0 $space-sm;

  .date-preview,
  .date-preview__content {
    display: flex;
    align-items: center;
  }

  .date-preview__button-prev,
  .date-preview__button-next {
    svg {
      fill: $neutral--50;
    }

    &:hover {
      svg {
        fill: $neutral--50;
      }
    }
  }

  .date-preview__button-prev {
    svg {
      transform: rotate(90deg);
    }
  }

  .date-preview__button-next {
    svg {
      transform: rotate(-90deg);
    }
  }

  .date-preview__content {
    margin: 0 $space-xxs;
    padding: $space-xxs $space-xs;
    font-size: $fz-14;
    font-weight: 400;
    border-radius: 10px;

    &:hover {
      .btn__content {
        color: inherit;
      }
      
      .date-preview__calendar-icon {
        fill: $neutral--50;
      }
    }

    span {
      color: $color-text-primary;
    }
  }

  .date-preview__calendar-icon {
    fill: $neutral--50;
  }
}

.calendar-dropdown__dropdown {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 50%;
  transform: translate(-50%, 40px);
  max-width: 320px;
  width: 100%;
  background: $color-background;
  border: 1px solid $color-blue;
  border-radius: 10px;
  box-shadow: 0px 4px 30px rgba($color-black--dark, .1);
  overflow: hidden;

  .MuiPickerStaticWrapper-content {
    min-width: auto;

    > div > div {
      width: 100%;
    }
  }
  
  .MuiCalendarPicker-root.MuiCalendarPicker-root {
    width: 100%;
  
    .MuiCalendarPicker-viewTransitionContainer {
      > div > div > div:first-of-type {
        padding: 0 $space-md;
      }
    }
  
    .PrivatePickersSlideTransition-root {
      min-height: 240px;
  
      div[role="row"] {
        padding: 0 $space-md;
      }
    }
  
    .PrivatePickersFadeTransitionGroup-root,
    .MuiTypography-root.MuiTypography-caption,
    .MuiButtonBase-root.MuiPickersDay-root {
      font-weight: 500;
      font-size: $fz-14;
      line-height: 1.15;
    }
  
    .MuiTypography-root.MuiTypography-caption {
      color: $color-grey--extra;
    }
  
    .MuiButtonBase-root.MuiPickersDay-root {
      width: calc(100% / 7);
      color: $color-text-primary;
      
      &.Mui-selected {
        background: $color-primary;
        color: $color-text-light;

        &.between-day {
          background: $color-grey--soft;
          color: $color-text-primary;
        }
      }
  
      &.MuiPickersDay-today {
        background: $color-grey--soft;
        color: $color-primary;
        border: none;
      }
  
      &.MuiPickersDay-dayOutsideMonth {
        color: $color-grey--dark;
        pointer-events: none;
      }

      &.first-day,
      &.last-day,
      &.between-day {
        background: $color-grey--soft;
      }

      &.first-day,
      &.last-day {
        .date {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          background: $color-primary;
          color: $color-text-light;
          border-radius: 50%;
        }
      }
  
      &.first-day {
        border-radius: 50% 0 0 50%;
      }
  
      &.last-day {
        border-radius: 0 50% 50% 0;
      }
  
      &.between-day {
        border-radius: 0;
      }
    }
  }
  
  .MuiYearPicker-root.MuiYearPicker-root,
  .MuiMonthPicker-root.MuiMonthPicker-root {
    margin: 0;
    padding: 0 $space $space-md;
    width: 100%;
    height: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  
    .PrivatePickersYear-root,
    .PrivatePickersMonth-root {
      flex: 0 0 calc(100% / 3);
    }
  
    .PrivatePickersYear-yearButton,
    .PrivatePickersMonth-root {
      margin: 0 0 ($space-sm - 4);
      padding: $space-xs 0;
      height: auto;
      font-weight: 500;
      font-size: $fz-12;
      line-height: 1.35;
      color: $color-text-primary;
    
      &.Mui-selected {
        background: $color-primary;
        color: $color-text-light;
      }
    }
  }
}

.calendar-dropdown__picker-holder {
  position: relative;
}

.calendar-dropdown__date-picker {
  .MuiCalendarPicker-root {
    > div {
      &:first-of-type {
        display: none;
      }
    }
  }

  .MuiDialogActions-root {
    display: none;
  }
}

.calendar-dropdown__date-picker,
.calendar-dropdown__month-picker,
.calendar-dropdown__year-picker {
  background: $color-background;
}

.calendar-dropdown__month-picker,
.calendar-dropdown__year-picker {
  position: absolute;
  z-index: 5;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  
}

.calendar-dropdown__toolbar {
  .toolbar,
  .toolbar__date-preview,
  .toolbar__button-block {
    display: flex;
    align-items: center;
  }

  .toolbar {
    justify-content: space-between;
    margin: 0 0 ($space - 4);
    padding: $space $space-lg 0;
    width: 100%;
  }

  .toolbar__date-preview-month,
  .toolbar__date-preview-year {
    padding: 0 0 $space-xxs;
    font-weight: 400;
    font-size: $fz-14;
    line-height: 1.4;
    letter-spacing: .5px;
    border-bottom: 1px solid rgba($color-grey, .2);

    &:hover {
      .btn__content {
        color: $color-grey;
      }

      svg {
        fill: $color-grey;
      }
    }

    span {
      color: $color-grey;
    }

    svg {
      fill: $color-grey;
      transform: rotate(180deg);
    }
  }

  .toolbar__date-preview-year {
    margin: 0 0 0 $space-sm;
  }

  .toolbar__button-block {
    margin: 0 (-$space-xs 0 0);
  }

  .toolbar__button-prev,
  .toolbar__button-next {
    align-items: flex-end;
    margin: (-$space-xxs) 0 0;
    padding: ($space-xxs);

    &:hover {
      background: rgba($color-black--dark, .04);
      border-radius: 4px;

      svg {
        fill: $color-grey;
      }
    }

    svg {
      fill: $color-grey;
    }
  }

  .toolbar__button-prev {
    svg {
      transform: rotate(90deg);
    }
  }

  .toolbar__button-next {
    svg {
      transform: rotate(-90deg);
    }
  }
}

.calendar-dropdown__static {
  width: 100%;

  .MuiCalendarPicker-root {
    width: 100%;
    max-height: 100%;

    > div {
      &:first-of-type {
        display: none;
      }
    }
  }

  .MuiPickerStaticWrapper-content {
    > div > div {
      width: 100%;
      max-height: 100%;
    }
  }

  .PrivatePickersFadeTransitionGroup-root,
  .MuiPickerStaticWrapper-root {
    .MuiDayPicker-header {
      display: none;
    }
  }

  .PrivatePickersSlideTransition-root {
    display: flex;
    min-height: 55px;

    @include break(767) {
      overflow: auto;
    }

    @include break-reverse(768) {
      flex-wrap: wrap;
      min-height: 110px;
    }
  }

  .MuiDialogActions-root {
    display: none;
  }

  .MuiPickersDay-root.MuiPickersDay-root {
    margin: 0 $space-min $space-xxs;
    padding: ($space-sm + 2) 0 ($space-sm - 3);
    height: auto;
    font-size: $fz-16;
    font-weight: 600;
    line-height: 1.3;
    color: $color-text-primary;
    border: 1px solid $color-blue;
    border-radius: 5px;

    @include break(767) {
      min-width: 50px;
    }

    @include break-reverse(768) {
      width: 5.65%;
    }

    &:hover {
      background: $color-blue--lightish;
      border-color: $color-grey--medium;
    }

    &.Mui-selected,
    &.MuiPickersDay-today {
      color: $color-primary;
    }

    &.Mui-selected {
      background: inherit;
      border-color: $color-primary;

      &:hover {
        background: $color-blue--lightish;
        border-color: $color-primary;
      }

      &.MuiPickersDay-today {
        border-color: $color-primary;

        &:hover {
          border-color: $color-primary;
        }
      }

      &.past,
      &.future-month {
        color: $color-blue--darkish;

        .week-day {
          color: $color-blue--extra;
        }
      }

      .week-day {
        color: $color-primary;
      }
    }

    &.MuiPickersDay-today {
      background: $color-white--dark;
      border-color: $color-violet--light;

      &:hover {
        background: $color-violet--soft;
        border-color: $color-violet--dark;
      }

      .week-day {
        color: $color-primary;
      }
    }

    &.past,
    &.future-month {
      color: $color-blue--darkish;
    }

    .week-day {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 25%);
      font-size: $fz-12;
      font-weight: 400;
      line-height: 1.25;
      color: $color-blue--extra;
    }
  }

  .MuiPickersDay-dayOutsideMonth {
    display: none;
  }
}

.calendar-dropdown__static--mobile-only {
  .PrivatePickersSlideTransition-root {
    @include break(767) {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  
    &::-webkit-scrollbar {
      @include break(767) {
        width: 0;
        height: 0;
      }
    }
  }
}

.calendar-dropdown__wrapper {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 $space-lg;
  background: $color-background;

  @include break(1049) {
    align-items: center;
    margin: 0 0 $space;
  }

  @include break-reverse(1441) {
    align-items: flex-end;
  }


  .btn--filters-menu {
    @include break-reverse(481) {
      position: absolute;
      right: 0;
    }
  }

  .calendar-dropdown {
    align-items: flex-start;
    margin: 0 0 $space;
    width: 320px;
  
    @include break-and-break-reverse(481, 1049) {
      margin: 0;
    }

    @include break-reverse(1441) {
      margin: 0;
    }
  }

  .calendar-dropdown__date-preview {
    margin: 0;
  }

  .calendar-dropdown__dropdown {
    left: 0;
    right: 0;
    transform: translate(0, 35px);
  }
}