.badge {
  display: inline-flex;
  padding: ($space-xs - 5) $space-xs;
  font-size: $fz-11;
  font-weight: 600;
  line-height: 1;
  background: $additional--blue;
  color: $neutral--80;
  border-radius: 5px;
}
