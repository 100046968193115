.DayPicker.events-calendar {
  width: 100%;
  font-family: $workSans;
  font-size: $fz-18;
  outline: none;

  *:focus {
    outline: transparent auto 1px;
  }

  .DayPicker-wrapper {
    padding: 0;
  }

  .DayPicker-NavBar {
    display: none;
  }

  .DayPicker-Caption {
    position: absolute;
    top: -30px;
    margin: 0 0 $space-xs 0;
    padding: 0;
  }

  .DayPicker-Month {
    position: relative;
    margin: ($space - 4) 0 0;
    padding: ($space - 4) 0 0;
    width: 100%;
  }

  .DayPicker-WeekdaysRow {
    padding-bottom: $space-xxs;
  }

  .DayPicker-Weekday {
    padding: $space-xxs 0;
    font-size: $fz-14;
    font-weight: 600;
    text-align: left;
    color: $color-text-secondary;
  }

  .DayPicker-Day {
    position: relative;
    padding: ($space-xs - 2) ($space-xs - 2) ($space-sm + 2);
    width: calc(100% / 7);
    font-weight: 600;
    font-size: $fz-18;
    text-align: left;
    background: $color-background;
    background-clip: padding-box;
    color: $color-primary;
    border: 2px solid $color-primary;
    border-radius: 0;
    transition: $transition-time all $cubic-default;

    &:after {
      content: '';
      position: absolute;
      z-index: 2;
      top: -2px;
      bottom: -2px;
      left: -2px;
      right: -2px;
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      background: transparent;
      border: 2px solid transparent;
      transition: $transition-time all $cubic-default;
      pointer-events: none;
    }
  }

  .DayPicker-Day--outside.DayPicker-Day--disabled {
    font-weight: 400;
  }

  .DayPicker-Day--disabled,
  .DayPicker-Day--outside {
    background: transparent url('/img/drop-calendar-disabled.svg') no-repeat center !important;
    color: $color-text-secondary;
    border: 1px solid $color-grey--light;
    pointer-events: none;

    &:after {
      border: none !important;
    }
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled) {
    background: $color-background;

    &:after {
      border: 2px solid  $color-secondary;
    }

    &:hover {
      background-color: $color-light;
    }
  }

  & *:focus {
    outline: none;
  }

  .DayPicker-Caption div {
    display: flex;
    align-items: center;
    font-size: $fz-14;
  }

  .DayPicker-Caption__navigation-btn--left {
    transform: rotate(90deg);
  }

  .DayPicker-Caption__navigation-btn--right {
    transform: rotate(-90deg);
  }

  .DayPicker-Caption__navigation-btn--margin-right {
    margin: 0 ($space-xs + 4) 0 0;
  }

  .DayPicker-Caption__navigation-btn--margin-left {
    margin: 0 0 0 ($space-xs + 4);
  }

  .DayPicker-Caption__date-preview {
    display: flex;
    align-items: baseline;
  }

  .DayPicker-Caption__date-preview-icon {
    margin: 0 $space-xs 0 0;
    width: 16px;
    height: 16px;
    stroke: $color-text-primary;
  }

  .DayPicker-Day--today {
    color: $color-secondary;
  }

  .DayPicker-Day--pastEvents,
  .DayPicker-Day--futureEvents {
    &:before {
      content: '';
      position: absolute;
      top: 30px;
      left: 7px;
      width: 8px;
      height: 8px;
      border-radius: 4px;
    }
  }

  .DayPicker-Day--pastEvents {
    &:before {
      background-color: $color-grey--darkish;
    }
  }

  .DayPicker-Day--futureEvents {
    &:before {
      background-color: $color-secondary;
    }
  }
}