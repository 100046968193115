.payment-selector {
  margin: 0 0 $space;
  padding: $space-sm $space;
  background: $color-background;
  border: 1px solid $color-light;
  border-radius: 0 20px;

  .radio {
    @include break-reverse(767) {
      margin-left: auto;
    }

    &:not(:first-of-type) {
      margin-top: ($space-sm - 4);
    }

    .tooltip__wrapper {
      width: 224px;
      font-size: $fz-12;
    }
  }
}

.payment-selector__title {
  display: block;
  margin: 0 0 ($space-sm - 4);
  font-size: $fz-14;
  font-weight: 600;
  text-align: center;
}

.payment-action {
  width: 100%;
  min-height: 80px;

  .popup {
    b {
      color: $color-error;
    }
  }
}

.payment-action__title {
  display: block;
  margin: 0 0 ($space-xs + 2);
  font-size: $fz-18;
  font-weight: 600;
  text-align: center;
}

.payment-action__payflow {
  font-size: $fz-16;
}

.payment-action__button-wrapper--venmo {
  height: 45px;
  background: $color-purple--soft url('/img/venmo_logo.svg') center / 70px 20px no-repeat;
  border-radius: 23px;
}

.payment-action__payflow {
  width: 100%;
}