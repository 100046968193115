.account-schedule__calendar-block {
  position: relative;
  display: flex;
  margin: 0 0 $space-md;

  @include break(767) {
    padding: 0 0 100px;
  }

  @include break-reverse(768) {
    margin: 0 0 $space;
  }

  .calendar-dropdown {
    @include break(767) {
      position: absolute;
      margin: 0 (-$space + 4);
      width: 100vw;
    }
  }
}

.account-schedule-card {
  margin: 0 0 $space-lg;
  padding: $space-sm;
  width: 100%;
  background: $color-background;
  border: 1px solid $color-blue;
  border-radius: 25px 0px;
  box-shadow: 0 2px 18px rgba($color-text-primary, .08);

  @include break-reverse(768) {
    margin: 0 0 $space-md;
    padding: $space ($space-md - 2);
  }

  .ftable__cell {
    padding: 0;
    border: none;
  }
}

.account-schedule-card__action {
  display: flex;
  justify-content: flex-end;

  .btn {
    padding: $space-xs ($space-sm - 2);

    @include break-reverse(768) {
      padding: ($space-sm - 2) ($space - 4);
    }

    span {
      font-size: $fz-12;

      @include break-reverse(768) {
        font-size: $fz-14;
      }
    }

    .icon {
      transform: rotate(-90deg);
    }
  }
}

.account-schedule-card__heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 $space-sm;
  padding: 0 0 $space-sm;
  border-bottom: 1px solid $color-blue--darkish;

  @include break-reverse(768) {
    margin: 0 0 ($space-sm + 4);
    padding: 0 0 ($space-sm + 4);
  }

  .ftable__cell {
    min-width: auto;

    &:first-of-type {
      flex: 0 0 65%;
    }
    
    &:last-of-type {
      flex: 0 0 35%;
    }
  }
}

.account-schedule-card__offering-title {
  .link,
  .btn {
    margin: 0 0 $space-xs;
  }

  .link__content,
  .btn__content {
    font-size: $fz-16;
    font-weight: 700;

    @include break-reverse(768) {
      font-size: $fz-18;
    }

    span {
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.account-schedule-card__offering-id {
  display: flex;
  align-items: center;
  margin: 0 $space-xxs 0;
  font-size: $fz-12;
  font-weight: 400;
  text-transform: uppercase;
}

.account-schedule-card__offering-instructor {
  font-size: $fz-14;

  @include break-reverse(768) {
    font-weight: 600;
  }
}

.account-schedule-card__body {
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 0 0 $space-sm;
  font-size: $fz-14;

  @include break-reverse(768) {
    margin: 0 0 $space;
  }

  .ftable__cell {
    flex: 0 0 50%;
    margin: 0 0 $space-sm;
    padding: 0 $space-sm 0 0;
    min-width: auto;

    @include break-reverse(768) {
      flex: 0 0 25%;
      margin: 0 0 $space;
    }

    &:last-child {
      @include break-reverse(768) {
        flex: 0 0 auto;
      }
    }
  }
}

.account-schedule-card__label {
  margin: 0 0 $space-xs;
}

.account-schedule-card__value {
  font-weight: 600;
}

.account-schedule-card__footer {
  .ftable__cell-content,
  .account-schedule-card__footer-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.account-schedule-card__footer-buttons {
  margin: 0 (-$space-xs);

  @include break-reverse(768) {
    margin: 0 (-$space);
  }
}

.account-schedule-card__button {
  padding: 0 $space-xs;

  @include break-reverse(768) {
    padding: 0 $space;
  }
}