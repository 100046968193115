.offering-timetable {
  padding: 0 0 $space-lg;

  @include break-reverse(768) {
    padding: 0 0 ($space-lg * 2);
  }
}

.offering-timetable__body {
  max-width: 750px;

  h2 {
    margin: 0 0 $space-md;
    font-size: $fz-20;
    font-weight: 600;
    line-height: 1.35;
  
    @include break-reverse(768) {
      font-size: $fz-30;
    }

    &:not(:first-of-type) {
      margin: $space-lg 0 $space-md;

      @include break-reverse(768) {
        margin: $space-xxl 0 $space-md;
      }
    }
  }

  h3 {
    margin: 0 0 $space-sm;
    font-size: $fz-16;
    font-weight: 600;
    line-height: 1.25;
  
    @include break-reverse(768) {
      font-size: $fz-20;
    }

    &:not(:first-of-type) {
      margin: $space-md 0 $space-sm;

      @include break-reverse(768) {
        margin: $space-lg 0 $space-sm;
      }
    }
  }

  p {
    margin: 0 0 $space-xs;
  }

  li {
    position: relative;
    padding: 0 0 0 ($space - 4);

    &:not(:last-of-type) {
      margin: 0 0 $space-xs;
    }

    &:before {
      content: "";
      position: absolute;
      top: 7.5px;
      left: 9px;
      width: 4px;
      height: 4px;
      background: $neutral--80;
      border-radius: 50%;
    }
  }

  a {
    cursor: pointer;

    &:hover {
      color: $color-secondary;
    }
  }
}