.registration-donation {
  display: flex;
  flex-direction: column;
}

.registration-donation__note {
  display: flex;
  margin: 0 0 $space;
  font-size: $fz-14;

  .icon {
    margin-right: $space-xxs;
    min-width: 20px;
  }
}

.registration-donation__actions {
  display: flex;
  flex-wrap: wrap;
}

.registration-donation__amount-wrapper {
  min-width: 260px;
}

.registration-donation__action-wrapper {
  display: flex;
  align-items: flex-end;
  margin: 0 ($space-sm + 4) $space 0;
  width: 100%;

  .tooltip--bottom .tooltip__content {
    right: auto;
    top: -32px;

    &:after {
      top: 40px;
      left: 39px;
      right: auto;
    }
  }

  .tooltip {
    margin: 0 0 0 ($space-xs + 2);
    min-width: 270px;
  }

  .tooltip__wrapper {
    left: 14%!important;
    padding: ($space-xs + 2);
    min-width: auto;
    font-size: $fz-12;
  }
}

.registration-donation__amount-wrapper {
  align-self: flex-start;
}

.registration-donation__action-wrapper .checkbox_label {
  @include break(767) {
    width: 270px;
  }
}
.registration-donation__action-wrapper .tooltip--bottom .tooltip__content {
  @include break(767) {
    top: 25px;
    left: -280px;
  
    &:after {
      top: 1px;
      left: 286px;
      right: auto;
    }
  }
}
.registration-donation__action-wrapper .tooltip__wrapper {
  @include break(767) {
    top: 0;
    left: 0 !important;
  }
}

.registration-donation__action-wrapper .tooltip {
  @include break(767) {
    min-width: auto;
  }
}