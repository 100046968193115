.checkout {
  .loader__wrapper {
    margin: 75px 0;
  }
}

.checkout__summary-title {
  margin: 0 0 $space;
}

.checkout__correction {
  display: flex;
  justify-content: space-between;
  margin-bottom: $space-sm;
  padding-right: $space-sm;
}

.checkout__correction-label {
  font-size: $fz-14;
}

.checkout__correction-value {
  font-weight: 600;
}

.checkout__total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: $space-sm;
  font-weight: 600;
}

.checkout__total-value {
  font-size: $fz-22;
}
