.account-transactions {
  .ftable {
    margin: 0 0 ($space-sm - 4);
    overflow: hidden;

    @include break-reverse(768) {
      margin: 0 0 $space;
      border: 1px solid $color-blue;
      border-radius: 10px 10px 25px 25px;
      box-shadow: 0 2px 18px rgba($color-text-primary, .08);
    }
  }

  .ftable__row {
    border-top: 1px solid $color-blue;

    @include break(767) {
      margin: 0 0 ($space-xs + 4);
      border: 1px solid $color-blue;
      border-radius: 9px 9px 0 0;
      overflow: hidden;
    }

    &:first-of-type {
      @include break-reverse(768) {
        border: none;
      }
    }
  }
  
  .ftable__cell {
    min-width: auto;
    font-size: $fz-14;
    border: none;

    @include break(767) {
      padding: $space-xs $space-sm;
    }
  }

  .ftable__cell-content {
    @include break(767) {
      display: flex;
      justify-content: space-between;
    }

    > span {
      @include break(767) {
        flex: 0 0 40%;
      }

      & + span {
        @include break(767) {
          flex: 0 0 60%;
          font-weight: 600;
          text-align: right;
        }
      }
    }
  }
}

.account-transactions__table-heading {
  padding: ($space-xs - 2) ($space-xs + 2);
  background: $neutral--10;

  .ftable__cell-content {
    font-size: $fz-12;
    font-weight: 600;
    text-transform: uppercase;
  }
}

.account-transactions__card {
  padding: ($space-sm - 2) ($space-xs + 2);
  background: $color-background;

  @include break(767) {
    flex-direction: column;
    padding: 0;
  }
}

.account-transactions__id {
  @include break(767) {
    order: 2;
    padding: $space-sm $space-sm $space-xs;
  }

  @include break-reverse(768) {
    flex: 0 0 8.9%;
  }
}

.account-transactions__date {
  @include break(767) {
    order: 1;
    padding: $space-sm;
    font-size: $fz-16;
    background: $neutral--10;
    border-bottom: 1px solid $color-blue;
  }

  @include break-reverse(768) {
    flex: 0 0 15.9%;
  }

  span {
    @include break(767) {
      font-weight: 600;
    }
  }
}

.account-transactions__class-title {
  @include break(767) {
    order: 3;
  }

  @include break-reverse(768) {
    flex: 0 0 36.2%;
  }
}

.account-transactions__amount {
  @include break(767) {
    order: 4;
    padding: $space-xs $space-sm $space-sm;
    text-align: left;
  }

  @include break-reverse(768) {
    flex: 0 0 15.4%;
    text-align: right;
  }
}

.account-transactions__action {
  flex: 0 0 23.6%;

  .btn {
    white-space: nowrap;
  }
}

.account-transactions__receipt-button {
  margin: 0 0 0 auto;
}