.cdm-text {
  display: inline-block;
  font: 400 $fz-16;
  line-height: 1.5;

  .cdm-text {
    margin: 0;
    padding: 0;
    font-size: inherit;
    line-height: inherit;
  }

  .cdm-link {
    font-size: inherit;
  }
}

.cdm-text--space-1 {
  line-height: 1.1;
}

.cdm-text--space-2 {
  line-height: 2;
}

.cdm-text--newline {
  display: block;
  margin-bottom: $space-xxs;
}

.cdm-text--bold {
  font-weight: 700;
}

.cdm-text--semibold {
  font-weight: 600;
}

.cdm-text--sm {
  font-size: $fz-14;
  line-height: 1.64;
}

.cdm-text--md {
  font-size: $fz-18;
}

.cdm-text--highlighted {
  color: $color-secondary;
}

.cdm-text--underlined {
  text-decoration: underline;
}

.cdm-text--title-1 {
  display: block;
  margin: 0 0 ($space-sm - 2) 0;
  font-size: $fz-24;
  font-weight: 600;
  line-height: 1.3;
}

.cdm-text--title-2 {
  display: block;
  line-height: 1.3;
  margin: 0 0 ($space-sm - 2) 0;
}

.cdm-text--title-3 {
  display: block;
  margin: 0 0 ($space-sm - 2) 0;
  font-size: $fz-18;
  font-weight: 600;
  line-height: 1.3;
  color: $color-secondary;
}

.cdm-text--title-clear {
  margin-bottom: $space-xxs;
}

.cdm-text--paragraph {
  display: block;
  margin-bottom: $space-sm - 2;
}

.cdm-link {
  font-size: $fz-16;
  font-weight: 400;
  text-decoration: underline;
  color: $color-primary;

  &:link,
  &:visited {
    text-decoration: underline;
    color: $color-primary;
  }

  &:hover {
    text-decoration: none;
  }
}

.cdm-list {
  margin: 0;
  padding: 0;
  font-size: 16px;
  list-style: none;

  li {
    position: relative;
    display: block;
    margin-bottom: $space-sm - 2;
    padding-left: 14px;
    line-height: 1.5;

    .cdm-text {
      font-size: inherit;
      line-height: inherit;
    }

    .cdm-link {
      font-size: inherit;
      line-height: inherit;
    }

    &:before {
      position: absolute;
      content: '\2022';
      left: 0;
      top: 0;
      font-size: inherit;
      font-weight: 700;
      line-height: inherit;
    }
  }

  .cdm-list--sm {
    font-size: $fz-14;
  }

  .cdm-list--xs {
    font-size: $fz-12;
  }

  .cdm-list--md {
    font-size: $fz-18;
  }

  .cdm-list--lg {
    font-size: $fz-21;
  }

  .cdm-list--alpha {
    counter-reset: aplhabetic;

    li {
      padding-left: 22px;
      counter-increment: aplhabetic;

      &:before {
        content: counter(aplhabetic, lower-alpha) '.';
      }
    }
  }

  .cdm-list--numeric {
    counter-reset: nums;

    li {
      counter-increment: nums;
      padding-left: 22px;

      &:before {
        content: counter(nums) '.';
        top: 0px;
      }
    }
  }

  .cdm-list--nav {
    li {
      color: $color-primary;
      font-size: $fz-16;
      font-weight: 700;

      &:before {
        top: -3px;
        font-size: $fz-20;
        color: $color-primary;
      }

      a {
        font-size: $fz-16;
        font-weight: 700;
        color: $color-primary;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .cdm-list--offset {
    margin-left: $space-md;
  }
}

.cdm-group {
  margin-bottom: $space-sm;
}

.cdm-group--sm {
  margin-bottom: $space-xs;
}

.cdm-group--xs {
  margin-bottom: $space-xxs;
}

.cdm-section {
  margin-bottom: $space-md;
}

.cdm-section--sm {
  margin-bottom: $space;
}

.cdm-section--md {
  margin-bottom: $space-lg;
}

.cdm-section--lg {
  margin-bottom: $space-xl;
}

.cdm-img {
  display: inline-block;
  max-width: 100%;
  width: auto;
}

.cdm-img--block {
  display: block;
}

[class*='cdm'] {
  text-size-adjust: none;
}