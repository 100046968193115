.burger-btn {
  position: relative;
  z-index: 25;
  transform: rotate(0deg);
  margin: $space-xs 0;
  padding: ($space-xs + 2);
  width: 30px;
  height: 21px;
  transition: .5s ease-in-out;
  cursor: pointer;

  .btn__content {
    span {
      position: absolute;
      left: 0;
      transform: rotate(0deg);
      display: block;
      height: 3px;
      width: 100%;
      background: $color-primary;
      border-radius: 2px;
      opacity: 1;
      transition: .25s ease-in-out;

      &:nth-child(1) {
        top: 0;
      }

      &:nth-child(2),
      &:nth-child(3) {
        top: 9px;
      }

      &:nth-child(4) {
        top: 18px;
      }
    }
  }
}

.burger-btn.active {
  position: fixed;
  top: 12px;
  right: 20px;

  .btn__content {
    span {
      &:nth-child(1) {
        top: 9px;
        width: 0;
        left: 50%;
      }

      &:nth-child(2) {
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
      }

      &:nth-child(4) {
        top: 9px;
        width: 0;
        left: 50%;
      }
    }
  }
}