.scroll-top-btn {
  position: fixed;
  z-index: 100;
  bottom: 70px;
  right: 12px;
  transform: translateY(200px);
  height: 44px;
  width: 44px;
  background: $color-background;
  border: 1px solid $color-blue--dark;
  border-radius: 50%;
  box-shadow: 0px 4px 20px rgba($color-grey--darkish, .25);
  transition: transform .1s linear;
  cursor: pointer;

  &--shown {
    transform: translateY(0);
  }

  .btn__content {
    position: absolute;
    left: 50%;
    top: 55%;
    transform: translate(-50%, -50%);
    width: 15px;
    height: 15px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}
