.error__wrapper {
  position: absolute;
  left: 0;
  bottom: -17px;
  display: flex;
  align-items: center;
  font-size: $fz-12;
  color: $color-error;
  white-space: nowrap;

  .icon {
    margin-right: 4px;
    width: 11px;
    height: 11px;
  }
}