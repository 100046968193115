.input--phone {
  .flag-dropdown,
  .country-list {
    .flag {
      width: 30px;
      height: 18px;
      background: url('../../assets/flags-sprite.png');
      border-radius: 2px;
      overflow: hidden;

      &.ad {
        background-position: -2px -2px;
      }

      &.ae {
        background-position: -36px -2px;
      }

      &.af {
        background-position: -2px -24px;
      }  

      &.ag {
        background-position: -36px -24px;
      }

      &.al {
          background-position: -2px -46px;
      }

      &.am {
        background-position: -36px -46px;
      }

      &.ao {
        background-position: -70px -2px;
      }

      &.ar {
        background-position: -70px -24px;
      }

      &.at {
        background-position: -70px -46px;
      }

      &.au {
        background-position: -2px -68px;
      }

      &.aw {
        background-position: -36px -68px;
      }

      &.az {
        background-position: -70px -68px;
      }

      &.ba {
        background-position: -104px -2px;
      }

      &.bb {
        background-position: -104px -24px;
      }

      &.bd {
        background-position: -104px -46px;
      }

      &.be {
        background-position: -104px -68px;
      }

      &.bf {
        background-position: -2px -90px;
      }

      &.bg {
        background-position: -36px -90px;
      }

      &.bh {
        background-position: -70px -90px;
      }

      &.bi {
        background-position: -104px -90px;
      }

      &.bj {
        background-position: -2px -112px;
      }

      &.bn {
        background-position: -36px -112px;
      }

      &.bo {
        background-position: -70px -112px;
      }

      &.bq {
        background-position: -104px -112px;
      }

      &.br {
        background-position: -138px -2px;
      }

      &.bs {
        background-position: -138px -24px;
      }

      &.bt {
        background-position: -138px -46px;
      }

      &.bw {
        background-position: -138px -68px;
      }

      &.by {
        background-position: -138px -90px;
      }

      &.bz {
        background-position: -138px -112px;
      }

      &.ca {
        background-position: -2px -134px;
      }

      &.cd {
        background-position: -36px -134px;
      }

      &.cf {
        background-position: -70px -134px;
      }

      &.cg {
        background-position: -104px -134px;
      }

      &.ch {
        background-position: -138px -134px;
      }

      &.ci {
        background-position: -172px -2px;
      }

      &.cl {
        background-position: -172px -24px;
      }

      &.cm {
        background-position: -172px -46px;
      }

      &.cn {
        background-position: -172px -68px;
      }

      &.co {
        background-position: -172px -90px;
      }

      &.cr {
        background-position: -172px -112px;
      }

      &.cu {
        background-position: -172px -134px;
      }

      &.cv {
        background-position: -2px -156px;
      }

      &.cw {
        background-position: -36px -156px;
      }

      &.cy {
        background-position: -70px -156px;
      }

      &.cz {
        background-position: -104px -156px;
      }

      &.de {
        background-position: -138px -156px;
      }

      &.dj {
        background-position: -172px -156px;
      }

      &.dk {
        background-position: -2px -178px;
      }

      &.dm {
        background-position: -36px -178px;
      }

      &.do {
        background-position: -70px -178px;
      }

      &.dz {
        background-position: -104px -178px;
      }

      &.ec {
        background-position: -138px -178px;
      }

      &.ee {
        background-position: -172px -178px;
      }

      &.eg {
        background-position: -206px -2px;
      }

      &.er {
        background-position: -206px -24px;
      }

      &.es {
        background-position: -206px -46px;
      }

      &.et {
        background-position: -206px -68px;
      }

      &.fi {
        background-position: -206px -90px;
      }

      &.fj {
        background-position: -206px -112px;
      }

      &.fm {
        background-position: -206px -134px;
      }

      &.fr {
        background-position: -206px -156px;
      }

      &.ga {
        background-position: -206px -178px;
      }

      &.gb {
        background-position: -2px -200px;
      }

      &.gd {
        background-position: -36px -200px;
      }

      &.ge {
        background-position: -70px -200px;
      }

      &.gf {
        background-position: -104px -200px;
      }

      &.gh {
        background-position: -138px -200px;
      }

      &.gm {
        background-position: -172px -200px;
      }

      &.gn {
        background-position: -206px -200px;
      }

      &.gp {
        background-position: -240px -2px;
      }

      &.gq {
        background-position: -240px -24px;
      }

      &.gr {
        background-position: -240px -46px;
      }

      &.gt {
        background-position: -240px -68px;
      }

      &.gu {
        background-position: -240px -90px;
      }

      &.gw {
        background-position: -240px -112px;
      }

      &.gy {
        background-position: -240px -134px;
      }

      &.hk {
        background-position: -240px -156px;
      }

      &.hn {
        background-position: -240px -178px;
      }

      &.hr {
        background-position: -240px -200px;
      }

      &.ht {
        background-position: -2px -222px;
      }

      &.hu {
        background-position: -36px -222px;
      }

      &.id {
        background-position: -70px -222px;
      }

      &.ie {
        background-position: -104px -222px;
      }

      &.il {
        background-position: -138px -222px;
      }

      &.in {
        background-position: -172px -222px;
      }

      &.io {
        background-position: -206px -222px;
      }

      &.iq {
        background-position: -240px -222px;
      }

      &.ir {
        background-position: -2px -244px;
      }

      &.is {
        background-position: -36px -244px;
      }

      &.it {
        background-position: -70px -244px;
      }

      &.jm {
        background-position: -104px -244px;
      }

      &.jo {
        background-position: -138px -244px;
      }

      &.jp {
        background-position: -172px -244px;
      }

      &.ke {
        background-position: -206px -244px;
      }

      &.kg {
        background-position: -240px -244px;
      }

      &.kh {
        background-position: -274px -2px;
      }

      &.ki {
        background-position: -274px -24px;
      }

      &.km {
        background-position: -274px -46px;
      }

      &.kn {
        background-position: -274px -68px;
      }

      &.kp {
        background-position: -274px -90px;
      }

      &.kr {
        background-position: -274px -112px;
      }

      &.kw {
        background-position: -274px -134px;
      }

      &.kz {
        background-position: -274px -156px;
      }

      &.la {
        background-position: -274px -178px;
      }

      &.lb {
        background-position: -274px -200px;
      }

      &.lc {
        background-position: -274px -222px;
      }

      &.li {
        background-position: -274px -244px;
      }

      &.lk {
        background-position: -2px -266px;
      }

      &.lr {
        background-position: -36px -266px;
      }

      &.ls {
        background-position: -70px -266px;
      }

      &.lt {
        background-position: -104px -266px;
      }

      &.lu {
        background-position: -138px -266px;
      }

      &.lv {
        background-position: -172px -266px;
      }

      &.ly {
        background-position: -206px -266px;
      }

      &.ma {
        background-position: -240px -266px;
      }

      &.mc {
        background-position: -274px -266px;
      }

      &.md {
        background-position: -308px -2px;
      }

      &.me {
        background-position: -308px -24px;
      }

      &.mg {
        background-position: -308px -46px;
      }

      &.mh {
        background-position: -308px -68px;
      }

      &.mk {
        background-position: -308px -90px;
      }

      &.ml {
        background-position: -308px -112px;
      }

      &.mm {
        background-position: -308px -134px;
      }

      &.mn {
        background-position: -308px -156px;
      }

      &.mo {
        background-position: -308px -178px;
      }

      &.mq {
        background-position: -308px -200px;
      }

      &.mr {
        background-position: -308px -222px;
      }

      &.mt {
        background-position: -308px -244px;
      }

      &.mu {
        background-position: -308px -266px;
      }

      &.mv {
        background-position: -2px -288px;
      }

      &.mw {
        background-position: -36px -288px;
      }

      &.mx {
        background-position: -70px -288px;
      }

      &.my {
        background-position: -104px -288px;
      }

      &.mz {
        background-position: -138px -288px;
      }

      &.na {
        background-position: -172px -288px;
      }

      &.nc {
        background-position: -206px -288px;
      }

      &.ne {
        background-position: -240px -288px;
      }

      &.ng {
        background-position: -274px -288px;
      }

      &.ni {
        background-position: -308px -288px;
      }

      &.nl {
        background-position: -2px -310px;
      }

      &.no {
        background-position: -36px -310px;
      }

      &.np {
        background-position: -70px -310px;
      }

      &.nr {
        background-position: -104px -310px;
      }

      &.nz {
        background-position: -138px -310px;
      }

      &.om {
        background-position: -172px -310px;
      }

      &.pa {
        background-position: -206px -310px;
      }

      &.pe {
        background-position: -240px -310px;
      }

      &.pf {
        background-position: -274px -310px;
      }

      &.pg {
        background-position: -308px -310px;
      }

      &.ph {
        background-position: -342px -2px;
      }

      &.pk {
        background-position: -342px -24px;
      }

      &.pl {
        background-position: -342px -46px;
      }

      &.pr {
        background-position: -342px -68px;
      }

      &.ps {
        background-position: -342px -90px;
      }

      &.pt {
        background-position: -342px -112px;
      }

      &.pw {
        background-position: -342px -134px;
      }

      &.py {
        background-position: -342px -156px;
      }

      &.qa {
        background-position: -342px -178px;
      }

      &.re {
        background-position: -342px -200px;
      }

      &.ro {
        background-position: -342px -222px;
      }

      &.rs {
        background-position: -342px -244px;
      }

      &.ru {
        background-position: -342px -266px;
      }

      &.rw {
        background-position: -342px -288px;
      }

      &.sa {
        background-position: -342px -310px;
      }

      &.sb {
        background-position: -2px -332px;
      }

      &.sc {
        background-position: -36px -332px;
      }

      &.sd {
        background-position: -70px -332px;
      }

      &.se {
        background-position: -104px -332px;
      }

      &.sg {
        background-position: -138px -332px;
      }

      &.si {
        background-position: -172px -332px;
      }

      &.sk {
        background-position: -206px -332px;
      }

      &.sl {
        background-position: -240px -332px;
      }

      &.sm {
        background-position: -274px -332px;
      }

      &.sn {
        background-position: -308px -332px;
      }

      &.so {
        background-position: -342px -332px;
      }

      &.sr {
        background-position: -2px -354px;
      }

      &.ss {
        background-position: -36px -354px;
      }

      &.st {
        background-position: -70px -354px;
      }

      &.sv {
        background-position: -104px -354px;
      }

      &.sy {
        background-position: -138px -354px;
      }

      &.sz {
        background-position: -172px -354px;
      }

      &.td {
        background-position: -206px -354px;
      }

      &.tg {
        background-position: -240px -354px;
      }

      &.th {
        background-position: -274px -354px;
      }

      &.tj {
        background-position: -308px -354px;
      }

      &.tl {
        background-position: -342px -354px;
      }

      &.tm {
        background-position: -376px -2px;
      }

      &.tn {
        background-position: -376px -24px;
      }

      &.to {
        background-position: -376px -46px;
      }

      &.tr {
        background-position: -376px -68px;
      }

      &.tt {
        background-position: -376px -90px;
      }

      &.tv {
        background-position: -376px -112px;
      }

      &.tw {
        background-position: -376px -134px;
      }

      &.tz {
        background-position: -376px -156px;
      }

      &.ua {
        background-position: -376px -178px;
      }

      &.ug {
        background-position: -376px -200px;
      }

      &.us {
        background-position: -376px -222px;
      }

      &.uy {
        background-position: -376px -244px;
      }

      &.uz {
        background-position: -376px -266px;
      }

      &.va {
        background-position: -376px -288px;
      }

      &.vc {
        background-position: -376px -310px;
      }

      &.ve {
        background-position: -376px -332px;
      }

      &.vn {
        background-position: -376px -354px;
      }

      &.vu {
        background-position: -2px -376px;
      }

      &.ws {
        background-position: -36px -376px;
      }

      &.xk {
        background-position: -70px -376px;
      }

      &.ye {
        background-position: -104px -376px;
      }

      &.za {
        background-position: -138px -376px;
      }

      &.zm {
        background-position: -172px -376px;
      }

      &.zw {
        background-position: -206px -376px;
      }
    }
  }
}