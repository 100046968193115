.slide-menu {
  position: fixed;
  z-index: 4;
  left: 0;
  bottom: 0;
  display: flex;
  width: 100%;
  height: 100%;
  transition: visibility .5s ease-in-out;
  visibility: hidden;

  &--shown {
    visibility: visible;

    .slide-menu__overlay {
      background-color: rgba($color-black--dark, .7);
      visibility: visible;
    }

    .slide-menu__inner {
      transform: translate(0, 0) !important;
    }
  }

  &__overlay {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    transition: all .5s ease-in-out;
    visibility: hidden;
  }

  &__inner {
    position: absolute;
    display: flex;
    background: $color-background;
    transition: transform .5s ease-in-out;
  }

  &__body {
    overflow-y: auto;
  }

  &__header {
    position: absolute;
    top: 0;
    left: 0;
    padding: $space-sm ($space-sm + 4);
    width: 100%;
    border-bottom: 1px $color-grey--darkish solid;
  }

  &__close-btn {
    padding: ($space-xxs + 1);

    .icon {
      width: 20px;
    }
  }

  &__btn-wrapper {
    position: absolute;
    z-index: 9;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    padding: 16px 0;
    width: 100%;
    background: $color-background;
    box-shadow: 0 4px 20px rgba($color-grey--darkish, .25);
  }

  &.mobile-nav {
    z-index: 20;
    height: calc(100% - 61px);

    .slide-menu__inner {
      z-index: 5;
      max-width: 300px;
      width: 75%;
      height: 100%;

      .slide-menu__body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: baseline;
        padding: ($space-xl * 2) 0 0 $space-lg;
        overflow-x: hidden;

        @include break(1049) {
          width: 100%;
        }

        @include break-height(450) {
          padding: $space-xl 0 0 $space-lg;
        }

        .tabs__container {
          margin: 0;
        }

        .tabs {
          display: flex;
          flex-direction: column;
          align-items: baseline;
        }

        .tabs__item {
          font-size: $fz-20;
        }

        .tabs__item:not(:last-child) {
          margin: 0 0 $space-md 0;
        }

        .tabs__item--active {
          color: $primary--70;
        }

        .tabs__item:hover:before {
          content: none;
        }

        .btn--navigation,
        .link {
          padding: $space 0;

          span {
            font-size: $fz-18;
          }
        }
      }
    }
  }

  &.mobile-filters {
    z-index: 25;

    .slide-menu__inner {
      top: 0;
      bottom: 0;
      padding: $space-xxl 0 0;
      max-width: 360px;
      width: 100%;
    }

    .slide-menu__body {
      margin: 0 0 80px;
      padding: $space-md 0 80px;
      width: 100%;

      .input-group__wrapper,
      .faculty__filters .select {
        max-width: 308px;
        width: 100%;
      }

      .schedule-table__filters-body,
      .faculty__filters,
      .programs__filter-form {
        flex-direction: column;
        padding: 0 ($space + 2);

        .input-group__wrapper {
          margin-right: 0;
          margin-bottom: 40px;
        }
      }
    }

    .select {
      max-width: 300px;
      width: 100%;
    }

    .schedule-table__filters-header,
    .faculty__filters-header,
    .programs__filter-action-buttons--mobile {
      position: absolute;
      top: 20px;
      right: 32px;
      margin: 0;
    }

    .programs__filter-action-buttons--mobile {
      .programs__filter-clear-all {
        padding: 0;
      }
    }

    .faculty__filters-title {
      display: none;
    }

    .faculty__filters-header {
      .btn {
        left: 26px;
      }
    }

    .schedule-table__filters-wrapper {
      .tabs__container {
        margin: ($space-xs + 4) 0 $space-lg;
      }
    }

    .alphabet-filter-tabs {
      padding: 0 ($space - 2);

      .tabs__item {
        margin: 0 $space-min $space-min 0;
        width: 40px;
        height: 40px;
        border: 1px solid $color-blue--medium;

        &:hover {
          color: $color-primary;
        }

        &--active {
          text-decoration: underline;
          border: 1px solid $color-primary;
        }
      }
    }

    .programs__filter-form {
      .form__section:last-of-type {
        display: none;
      }
    }
  }

  .list {
    width: 100%;
  }
}

.sorting-menu {
  padding: 0 $space;
}

.sorting-menu__method-list,
.sorting-menu__order-list {
  margin: 0 0 $space;
  border-bottom: 1px solid $color-grey--darkish;
}

.sorting-menu__title {
  margin: 0 0 $space;
}

.sorting-menu__method-item,
.sorting-menu__order-item {
  margin: 0 0 $space;
  padding: 0 0 0 $space;

  &:hover {
    .icon {
      fill: $color-grey--darkish;
    }

    span {
      color: $color-text-primary;
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: -2px;
    left: -1px;
    transform: rotate(0) translate(0%, 120%) scale(0);
    width: 12px;
    height: 6px;
    border-left: 2px solid $color-primary;
    border-bottom: 2px solid $color-primary;
    transition: all .15s ease;
  }

  span {
    font-weight: 500;
    color: $color-text-primary;
  }

  .icon {
    fill: $color-grey--darkish;
  }
}

.sorting-menu__method-item--active,
.sorting-menu__order-item--active {
  &:after {
    transform: rotate(-45deg) translate(0%, 120%) scale(1);
  }
}

.sorting-menu__order-item--active {
  .icon {
    fill: $color-primary;
  }

  &:hover {
    .icon {
      fill: $color-primary;
    }
  }
}

.slide-menu--left {
  .slide-menu__inner {
    left: 0;
    transform: translateX(-100%);
  }

  .slide-menu__close-btn {
    margin: 0 0 0 auto;
  }
}

.slide-menu--right {
  .slide-menu__inner {
    transform: translateX(100%);
    right: 0;
  }
}