.tabs__container {
  margin: $scheduleTabsListMargin;
}

.tabs {
  position: relative;
  padding-left: 0;
}

.tabs--bordered {
  border-bottom: 1px solid $color-grey--light;

  @include break(450) {
    display: flex;
    justify-content: space-between;
  }
}

.tabs__label {
  display: block;
  padding: $space-xxs 0;
  font-size: $fz-12;
  font-weight: 600;
  text-transform: uppercase;
  opacity: .4;
}

.tabs__item {
  position: relative;
  display: inline-block;
  padding: $space-xs 0 $space-xs * 1.5;
  font-size: $fz-18;
  font-weight: 600;
  list-style: none;
  color: $neutral--80;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: $space-xl;

    @include break(450) {
      margin-right: 0;
    }
  }
}

@media (pointer:fine) {
  .tabs__item:hover:not(.tabs__item--active) {
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      background: transparent;
      border-bottom: 2px solid $color-primary;
    }
  }
}

.tabs__item-shadow {
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  transform: translateX(0) scaleX(0);
  transform-origin: left center;
  height: 37px;
  width: 100%;
  background: transparent;
  border-bottom: $scheduleTabActiveBorderBottom;
  transition: $transition-time * 2;
  pointer-events: none;
}