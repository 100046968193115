.password-recovery {
  margin: 0 auto;
  max-width: 300px;
}

.password-recovery__banner {
  margin: 0 0 ($space-sm - 4);

  .icon {
    margin: 0 auto;
    width: 200px;
    height: auto;

    @include break-all(767, 375) {
      width: 100px;
    }
  }
}

.password-recovery__title {
  text-align: center;
  color: $color-primary;

  @include break-all(767, 375) {
    font-size: 26px;
  }
}

.password-recovery__text {
  display: inline-block;
  margin: 0 0 $space-md;
  font-size: $fz-16;
  line-height: 1.5;

  .btn {
    display: inline;
    
    span {
      font-size: $fz-16;
      font-weight: 400;
    }
  }
}

.password-recovery__email {
  display: flex;
  justify-content: center;
  margin: 0 0 $space-md;

  a {
    font-size: $fz-16;
  }
}

.password-recovery__actions {
  display: flex;
  justify-content: center;
  margin: $space-xl 0 0;

  .btn {
    & + .btn {
      margin: 0 0 0 ($space-xs + 4);
    }
  }

  .icon {
    transform: rotate(90deg);
  }
}

.password-recovery__resend-email {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.password-recovery--step2 {
  .password-recovery__actions {
    justify-content: center;
    margin: 0 0 $space-xl;
  }

  .password-recovery__text {
    margin: 0 0 $space-sm;
  }
}

.password-recovery--step3 {
  .password-recovery__banner {
    margin: 0 0 $space;
  }
  .input {
    & + .input {
      margin: $space-md 0 0;
    }
  }
}