.buying-tickets__tickets-container {
  margin: 0 0 $space-xl;
  position: relative;
}

.buying-tickets-card {
  display: flex;
  margin: 0 0 $space;

  @include break(767) {
    flex-direction: column-reverse;
    align-items: center;
    margin: 0 0 $space-lg;
  }
}

.buying-tickets-card__counterBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 $space-sm 0 0;
}

.buying-tickets-card__counter {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 130px;

  
  @include break(767) {
    margin: ($space - 4) 0 0;
  }

  .btn {
    padding: $space-xxs ($space-xs + 2);
    width: 30px;
    height: 30px;
    border-radius: 4px;
  }

  .input {
    margin: 0 $space-xs;
  }

  .input__element {
    padding: ($space-xxs + 1) 0;
    max-width: 40px;
    font-family: $workSans;
    font-size: $fz-16;
    font-weight: 600;
    text-align: center;
  }
}

.buying-tickets-card__total {
  margin: ($space-xs + 2);
  font-family: $workSans;
  font-size: $fz-16;
  font-weight: 600;
}

.buying-tickets-card__body {
  display: flex;
  width: 100%;
  background: $color-background;
  border: 1px solid $color-blue;
  border-radius: $b-rad-programs-course 0;
  box-shadow: 0 4px 20px rgba($color-grey--medium, .5);
  overflow: hidden;
}

.buying-tickets-card__left-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: ($space-sm - 4) 0;
  max-width: 150px;
  width: 100%;
  font-weight: 600;
  border-right: 1px solid $color-blue;

  @include break(767) {
    max-width: 80px;
  }
}

.buying-tickets-card__price {
  font-size: $fz-22;

  @include break(767) {
    font-size: $fz-16;
  }
}

.buying-tickets-card__type {
  font-size: $fz-20;

  @include break(767) {
    font-size: $fz-16;
  }
}

.buying-tickets-card__right-block {
  padding: $space;

  @include break(767) {
    padding: $space-sm;
    width: 100%;
  }
}

.buying-tickets-card__date {
  margin: 0 0 $space-xs;
  font-size: $fz-14;
  font-weight: 600;
  color: $color-grey--extra;

  @include break(767) {
    font-size: $fz-12;
  }
}

.buying-tickets-card__title {
  margin: 0 0 ($space-xs + 2);
  font-size: $fz-18;
  font-weight: 600;
  line-height: 1.4;

  @include break(767) {
    font-size: $fz-14;
  }
}

.buying-tickets-card__attendees {
  .btn {
    span {
      font-size: $fz-16;

      @include break(767) {
        font-size: $fz-14;
      }
    }
  }
}

.buying-tickets-card__input {
  display: flex;
  align-items: center;

  button {
    padding: $space-sm;

    @include break(767) {
      padding-right: 0;
    }
  }

  .input__element {
    padding: ($space-xxs + 1) ($space-xs + 2);
    font-family: $workSans;
    border: 1.5px solid $color-blue;
    border-radius: 5px;

    &::placeholder {
      font-family: $workSans;
      font-size: 14px;
      color: $color-grey--darkish;
    }
  }

  .input {
    @include break(767) {
      width: 100%;
    }
  }
}

.buying-tickets-card__add {
  margin-top: ($space-xs + 2);
}

.buying-tickets__payment-btn-loader {
  margin: 0 125px 0 0;

  @include break(767) {
    margin: 0 0 $space-xl;
  }
}