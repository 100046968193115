.nothing-found {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 $space;
  max-width: 600px;
  min-height: 300px;

  @include break-reverse(768) {
    margin: $space-xl auto 0;
  }
}

.nothing-found__class-icon {
  position: absolute;
  z-index: -1;
  top: 50%;
  transform: translate(0, -50%);
  margin: 0 0 $space;
  width: auto;
  height: 300px;
  fill: $color-blue--light;

  @include break(767) {
    width: 100%;
  }
}

.nothing-found__title {
  margin: 0 0 $space-sm;
  width: 100%;
  font-size: $fz-22;
  font-weight: 700;
  text-align: center;

  @include break(767) {
    font-size: $fz-20;
  }
}

.nothing-found__subtitle {
  margin: 0 0 $space-xs;
  width: 100%;
  font-size: $fz-18;
  font-weight: 400;
  text-align: center;
  
  .btn {
    display: inline;
    font-size: initial;
  }

  @include break(767) {
    font-size: $fz-16;
  }
}
