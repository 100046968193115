.input--has-icon {
  .input__element {
    padding-right: $space-md;
  }
}

.input__element {
  padding: ($space-sm - 4) 0;
  width: 100%;
  font-size: $fz-16;
  font-weight: 400;
  text-overflow: ellipsis;
  background: transparent;
  color: $color-text-primary;
  border: none;
  border-bottom: 1px solid rgba($neutral--70, .2);
  transition: all .15s ease;

  &::placeholder {
    color: $color-text-secondary;
    opacity: 1;
  }

  &:focus {
    border-bottom: 1px solid $color-primary;
  }
}

.input__element--disabled {
  opacity:.7;
}

.input__element--has-error {
  border-bottom-color: $color-error;
}

.input-label {
  display: none;
}

.input-textarea {
  resize: none;
  text-align: justify;
}

.input-group__wrapper {
  position: relative;
  margin-bottom: $registrationInputMarginBottom;
  margin-right: ($space-sm + 4);
  width: calc(50% - 20px);
}

.input-group__wrapper--checkbox,
.input-group__wrapper--textarea,
.input-group__wrapper--full-width {
  width: 100%;
}

.input-group__title {
  margin: $space-lg 0 ($space-sm + 4);
  width: 100%;
  font-weight: 400;
  font-size: $fz-14;
  color: $color-primary;
}

.input,
.select,
.checkbox__container,
.radio {
  position: relative;
}

.checkbox__container .error-message {
  padding-left: ($space + 4);
}

.input__wrapper {
  position: relative;

  .icon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    fill: $color-text-secondary;
  }
}

.input__label {
  position: relative;
  font-size: 13px;
  color: $color-text-primary;
}

.input__label--focused {
  color: $color-primary!important;
}

.input__show-password {
  position: absolute;
  bottom: 50%;

  .icon {
    width: 17px;
    height: 17px;
    fill: $color-show-input-password;
  }
}

.input__hint {
  margin-top: $space-xxs;
  font-size: $fz-12;
  line-height: 1.3;
  color: $color-input-hint;
}

#password,
#confirmPassword,
#oldPassword {
  padding-right: $space;
}