@mixin break($px) {
  @media (max-width: '#{$px}px') {
    @content;
  }
}

@mixin break-all($wpx, $hpx) {
  @media (max-width: '#{$wpx}px'){
    @content;
  }

  @media (max-height: '#{$hpx}px'){
    @content;
  }
}

@mixin break-height($hpx) {
  @media (max-height: '#{$hpx}px'){
    @content;
  }
}

@mixin break-reverse($px) {
  @media (min-width: '#{$px}px') {
    @content;
  }
}

@mixin break-and-break-reverse($w1px, $w2px) {
  @media (min-width: '#{$w1px}px') and (max-width: '#{$w2px}px') {
    @content;
  }
}

@mixin print() {
  @media only print {
    @content;
  }
}