.global-about {
  /*----- base styles ----- */
  .global-about__block {
    * {
      position: relative;
      z-index: 5;
    }
  }
  
  .global-about__title-block {
    width: fit-content;
    white-space: nowrap;
    background: $additional--rose;
    color: $color-text-light;
  
    .icon {
      position: absolute;
      width: 28px;
      height: 28px;
  
      @include break-reverse(768) {
        width: 37px;
        height: 37px;
      }
  
      @include break-reverse(1050) {
        width: 42px;
        height: 42px;
      }
  
      @include break-reverse(1441) {
        width: 47px;
        height: 47px;
      }
    }
  }
  
  .global-about__screen {
    border: 1px solid $primary--40;
    border-radius: 12px;
    filter: drop-shadow(0 4px 20px rgba(#cdd1d9, .35));
    overflow: hidden;
  }
  
  
  
  /*----- title block ----- */
  .about-title-block {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 ($space - 4);
  
    @include break-reverse(768) {
      padding: $space-lg 0;
    }
  
    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 0;
      width: 98px;
      height: 98px;
      filter: blur(50px);
  
      @include break-reverse(768) {
        width: 226px;
        height: 226px;
        filter: blur(80px);
      }
  
      @include break-reverse(1050) {
        width: 353px;
        height: 353px;
        filter: blur(124.5px);
      }
    }
  
    &:before {
      left: 0;
      transform: translate(-70%, -35%);
      background: rgba($additional--rose, .36);
    }
  
    &:after {
      right: 0;
      transform: translate(50%, 130%);
      background: rgba($primary--40, .58);
  
      @include break-reverse(768) {
        transform: translate(50%, 44%)
      }
    }
  }
  
  .about-title-block__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 700;
    line-height: 1.1;
  }
  
  .about-title-block__content-top,
  .about-title-block__content-bottom {
    position: relative;
    display: inline-block;
    text-align: center;
  
    .icon {
      position: absolute;
      z-index: -1;
      bottom: 0;
    }
  }
  
  .about-title-block__content-top {
    max-width: 260px;
    font-size: $fz-32;
  
    @include break-reverse(375) {
      max-width: 330px;
      font-size: $fz-40;
    }
  
    @include break-reverse(768) {
      max-width: 100%;
      font-size: 6.1vw;
    }
  
    @include break-reverse(1441) {
      font-size: $fz-72;
    }
  
    .icon {
      left: 0;
      transform: translate(10%, 40%);
      width: 32px;
      height: 32px;
  
      @include break(767) {
        top: 0;
        bottom: auto;
      }
  
      @include break-reverse(375) {
        width: 40px;
        height: 40px;
      }
  
      @include break-reverse(768) {
        transform: translate(-2vw, 1vw);
        width: 5.54vw;
        height: 5.54vw;
      }
  
      @include break-reverse(1441) {
        transform: translate(-43%, 30%);
        width: 85px;
        height: 85px;
      }
    }
  }
  
  .about-title-block__content-apostrophe {
    color: $primary--70;
  }
  
  .about-title-block__content-bottom {
    transform: rotate(-2.28deg);
    margin: $space-xs 0 0;
    padding: $space-min $space-xs;
    font-size: $fz-20;
  
    @include break-reverse(375) {
      font-size: $fz-24;
    }
  
    @include break-reverse(768) {
      margin: $space-sm 0 0;
      padding: 0 $space-sm;
      font-size: 5.6vw;
    }
  
    @include break-reverse(1441) {
      font-size: $fz-66;
    }
  
    .icon {
      right: 0;
      transform: translate(50%, -50%);
  
      @include break(767) {
        top: 0;
        bottom: auto;
      }
  
      @include break-reverse(768) {
        transform: translate(50%, 50%);
      }
    }
  }
  
  
  
  /*----- tabs block ----- */
  .about-tabs {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ($space - 4) 0 $space-sm;
  
    @include break-reverse(768) {
      padding: $space-lg 0 ($space-lg / 2);
    }
  
    .tabs__container {
      margin: 0;
    }
  
    .tabs__item {
      padding: 0 0 $space-xs;
      font-size: $fz-20;
      line-height: 1.25;
      color: $neutral--80;
  
      @include break-reverse(768) {
        font-size: $fz-30;
      }
  
      &:hover {
        color: $primary--70;
      }
  
      &:not(:last-of-type) {
        margin: 0 $space-lg 0 0;
      }
    }
  
    .tabs__item--active {
      color: $primary--70;
    }
  }
  
  
  
  /*----- banner block ----- */
  .about-banner {
    padding: $space-sm 0 ($space - 4);
  
    @include break-reverse(768) {
      padding: ($space - 4) 0 $space-lg;
    }
  }
  
  .about-banner__body {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10vw 0 0;
  
    @include break-reverse(768) {
      padding: 6vw 5.2vw 0;
    }
  
    @include break-reverse(1441) {
      padding: ($space-lg * 2) $space-lg 0;
    }
  
    .icon {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
  
      @include break(767) {
        left: -20px;
        width: calc(100% + 40px);
      }
    }
  }
  
  .about-banner__visual {
    max-width: 1100px;
  }
  
  
  
  /*----- offer block ----- */
  .about-offer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .about-offer__title,
  .about-offer__item {
    margin: 0 auto $space-xl;
    max-width: 500px;
  
    @include break-reverse(768) {
      margin: 0 0 ($space-lg * 2);
      max-width: 100%;
    }
  }
  
  .about-offer__title {
    text-align: center;
    font-size: $fz-28;
    font-weight: 700;
    line-height: 1.2;
  
    @include break-reverse(768) {
      font-size: $fz-40;
    }
  
    @include break-reverse(1050) {
      font-size: $fz-56;
    }
  
    span {
      display: block;
    }
  }
  
  .about-offer__item {
    position: relative;
    padding: 0 ($space-xs + 2);
  
    &:nth-of-type(1),
    &:nth-of-type(2) {
      &:before {
        content: "";
        position: absolute;
        width: 98px;
        height: 98px;
        filter: blur(50px);
  
        @include break-reverse(768) {
          width: 226px;
          height: 226px;
          filter: blur(88px);
        }
    
        @include break-reverse(1050) {
          width: 353px;
          height: 353px;
          filter: blur(124.5px);
        }
      }
    }
  
    &:nth-of-type(1) {
      &:before {
        top: 0;
        left: 0;
        transform: translate(-100%, 5%);
        background: rgba($primary--40, .58);
      }
    }
  
    &:nth-of-type(2) {
      &:before {
        bottom: 0;
        right: 0;
        transform: translate(65%, 25%);
        background: rgba($additional--rose, .36);
  
        @include break-reverse(1441) {
          transform: translate(120%, 25%);
        }
      }
  
      .offer-card__content {
        @include break(767) {
          order: 1;
        }
  
        @include break-reverse(768) {
          padding: 0 3% 0 0;
        }
      
        @include break-reverse(1441) {
          padding: 0 $space-lg 0 0;
        }
      }
  
      .offer-card__visual {
        @include break(767) {
          order: 2;
        }
      }
    }
  
    &:last-of-type {
      margin: 0;
    }
  
    .offer-card {
      display: flex;
      flex-direction: column;
  
      @include break-reverse(768) {
        flex-direction: row;
        align-items: center;
        column-gap: 13%;
      }
    }
  
    .offer-card__content {
      margin: 0 0 $space;
  
      @include break-reverse(768) {
        width: 39%;
        margin: 0;
        padding: 0 0 0 3%;
      }
  
      
      @include break-reverse(1441) {
        padding: 0 0 0 $space-lg;
      }
    }
  
    .offer-card__visual {
      filter: drop-shadow(0 4px 20px rgba(#cdd1d9, .35));
  
      @include break-reverse(768) {
        width: 48%;
      }
    }
  
    .offer-card__title-block {
      transform: rotate(-2.99deg);
      margin: 0 0 $space;
      padding: $space-xs;
  
      @include break-reverse(1050) {
        margin: 0 0 $space-lg;
        padding: $space-xs $space-md;
      }
  
      h3 {
        font-size: $fz-24;
        font-weight: 600;
        line-height: 1;
  
        @include break-reverse(1050) {
          font-size: $fz-30;
          line-height: 1.25;
        }
      }
  
      .icon {
        top: 0;
      }
  
      .icon--left {
        left: 0;
        transform: translate(-50%, -50%);
      }
  
      .icon--right {
        right: 0;
        transform: translate(50%, -50%);
      }
    }
  }
  
  
  
  /*----- advantages block ----- */
  .about-advantages {
    padding: 0 0 $space-lg;
  
    @include break-reverse(768) {
      padding: 0 0 ($space-lg * 2);
    }
  }
  
  .about-advantages__slider {
    padding: ($space - 4) ($space-xs + 2) $space-md;

    @include break-reverse(768) {
      padding: $space-lg ($space-xs + 2) $space-md;
    }
  }
  
  .about-advantages__slide {
    display: flex;
    flex-direction: column;
  
    .advantages-card {
      padding: $space $space-sm;
  
      @include break-reverse(768) {
        padding: $space-lg;
      }
    }
  
    .advantages-card__title-block {
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      h3 {
        font-weight: 600;
      }
  
      .icon {
        width: 40px;
        height: 40px;
      }
    }
  }
  
  
  
  /*----- faq block ----- */
  .about-faq {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $space-lg 0;
    background: $primary--20;
  
    @include break-reverse(768) {
      padding: ($space-lg * 2) 0;
    }
  
    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
      width: 100vw;
      background: $primary--20;
    }
  
    * {
      position: relative;
    }
  }
  
  .about-faq__body {
    max-width: 1100px;
    width: 100%;
  }
  
  .about-faq__title {
    margin: 0 0 $space-md;
    text-align: center;
    text-transform: uppercase;
  
    @include break-reverse(768) {
      margin: 0 0 $space-lg;
    }
  
    span {
      text-transform: none;
    }
  }
  
  .about-faq__item {
    border-bottom: 1px solid $neutral--50;
  
    &:first-of-type {
      border-top: 1px solid $neutral--50;
    }
  
    .faq-card {
      background: transparent !important;
      box-shadow: none !important;
  
      @include break-reverse(768) {
        padding: 0 $space;
      }
    }
  
    .faq-card__title-block {
      padding: $space-sm 0;
      min-height: auto !important;
  
      @include break-reverse(768) {
        padding: $space 0;
      }
  
      div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0;
      }
  
      h3 {
        font-size: $fz-20;
        font-weight: 600;
        line-height: 1.3;
        color: $neutral--80; 
      }
  
      .icon {
        width: 32px;
        height: 32px;
  
        @include break-reverse(768) {
          width: 40px;
          height: 40px;
        }
      }
    }
  
    .faq-card__content {
      padding: 0 0 $space;
      max-width: 615px;
    }
  }
  
  
  
  /*----- sponsors block ----- */
  .about-sponsors {
    position: relative;
    padding: $space-lg 0 ($space - 4);
  
    @include break-reverse(768) {
      padding: ($space-lg * 2) 0 $space-lg;
    }
  
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translate(-70%, 12%);
      width: 98px;
      height: 98px;
      background: rgba($primary--40, .58);
      filter: blur(50px);
  
      @include break-reverse(768) {
        width: 226px;
        height: 226px;
        filter: blur(88px);
      }
  
      @include break-reverse(1050) {
        width: 353px;
        height: 353px;
        filter: blur(124.5px);
      }
    }
  
    .sponsors-block__title-block {
      padding: 0 ($space-xs + 2);
    }
    
    .sponsors-block__slider {
      padding: $space-md ($space-xs + 2);
  
      @include break-reverse(768) {
        padding: $space-md ($space-xs + 2);
      }
    }
  }
  
  
  
  /*----- form block ----- */
  .contact-form-block {
    padding: ($space - 4) ($space-xs + 2) $space-lg;
    
    @include break-reverse(768) {
      padding: $space-lg ($space-xs + 2) ($space-lg * 2);
    }
  }
}