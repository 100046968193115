.class-details {
  .sessions__item {
    flex: 0 0 100%;
  }
}

.class-details__title-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 0 $space-xs;

  .class-details__class-type {
    margin-left: 0;
  }
}

.class-details__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: $space-sm 0 0;
}

.class-details__actions {
  display: flex;
  align-items: center;
  margin: 0 0 $space-sm;
  flex-wrap: wrap;

  .btn {
    margin: 0 $space-xs $space-xs 0;
    padding: $space-xs $space;
    font-size: $fz-12;

    &:last-of-type {
      margin: 0 0 $space-xs;
    }
  }
}

.class-details__presented {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 0 0 auto;

  span {
    margin: 0 $space-xs 0 0;
    font-size: $fz-18;
    font-weight: 600;
  }

  img {
    width: 100px;
    height: auto;
  }
}