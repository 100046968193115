.radio__input {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);

  & + label {
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
    }

    &:before {
      left: 0;
      transform: translate(0, -50%);
      width: 20px;
      height: 20px;
      background: $color-background;
      border-radius: 15px;
      border: 2px solid $color-grey--darkish;
    }

    &:after {
      left: 5px;
      transform: translateY(-50%) scale(0);
      height: 10px;
      width: 10px;
      background: $color-primary;
      border-radius: 10px;
      transition: transform .1s ease-in;
    }
  }

  &:checked + label {
    &:after {
      transform: translateY(-50%) scale(1);
    }
  }
}

.radio__input--has-error {
  & + label {
    :before {
      border: 2px solid $color-error;
    }
  }
}


.radio__container {
  display: flex;
  align-items: end;
}

.radio__label {
  position: relative;
  display: inline-block;
  padding-left: ($space + 4);
  font-size: $fz-14;
  font-weight: 400;
  line-height: 1.35;
  cursor: pointer;
}

.radio--disabled {
  .radio__label {
    opacity: .4;
    cursor: not-allowed;
  }

  .radio__input {
    opacity: 0;
    cursor: not-allowed;
  }
}