.offering-faculty__body,
.offering-faculty__item,
.offering-faculty-card,
.offering-faculty-card__socials,
.offering-faculty-card__socials-list,
.offering-faculty-card__content,
.offering-faculty-card__bottom {
  display: flex;
  flex-direction: column;
}

.offering-faculty__body {
  @include break-reverse(768) {
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 (-$space + 4);
  }
}

.offering-faculty__item {
  width: 100%;
  margin: 0 0 $space-lg;

  @include break-reverse(768) {
    width: 50%;
    padding: 0 ($space - 3);
  }

  .offering-faculty-card {
    height: 100%;
  
    @include break-and-break-reverse(576, 767) {
      flex-direction: row;
      column-gap: $space;
    }
  
    @include break-reverse(1050) {
      flex-direction: row;
      column-gap: $space;
    }
  }
  
  .offering-faculty-card__top {
    display: flex;
    column-gap: $space-sm;
    margin: 0 0 $space;
    height: fit-content;
  
    @include break-and-break-reverse(576, 767) {
      margin: 0;
    }
  
    @include break-reverse(1050) {
      margin: 0;
    }
  }
  
  .offering-faculty-card__bottom {
    @include break(575) {
      height: 100%;
    }
  
    @include break-and-break-reverse(768, 1049) {
      height: 100%;
    }
  }
  
  .offering-faculty-card__socials {
    justify-content: flex-end;
  }
  
  .offering-faculty-card__socials-list {
    row-gap: $space-xs;
  }
  
  .offering-faculty-card__visual {
    position: relative;
    min-width: 202px;
    max-width: 202px;
    height: 234px;
    background: $color-violet--soft;
    border-radius: 50px 0 0 0;
    overflow: hidden;
  
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  
    svg {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 17%);
      width: 150px;
      height: 178px;
    }
  }
  
  .offering-faculty-card__content {
    justify-content: space-between;
    height: 100%;
  }
  
  .offering-faculty-card__name {
    margin: 0 0 $space-min;
    font-size: $fz-20;
    font-weight: 600;
    line-height: 1.3;
    color: $color-primary;
  }
  
  .offering-faculty-card__instruction {
    margin: 0 0 $space-sm;
  }
  
  .offering-faculty-card__bio {
    display: -webkit-box;
    margin: 0 0 24px;
    max-width: 100%;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    text-overflow: ellipsis;
    overflow: hidden;
  
    br {
      content: "";
      display: block;
      margin: 0 0 $space-sm;
    }

    iframe {
      display: none;
    }
  }
  
  .offering-faculty-card__button {
    padding: ($space-xs + 2) $space-sm;
    width: fit-content;

    @include break-reverse(768) {
      padding: ($space-sm - 4) $space-lg;
    }
    
    span {
      font-size: $fz-14;
    }
  }
}