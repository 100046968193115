.checkbox {
  position: absolute;
  top: 4px;
  left: 3px;
  display: none;
  appearance: none;

  &:disabled {
    display: none;
  }

  & + label {
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: -2px;
    }

    &:before {
      left: 0;
      width: 19px;
      height: 19px;
      border: 2px solid $color-primary;
      border-radius: 5px;
    }

    &:after {
      left: -1px;
      transform: rotate(0) translate(0%, 120%) scale(0);
      width: 12px;
      height: 6px;
      border-left: 2px solid $color-primary;
      border-bottom: 2px solid $color-primary;
      transition: all 0.15s ease;
    }
  }

  &:checked + label {
    &:after {
      transform: rotate(-45deg) translate(0%, 120%) scale(1);
    }
  }

  &--has-checked-child + label {
    &:after {
      left: 5px;
      top: 3px;
      transform: none;
      height: 9px;
      width: 9px;
      background: $color-primary;
      border-radius: 2px;
    }
  }

  &:disabled + label
  &:disabled + label {
    &:before,
    &:after {
      opacity: .4;
      cursor: not-allowed;
    }
  }
}

.checkbox--has-error {
  & + label {
    &:before {
      border: 2px solid $color-error;
    }
  }
}

.checkbox_label {
  position: relative;
  display: inline-block;
  padding-left: $space-md;
  font-size: $fz-14;
  font-weight: 400;
  line-height: 1.35;
  cursor: pointer;

  .btn {
    span {
      font-size: $fz-12;
    }
  }
}
