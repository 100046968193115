.ftable {
  position: relative;
}

.ftable__row {
  display: flex;
}

.ftable__row--title {
  .table__cell {
    min-width: 150px;
    font-weight: 700;
    color: $color-text-light;
  }

  .table__cell-content {
    font-weight: 700;
  }
}

.ftable__cell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1 0;
  padding: 10px;
  min-width: 150px;
  line-height: 1.2;
  border-bottom: 1px solid rgba($color-black--dark, .1);
  border-right: 1px solid rgba($color-black--dark, .1);
  transition: all .3s;

  &:last-child {
    border-right: none;
  }
}

.ftable__cell-content {
  width: 100%;
  font-weight: 400;
  word-break: break-word;
}

//.ftable__wrapper {
//  position: relative;
//
//  &:after {
//    content: '';
//    position: absolute;
//    right: 0;
//    top: 0;
//    height: 100%;
//    width: 60px;
//    background: linear-gradient(to right, rgba($color-background, 0) 0%,rgba($color-background, 1) 100%);
//    transition: .3s all;
//    opacity: 0;
//    visibility: hidden;
//    pointer-events: none;
//  }
//
//  &--scroll-indicate {
//    &:after {
//      opacity: 1;
//      transition: .3s opacity, 0s visibility;
//      visibility: visible;
//    }
//  }
//}
