.alphabet-filter-tabs {
  margin: 0;

  .tabs__item {
    margin: 0 $space-xs;
    padding: $space-xxs 0;
    font-size: $fz-16;
    font-weight: 600;
    color: $color-primary;
    text-transform: uppercase;

    &:hover {
      color: $color-secondary;

      &:before {
        display: none;
      }
    }
  }
}

.alphabet-filter-tabs__label {
  padding: 0 0 0 ($space + 2);
  font-size: $fz-12;
  font-weight: 600;
  text-transform: uppercase;
  opacity: .4;
}
