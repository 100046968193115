.footer__body,
.footer__top-body,
.footer__bottom-body,
.footer__body-block--menu-item {
  display: flex;
  flex-direction: column;
}

.footer__top {
  padding: $space-md 0;
  background: $primary--20;
  color: $neutral--70;

  @include break-reverse(1050) {
    padding: $space-lg 0;
  }
}

.footer__bottom {
  padding: $space 0;
  font-size: $fz-14;
  font-weight: 600;
  line-height: 1.15;
  background: $primary--30;
  color: $neutral--50;

  @include break-reverse(1050) {
    padding: $space-sm 0;
  }
}

.footer__top-body {
  @include break-reverse(1050) {
    flex-direction: row;
  }

  .footer__body-block--logo {
    margin: 0 0 $space-md;
    width: 100%;

    @include break-reverse(1050) {
      margin: 0;
      width: 30%;
    }

    .footer__logo {
      img {
        filter: grayscale(100%);
      }
    }
  }
}

.footer__bottom-body {
  align-items: center;

  @include break-reverse(1050) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.footer__body-block--menu {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  @include break-reverse(1050) {
    width: 70%;
  }
}

.footer__body-block--menu-item {
  align-items: flex-start;
  width: 50%;

  @include break-reverse(768) {
    width: auto;
  }

  &:nth-of-type(1),
  &:nth-of-type(2) {
    @include break(767) {
      margin: 0 0 $space-md
    }
  }

  &:not(:last-of-type) {
    @include break-reverse(768) {
      margin: 0 15vw 0 0;
    }

    @include break-reverse(1050) {
      margin: 0 10vw 0 0;
    }
  }

  .btn {
    &:hover {
      span {
        color: $neutral--70;
      }
    }

    &:not(:last-of-type) {
      margin: 0 0 $space-sm;
    }

    span {
      color: $neutral--70;
    }
  }

  .footer__donation-tooltip {
    margin: 0 0 $space-sm;

    .tooltip__wrapper {
      padding: ($space-sm - 4) $space-sm;
    }

    .tooltip__content {
      right: 11px;
      font-size: $fz-12;
    }

    .tooltip__body {
      white-space: nowrap;
    }
  }
}

.footer__body-block--payment {
  display: flex;
  align-items: center;
  margin: 0 (-$space-xs);

  @include break(1049) {
    order: 1;
    margin: 0 (-$space-xs) $space-sm;
  }
}

.footer__socials {
  display: flex;
  margin: $space-sm (-$space-xs) 0;

  .footer__link {
    padding: 0 $space-xs;
    filter: grayscale(100%);
    opacity: .7;
  }
}

.footer__link--logo {
  span {
    display: flex;
    align-items: center;
  }
}

.footer__payment {
  padding: 0 $space-xs;

  img {
    width: auto;
    height: 16px;
  }
}