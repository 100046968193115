.link {
  position: relative;
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    span {
      color: $color-secondary;
    }

    .icon {
      fill: $color-secondary;
    }
  }

  .link__icon--left {
    margin: 0 $space-xs 0 0;
  }
  
  .link__icon--right {
    margin: 0 0 0 $space-xs;
  }
  
  .icon {
    fill: $color-primary;
    transition: all .3s ease;
  }

  span {
    font-size: $fz-14;
    font-weight: 600;
    word-break: break-all;
    transition: all .3s ease;
  }
}

.link--button-primary,
.link--button-secondary,
.link--button-transparent {
  padding: ($space-sm - 4) $space-md;
  border: 1px solid transparent;
  border-radius: 24px;

  span {
    font-size: $fz-18;
  }
}

.link--button-primary,
.link--button-secondary {
  background: $color-primary;

  &:hover {
    background: $color-secondary;

    span {
      color: $color-text-light;
    }

    .icon {
      fill: $color-text-light;
    }
  }

  span {
    color: $color-text-light;
  }

  .icon {
    fill: $color-text-light;
  }
}

.link--button-primary {
  background: $color-primary;

  &:hover {
    background: $color-secondary;
  }
}

.link--button-secondary {
  background: $color-secondary;
  border-color: $color-secondary;

  &:hover {
    background: $color-background;
    
    span {
      color: $color-secondary;
    }
  }
}

.link--button-transparent {
  border-color: $color-primary;
  transition: border .3s ease;

  &:hover {
    border-color: $color-secondary;
  }
}