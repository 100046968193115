.modal {
  position: fixed;
  z-index: 300;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: $space-sm;
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;

  @include break(630) {
    padding: 0;

    .modal__body {
      padding: $space-xl $space-sm;
    }
  }
}

.modal--narrow {
  .modal__window {
    max-width: 586px;
  }
}

.modal--policy {
  .modal__window {
    max-width: 800px;
  }
}

.modal__window {
  position: relative;
  z-index: 1;
  margin: auto;
  max-width: 900px;
  width: 100%;
  min-height: 300px;
  background: $color-background;
  border-radius: $b-rad;
  transition: $transition-time * 2 max-width $cubic-default;
  overflow-y: auto;
}

.modal__body {
  position: relative;
  padding: ($space-xxl + 4) $space-xl;
  min-height: 100%;
}

.modal__overlay {
  position: fixed;
  z-index: -1;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba($color-black--dark, .7);
}

.modal__close-btn {
  position: absolute;
  z-index: 2;
  right: $space-sm;
  top: $space-sm;
  margin: 0 0 0 auto;
  padding: ($space-xxs + 1);
  min-width: 30px;
  min-height: 30px;
  font-size: $fz-14;
}

.modal__go-back {
  position: absolute;
  z-index: 2;
  left: $space-sm;
  top: $space-sm;
  min-width: 30px;
  min-height: 30px;

  .btn__icon--left {
    margin: 0 $space-xxs 0 0;
  }

  svg {
    transform: rotate(90deg);
    fill: $color-primary;
  }
}

.modal-block {
  position: fixed;
  width: 100%;
  overflow-y: hidden;
}