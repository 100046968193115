.tooltip {
  position: relative;
  margin: 0 0 0 $space-xxs;
  line-height: 1.5;
  cursor: pointer;

  &:hover {
    .tooltip__content {
      opacity: 1;
      visibility: visible;
    }
  }
}

.tooltip--bottom {
  .tooltip__content {
    right: 0;
  }

  .tooltip__wrapper {
    left: 47%;
    top: 12px;
    box-shadow: 0 4px 5px rgba($neutral--100, .15);
  }

  .tooltip__content {
    &:after {
      top: 12px;
      left: auto;
      right: -8px;
    }
  }
}

.tooltip--right {
  .tooltip__content {
    top: -100%;
  }

  .tooltip__wrapper {
    left: 26px;
    top: 0;
  }

  .tooltip__content {
    &:after {
      top: 24px;
      left: 26px;
    }
  }
}

.tooltip--top {
  .tooltip__content {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .tooltip__wrapper {
    top: -24px;
  }

  .tooltip__content {
    &:after {
      top: auto;
      left: 50%;
      bottom: 8px;
    }
  }
}

.tooltip--left {
  .tooltip__content {
    top: -100%;
    right: 0;
  }

  .tooltip__wrapper {
    left: -26px;
    top: 0;
  }

  .tooltip__content {
    &:after {
      top: 24px;
      right: 12px;
      left: auto;
    }
  }
}

.tooltip__wrapper {
  position: relative;
  left: 0;
  padding: $space-xs $space-xs;
  background: $primary--30;
  border-radius: 5px;
}

.tooltip__content {
  position: absolute;
  z-index: 2;
  max-width: 90vw;
  opacity: 0;
  transition: opacity .4s linear;
  visibility: hidden;

  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 15px;
    height: 15px;
    background: $primary--30;

    @include break(600) {
      top: 0;
      left: 50%;
    }
  }

  &:hover {
    opacity: 1;
    visibility: visible;
  }
}

.tooltip__title {
  font-weight: 600;
}

.tooltip__body {
  li {
    display: inline;
  }
}

.tooltip--danger {
  circle {
    fill: $color-error;
  }
}