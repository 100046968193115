.breadcrumbs-block {
  display: flex;
  align-items: center;

  span {
    font-size: $fz-14;
    font-weight: 400;
    line-height: 1;
  }

  .icon {
    transform: rotate(-90deg);
    margin: 0 $space-xs;
    fill: $neutral--50;
  }

  .link {
    &:hover {
      span {
        color: $primary--70;
      }
    }

    span {
      font-weight: 400;
      color: $neutral--80;
    }
  }

  > span {
    color: $neutral--50;
  }
}