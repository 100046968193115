.cookie-block {
  position: fixed;
  z-index: 200;
  bottom: 0;
  left: 0;
  width: 100%;
  font-size: $fz-14;
  font-weight: 400;
  line-height: 1.25;
  background: $primary--20;
  color: $neutral--80;
  border-radius: 40px 0 0 0;

  @include break-reverse(768) {
    font-size: $fz-16;
    border-radius: 150px 0 0 0;
  }

  @include break-reverse(1441) {
    border-radius: 0;
  }
}

.cookie-block__body {
  position: relative;
  display: flex;
  align-items: center;
  padding: $space-sm 0;
  
  @include break-reverse(768) {
    padding: $space-md 0;
  }
}

.cookie-block__close-button {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(0, 18px);

  @include break-reverse(768) {
    transform: translate(0, 34px);
  }
}

.cookie-block__visual {
  display: none;

  @include break-reverse(768) {
    display: flex;
    justify-content: center;
    flex: 0 0 25%;
  }

  @include break-reverse(1050) {
    flex: 0 0 20%;
  }

  .icon {
    width: 140px;
    height: 150px;
  }
}

.cookie-block__content {
  @include break-reverse(768) {
    flex: 0 0 75%;
  }

  @include break-reverse(1050) {
    flex: 0 0 80%;
  }
}

.cookie-block__title {
  margin: 0 0 $space-xs;
  font-size: $fz-16;
  font-weight: 600;

  @include break-reverse(768) {
    margin: 0 0 $space-sm;
    font-size: $fz-20;
  }
}

.cookie-block__description {
  margin: 0 0 $space;
  padding: 0 $space-lg 0 0;

  @include break-reverse(768) {
    padding: 0 $space-xl 0 0;
  }

  .link {
    display: inline-block;
    
    span {
      font-size: inherit;
      font-weight: 400;
      color: $neutral--80;
      border-bottom: .5px solid $neutral--80;
    }

    &:hover {
      span {
        color: $primary--70;
        border-bottom: .5px solid $primary--70;
      }
    }
  }
}

.cookie-block__buttons {
  display: flex;
  align-items: center;
  margin: 0 (-$space-xs);

  button {
    margin: 0 $space-xs;
    padding: ($space-sm - 4) $space-xxs;
    width: 50%;
    font-size: $fz-10;
    line-height: 1;

    @include break-reverse(376) {
      padding: ($space-sm - 4) $space-md;
      width: auto;
    }

    @include break-reverse(576) {
      font-size: $fz-12;
    }

    @include break-reverse(768) {
      padding: ($space-sm - 4) $space-md;
      font-size: $fz-14;
    }
  }
}