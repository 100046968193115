.offering-details {
  margin: (-$space-xs) 0 0;
  font-size: $fz-16;
  line-height: 1.25;
  color: $neutral--80;

  .offering-title-block__middle {
    margin: 0 0 $space;

    @include break-reverse(768) {
      margin: 0;
    }

    .offering-title-block__logo {
      margin: 0 0 0 auto;
    }
  }

  .offering-title-block__title {
    margin: 0;
    font-size: $fz-24;
    line-height: 1.5;

    @include break-reverse(768) {
      font-size: $fz-40;
    }
  }

  .offering-title-block__actions {
    @include break(1049) {
      margin: $space 0 0;
    }
  }
}

.offering-details__title-block {
  margin: 0 0 $space;

  & + .tabs__container {
    margin: 0;

    @include break-reverse(768) {
      padding: $space 0 0;
    }
  }
}

.offering-title-block__id {
  position: relative;
  margin: 0 $space-xs 0 0;
  font-weight: 400;
  font-size: $fz-14;
  letter-spacing: .03em;

  @include break-reverse(401) {
    margin: 0 $space-sm 0 0;
  }
}

.offering-title-block__top,
.offering-title-block__middle {
  display: flex;
  align-items: center;
}

.offering-title-block__top {
  justify-content: space-between;
  margin: 0 0 $space-sm;
}

.offering-title-block__middle {
  flex-wrap: wrap;
  margin: 0 0 $space-xs;

  .offering-details__class-type {
    margin-left: 0;
  }
}

.offering-title-block__bottom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include break-reverse(1050) {
    flex-direction: row;
    align-items: flex-start;
    margin: 0 (-$space-sm + 4);
  }
}

.offering-title-block__title {
  margin: 0 0 $space-xxs;
  font-size: $fz-24;
  font-weight: 600;

  @include break-reverse(1050) {
    padding: 0 ($space-sm - 4);
  }
}

.offering-title-block__subtitle {
  font-weight: 600;
  
  span {
    font-size: $fz-16;
    color: $color-primary;
  }
}

.offering-title-block__actions {
  display: flex;
  align-items: center;

  @include break-reverse(1050) {
    margin: $space-xs 0 0;
    padding: 0 ($space-sm - 4);
  }

  .btn {
    &:not(:first-of-type) {
      margin: 0 0 0 $space-sm;

      @include break-reverse(768) {
        margin: 0 0 0 $space-md;
      }
    }
  }
}

.offering-details__watch-button,
.offering-details__register-button {
  padding: $space-xs $space-lg;
  font-size: $fz-12;
  white-space: nowrap;

  @include break-reverse(768) {
    padding: ($space-sm - 4) $space-lg;
    font-size: $fz-18;
  }
}

.offering-details__badges {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: ($space-xs - 2) $space-xs 0 0;

  .badge {
    margin: 0 ($space-xs - 2) $space-xxs 0;
    white-space: nowrap;
    border: 1px solid transparent;

    &:last-child {
      margin: 0 0 ($space-xs - 2);
    }
  }

  .offering-details__badge--content-exhibit,
  .offering-details__badge--content-private-workshop,
  .offering-details__badge--content-privat-meeting,
  .offering-details__badge--content-workshop {
    background: $primary--40;
  } 

  .offering-details__badge--online,
  .offering-details__badge--online-onsite,
  .offering-details__badge--onsite-online,
  .offering-details__badge--onsite {
    background: transparent;
    border: 1px solid $neutral--80;
  }

  .offering-details__badge--content-event,
  .offering-details__badge--content-private-event,
  .offering-details__badge--content-pre-production-session {
    background: $additional--pink;
  }

  .offering-details__badge--content-private-rehearsal,
  .offering-details__badge--content-public-rehearsal,
  .offering-details__badge--content-practica {
    background: $additional--rose;
  }
    
  .offering-details__badge--content-open-house,
  .offering-details__badge--content-perfomance\/show,
  .offering-details__badge--content-private-perfomance\/show {
    background: $primary--20;
  }
  
  .offering-details__badge--content-lecture,
  .offering-details__badge--content-public-audition,
  .offering-details__badge--content-private-audition {
    background: $additional--olive;
  }

  .offering-details__badge--content-private-class\/lesson,
  .offering-details__badge--content-private-lecture {
    background: $additional--yellow;
  }

  .offering-details__badge--content-recording\/broadcast-session,
  .offering-details__badge--content-photo\/video-shoot {
    background: $additional--blue;
  }

  .offering-details__badge--content-private-class {
    background: $additional--cyan;
  }

  .offering-details__badge--hasVideos {
    background: transparent;
    border: 1px solid $color-secondary;
    color: $color-secondary;
  }

  .offering-details__badge--isFree,
  .offering-details__badge--isTrial {
    background: $color-secondary;
    color: $color-text-light;
  }

  .offering-details__badge--isPastFree,
  .offering-details__badge--isPastTrial {
    background: $primary--30;
    color: $color-text-secondary;
    border: 1px solid $primary--30;
  }
}

.offering-details__tab-content {
  margin: (-$space-xxxl * 2) 0 0;
  padding: $space-lg 0 0;
  background: #f9f9f9;

  @include break-reverse(411) {
    margin: (-$space-xxl * 2) 0 0;
  }

  @include break-reverse(768) {
    margin: 0;
    padding: $space-xxxl 0 0;
  }

  @include break-reverse(1050) {
    padding: ($space-lg * 2) 0 0;
  }

  .tabs__container.tabs__container {
    margin: 0 0 $space;
    border: none;
  
    .tabs__item {
      &:not(:last-child) {
        @include break(450) {
          margin: 0 $space-xl 0 0;
        }
      }
    }
  }
}

.offering-details__tabs {
  display: flex;

  @include break(767) {
    padding: 0 0 ($space-xxl * 2);
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  @include break(410) {
    padding: 0 0 ($space-xxxl * 2);
  }

  &::-webkit-scrollbar {
    @include break(767) {
      width: 0;
      height: 0;
    }
  }

  .tabs__item {
    display: inline-flex;
    align-items: center;
    padding: $space 0 ($space-xs + 2);
    font-size: $fz-16;
    font-weight: 600;
    white-space: nowrap;
    transition: $transition-time;

    @include break-reverse(768) {
      padding: 0 0 ($space-xs + 2);
    }

    &:not(:last-child) {
      margin: 0 $space-lg 0 0;

      @include break-reverse(768) {
        margin: 0 $space 0 0;
      }

      @include break-reverse(1050) {
        margin: 0 $space-xl 0 0;
      }

      @include break-reverse(1441) {
        margin: 0 ($space-lg * 2) 0 0;
      }
    }

    &:hover {
      color: $color-primary;
  
      svg {
        fill: $color-primary;
      }
    }

    svg {
      fill: $color-text-primary;
    }
  }

  .tabs__item--active {
    color: $color-primary;
  
    svg {
      fill: $color-primary;
    }
  }
}

.offering-details__tab-icon {
  margin: 0 $space-xs 0 0;
}

.offering-details__tab-tooltip {
  margin: (-$space-xs - 2) 0 0 $space-xxs;
  
  .tooltip__content {
    z-index: 5;
    width: calc(100vw - 40px);

    @include break-reverse(416) {
      width: 375px;
    }
  }

  .tooltip__wrapper {
    padding: ($space-sm - 4) $space-sm;
    font-weight: 400;
    white-space: normal;
    color: $color-text-primary;
  }
}