.login {
  position: relative;

  .registration-step {
    background: $color-background;

    @include break(767) {
      margin: 0 auto;
      max-width: 100%;
    }

    .registration-step__container {
      margin: 0 auto;
      padding: 0;
      max-width: 345px;
      width: 100%;
    }

    .login__icon {
      margin: 0 auto;
      width: 200px;
      height: auto;

      @include break-all(767, 375) {
        width: 100px;
      }
    }

    .login__title {
      margin: 0 0 ($space-sm - 4);
      text-align: center;

      @include break-all(767, 375) {
        margin: 0 0 $space;
        font-size: $fz-26;
      }
    }

    .login__switcher {
      margin: 0 0 $space-lg;
      text-align: center;
    }

    .login__switcher__block {
      span {
        margin: 0 $space-xxs 0 0;
        font-size: $fz-14;
      }

      div {
        margin: 0 0 $space-xxs;
        font-size: $fz-14;
        font-weight: 600;
      }

      .btn {
        display: inline-block;
        
        span {
          font-weight: 400;
        }
      }
    }

    .login__switcher--sign-in {
      .login__switcher__block {
        &:last-of-type {
          margin: $space 0 0;
        }
      }
    }

    .login__switcher--sign-up {
      .login__switcher__block {
        &:last-of-type {
          margin: $space-sm 0 0;
        }
      }

      .login__switcher__block {
        div {
          display: inline-block;
          margin: 0 $space-xxs 0 0;
        }
      }
    }

    .login__forgot-password {
      margin: 0 auto;
      font-weight: 400;
    }

    .form__group--login-buttons {
      display: flex;
      justify-content: space-between;
      margin: $space-lg 0 0;

      @include break(767) {
        margin: $space 0 0;
      }
    }

    .btn__wrapper {
      position: relative;
      margin: 0;
      width: 100%;
      background: $color-modal-background;

      .loader__wrapper {
        position: absolute;
        bottom: $space-xs;
        left: $space-xs;
      }
    }
  }

  .login__help {
    display: flex;
    justify-content: center;
  }

  .login__help-text {
    display: block;
    margin: 0 0 $space-md;
    font-size: $fz-14;
    font-weight: 600;
    line-height: 1.15;
    text-align: center;
  }

  .login__tooltip {
    margin: 1px 0 0 $space-xs;

    .tooltip__wrapper {
      padding: ($space-xs + 2) ($space-xs + 4);

      @include break(767) {
        left: 29%;
      }
    }

    .tooltip__body {
      font-size: $fz-12;
      line-height: 1.5;
      text-align: center;

      a {
        font-size: $fz-12;
      }

      span {
        display: block;
        margin: 0 auto;
        max-width: 170px;
      }
    }
  }
}

.required-mark {
  &:after {
    content: '*';
    position: absolute;
    top: 0;
    right: -10px;
    color: $color-error;
  }
}