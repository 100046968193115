/*----- base styles ----- */
.global-page {
  font-size: $fz-16;
  line-height: 1.25;
  color: $color-text-primary;

  h1,
  h2 {
    color: $neutral--80;
  }
}

.global-page__body {
  margin: 0 (-$space-xs - 2);
}

.global-page__block {
  padding: ($space - 4) 0;

  @include break-reverse(768) {
    padding: $space-lg 0;
  }
}

.global-page__title {
  font-size: $fz-28;
  font-weight: 700;
  line-height: 1.2;

  @include break-reverse(768) {
    font-size: $fz-40;
  }
}

.global-page__buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 (-$space-xs - 2);
  padding: 0 ($space-xs + 2);

  .icon {
    position: relative;
    width: 40px;
    height: 16px;
    fill: $primary--40;
  }
}

.global-page__prev-button,
.global-page__next-button {
  margin: 0 ($space-xs + 2);
  transition: all .3s ease;
  cursor: pointer;
  
  &:hover {
    .icon {
      fill: $color-primary;
    }
  }
}

.global-page-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: $color-background;
  box-shadow: 0 4px 10px rgba(#cdd1d9, .7);
  border-radius: 50px 0px;
}

.global-page-card__title-block {
  margin: 0 0 $space;
}