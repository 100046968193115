/*----- constants ----- */
$size-point: 8px;



/*----- new colors ----- */
$primary--100: #2c02a0;
$primary--70: #6d47d7;
$primary--40: #bbb3db;
$primary--30: #ede9ff;
$primary--20: #f4f3ff;

$secondary: #ff0080;

$neutral--100: #0f1922;
$neutral--90: #272d32;
$neutral--80: #223445;
$neutral--70: #556675;
$neutral--50: #7c8c9a;
$neutral--40: #b0c0cd;
$neutral--30: #cbd6e3;
$neutral--20: #e4f1ff;
$neutral--10: #f0f6fc;
$neutral--0: #fff;

$additional--blue: #c0e8e5;
$additional--blue--dark: #00aeef;
$additional--cyan: #2ec4b6;
$additional--olive: #c9dbbb;
$additional--blush: #ffdfef;
$additional--pink: #fcd6c6;
$additional--rose: #ffbbdd;
$additional--yellow: #b9c8e1;



/*----- base colors ----- */
$color-white--dark: #f3efff;
$color-black: #333;
$color-black--dark: #000;
$color-purple--soft: #008cff;
$color-purple--light: #3f51b5;
$color-purple--medium: #2f00b0;
$color-violet--soft: #edecf6;
$color-violet--light: #d8ccfb;
$color-violet: #c8c0dd;
$color-violet--dark: #a098df;
$color-red: #ff5454;
$color-blue: #dfdeeb;
$color-blue--soft: #f8fbfd;
$color-blue--lightish: #f6f6fc;
$color-blue--light: #e5eef8;
$color-blue--medium: #d8e3ec;
$color-blue--darkish: #b1acc1;
$color-blue--dark: #8f8ca8;
$color-blue--extra: #6c6985;
$color-grey: #5e6d7b;
$color-grey--soft: #eae9f4;
$color-grey--lightish: #ebebeb;
$color-grey--light: #e0e0e0;
$color-grey--medium: #cccaea;
$color-grey--darkish: #c4c4c4;
$color-grey--dark: #8e9093;
$color-grey--extra: #727272;



/*----- derivative colors ----- */
$color-primary: $primary--100;
$color-primary--light: $color-violet;
$color-secondary: $secondary;
$color-light: $color-grey--soft;

$color-scrollbar: $color-blue--soft;

$color-background: $neutral--0;

$color-text-primary: $neutral--100;
$color-text-secondary: $neutral--70;
$color-text-secondary--light: $color-grey--darkish;
$color-text-light: $neutral--0;

$color-error: $color-red;

$color-table-title: $color-blue--soft;
$color-table-subtitle: $color-blue--soft;
$color-table-root: $color-blue--light;
$color-table-border: $neutral--30;
$color-table-subtitle-border: $color-blue--light;

$color-modal-background: $color-blue--soft;

$color-switcher-disabled: $color-grey--darkish;
$color-show-input-password: $color-grey;
$color-input-hint: $color-grey;

$color-input-range-background: $color-grey--lightish;



/*----- font-sizes ----- */
$fz-72: 72px;
$fz-66: 66px;
$fz-56: 56px;
$fz-48: 48px;
$fz-40: 40px;
$fz-36: 36px;
$fz-32: 32px;
$fz-30: 30px;
$fz-28: 28px;
$fz-26: 26px;
$fz-24: 24px;
$fz-22: 22px;
$fz-21: 21px;
$fz-20: 20px;
$fz-18: 18px;
$fz-16: 16px;
$fz-15: 15px;
$fz-14: 14px;
$fz-12: 12px;
$fz-11: 11px;
$fz-10: 10px;



/*----- font-families ----- */
$workSans: 'Work Sans', sans-serif;



/*----- spaces----- */
$space-min: $size-point / 4;
$space-xxs: $size-point / 2;
$space-xs: $size-point;
$space-sm: $size-point * 2;
$space: $size-point * 3;
$space-md: $size-point * 4;
$space-lg: $size-point * 5;
$space-xl: $size-point * 6;
$space-xxl: $size-point * 7;
$space-xxxl: $size-point * 8;



/*----- appearences----- */
$b-rad: 5px;
$b-rad-programs-course: $b-rad * 8;
$b-rad-table-curved-row: $b-rad * 4;
$b-rad-programs-course-row-img: 12px;

$transition-time: 0.2s;
$cubic-default: cubic-bezier(0.34, -0.32, 0, 1.13);



/*----- elements ----- */
$select-height: $size-point * 5 + 1;



/*----- icon sizes ----- */
$icon-w: 16px;
$icon-h: $icon-w;
$icon-w-xs: 8px;
$icon-h-xs: $icon-w-xs;
$icon-w-sm: 12px;
$icon-h-sm: $icon-w-sm;
$icon-w-md: 20px;
$icon-h-md: $icon-w-md;
$icon-w-lg: 24px;
$icon-h-lg: $icon-w-lg;
$icon-w-xl: 28px;
$icon-h-xl: $icon-w-xl;
$icon-w-xxl: 32px;
$icon-h-xxl: $icon-w-xxl;
$icon-w-xxxl: 44px;
$icon-h-xxxl: $icon-w-xxl;



/*----- layout ----- */
$registrationInputMarginBottom: 40px;

$scheduleTabsListMargin: $space-sm 0;
$scheduleTabActiveBorderBottom: 2px solid $color-primary;
$scheduleTabPadding: $space-xs;

$scheduleTableBorder: 1px solid $color-table-border;
$scheduleTableSubtitleBorder: 1px solid $color-table-subtitle-border;

$scheduleTableCellSidePadding: 25px;
$scheduleTableCellTopPadding: 15px;
$scheduleTableCellHourTopPadding: 4px;
$scheduleTableCellDailyWeeklyCardPadding: 20px;



/*----- sizes ----- */
$registrationModalFinalWidth: 500px;
$dailyCellHeight: 57px;
$switcher-min-w: 150px;

$scheduleDailyCellWidth: 366px;
$scheduleDailyTimeCellWidth: 200px;
$scheduleDailyTimeCellWidthMobileView: 95px;



/*----- loader ----- */
$loader-w: 56px;
$loader-h: $loader-w;
$loader-w-xs: 32px;
$loader-h-xs: $loader-w-xs;
$loader-w-md: 72px;
$loader-h-md: $loader-w-md;
$loader-w-lg: 88px;
$loader-h-lg: $loader-w-lg;
$loader-w-xl: 94px;
$loader-h-xl: $loader-w-xl;