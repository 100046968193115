.registration-step {
  display: flex;
  flex-direction: column;
  background: $color-modal-background;

  .btn__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: auto;
    margin: 0 (-$space-xl) -92px;
    padding: $space-md $space-xl;
    background: $color-background;

    @include break(767) {
      margin: 0 (-$space-sm) (-$space-xl);
      padding: $space-md $space-sm $space-md;
    }

    @include break(575) {
      flex-direction: column-reverse;
      align-items: center;

      .btn {
        max-width: 330px;
        width: 100%;
      }
    }

    .payment-action {
      margin: 0 0 0 auto;
      max-width: 330px;
      width: 100%;

      @include break(575) {
        margin: 0 0 $space;
      }
    }
  }

  .btn__wrapper--background {
    padding-top: 0;
    background: $color-modal-background;
  }

  .btn__wrapper--single {
    margin-left: -$space-xl;
  }

  .btn--transparent {
    .icon {
      transform: rotate(90deg);
    }
  }

  .tabs {
    margin: 0;
  }

  .tabs__item-shadow {
    display: none;
  }

  .tabs__item {
    padding: 0;
    transition: border-color .15s ease;

    &:hover {
      &:before {
        border: 0px;
      }
    }
  }

  .registration-step__class-option {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: $space-xl $space $space-sm;
    width: 250px;
    height: 210px;
    border: 3px solid $color-light;
    border-radius: 0 $space-lg;
    transition: all .2s ease;
    box-sizing: border-box;
  }

  .registration-step__class-icon {
    margin: 0 0 $space;
    width: auto;
    max-height: $size-point * 9;
    height: auto;
    fill: $color-primary;
    transition: all .15s ease;
  }

  .registration-step__last-date-label {
    position: absolute;
    top: 0;
    left: 0;
    padding: $space-xs * 1.5 $space-xs * 1.5;
    width: 100%;
    font-size: $fz-12;
    font-weight: 400;
    text-align: initial;
    background: $color-light;
    border-radius: 0 40px 0 0;

    b {
      font-weight: 600;
      color: $color-secondary;
    }
  }

  .registration-step__min-rate-label {
    position: absolute;
    bottom: -$space;
    font-size: $fz-14;
    font-weight: 400;

    b {
      font-weight: 700;
      color: $color-primary;
    }
  }

  .registration-step__group {
    margin: 0 0 $space;
  }

  .registration-step__group--price {
    display: flex;
    flex-direction: column;
    margin: 0 0 $space-xl;
  }

  .registration-step__option-title {
    font-size: $fz-14;
    font-weight: 600;
    text-align: center;
    line-height: 1.3;

    b {
      font-weight: 600;
      color: $color-secondary;
    }
  }

  .tabs__item--active {
    .registration-step__class-icon {
      fill: $color-primary;
    }
  }

  .registration-step__instruction {
    margin: 0 0 $space;
    width: 100%;
    font-size: $fz-16;
    font-weight: 600;

    .registration-step__schedule-item {
      margin-left: $space-xs;
    }

    span {
      display: inline;

      .btn {
        display: inline;
        
        span {
          font-size: $fz-16;
          font-weight: 600;
        }
      }
    }
  }

  .registration-step__title {
    font-size: $fz-20;
    font-weight: 600;
  }

  .registration-step__subtitle {
    position: relative;
    display: flex;
    margin: $space-xs 0 0;
    padding: 0 0 0 ($space + 4);
    font-size: $fz-14;

    .icon {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
    }
  }

  .registration-step__group--inactivity-days,
  .registration-step__group--blocks-select {
    .columns {
      margin: 0 (-$space-sm / 1.3) 0;

      .columns__item {
        width: auto;
        padding: 0 ($space-sm / 1.3) 0;
        margin: 0 0 ($space-sm * 1.3);
      }
    }
  }

  .registration-step__group--inactivity-days {
    margin: 0 0 $space-xl;
  }

  .registration-step__group--blocks-select {
    position: relative;

    .columns {
      .columns__item {
        @include break(767) {
          width: calc(100% / 3);
        }

        @include break(575) {
          width: calc(100% / 2);
        }
      }
    }

    .error__wrapper {
      bottom: 0;
    }
  }

  .registration-step__group--date-choose {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 0 $space-xl;

    .registration-step__title {
      margin: 0 0 $space-sm;
      width: 100%;
    }

    .select {
      max-width: 235px;
    }

    .loader__wrapper {
      justify-content: flex-start;
    }

    .registration-step__schedule-note {
      flex-basis: 50%;
      margin-top: -$space;
    }
  }

  .registration-step__group--schedule {
    display: flex;
    align-items: flex-start;
    margin: 0 0 $space-xl;

    @include break(1049) {
      flex-direction: column;
      margin-bottom: 0;
    }

    .list {
      padding: 0 $space-lg 0 0;
      width: 100%;

      @include break(1049) {
        padding: 0;
      }
    }
  }

  .registration-step__group--calendar {
    position: relative;
    margin: 0 0 $space-lg;

    .error__wrapper {
      bottom: -15px;
    }
  }

  .registration-step__schedule-note {
    display: flex;
    flex-basis: 100%;
    padding: $space-sm;
    max-width: 50%;
    font-size: $fz-14;
    background: $color-background;
    border-radius: 5px;

    @include break(1049) {
      margin: $space-md 0;
      max-width: 550px;
      width: 100%;
    }

    .icon {
      margin-right: 16px;
      min-width: 32px;
    }
  }

  .registration-step__group--week-timeslots {
    display: flex;
    align-items: flex-start;
    margin: 0 0 $space-lg;

    @include break(1049) {
      flex-direction: column;
      margin-bottom: 0;
    }

    .list {
      max-width: 50%;
      width: 100%;

      @include break(1049) {
        max-width: 100%;
      }
    }
  }

  .list--drop-in-days {
    .list__item {
      display: flex;
      margin: 0 0 $space-lg;
      width: 100%;

      &:last-child {
        margin: 0;
      }

      .text {
        font-size: $fz-14;
        font-weight: 400;
      }

      .text--drop-in-date {
        max-width: 150px;
        width: 100%;
      }

      .text--drop-in-time {
        position: relative;

        .checkbox:disabled + label {
          &:before,
          &:after {
            display: none;
          }
        }
      }

      .checkbox__container + .checkbox__container {
        margin: $space-xs 0 0;
      }
    }
  }

  .registration-price {
    margin-top: ($space-xxl + 4);

    @include break-reverse(768) {
      margin-right: $space;
    }

    @include break(767) {
      margin: ($space-xxl + 4) auto 0;
      max-width: 330px;
      width: 100%;
    }
  }

  .payment-selector {
    margin-top: ($space-xxl + 4);

    @include break(767) {
      margin: ($space-xxl + 4) auto 0;
      max-width: 330px;
      width: 100%;
    }
  }
}

.registration-step--1,
.registration-step--3,
.registration-step--5 {
  margin: 0 (-$space-xl);
  padding: $space-md $space-xl;

  @include break(767) {
    margin: 0 (-$space-sm + 2) (-$space-md);
    padding: $space-md $space-sm $space-md;
  }

  .tabs {
    @include break(1049) {
      flex-wrap: wrap;
    }
  }

  .tabs__item {
    margin: 0 $space 0 0;
    padding: 0;

    @include break(1049) {
      margin: 0 $space $space-lg 0;
    }

    @include break(767) {
      margin: 0 0 $space-lg;
    }
  }

  .registration-step__tabs {
    @include break-reverse(767) {
      margin: 0 0 $space-xl;
    }

    .tabs__item--active {
      .registration-step__class-option {
        background: $color-background;
        border: 3px solid $color-secondary;
        box-shadow: 0px 4px 20px rgba($color-grey--medium, 0.5);
      }
    }
  }

  .registration-step__tabs--two-rows {
    .tabs__container {
      margin: $space-sm 0 0;
    }
    
    .tabs {
      flex-wrap: wrap;
    }

    .tabs__item {
      margin: 0 $space $space-xxl 0;
    }
  }

  .btn__wrapper {
    position: relative;
  }
}

.registration-step--1 {
  .tabs {
    display: flex;
    justify-content: center;
    margin: 0 0 $space-md;

    @include break(767) {
      flex-direction: column;
    }

    .tabs__item {
      &:not(:last-child) {
        @include break(767) {
          margin: 0 0 $space-md;
        }
      }

      .registration-step__class-option {
        @include break(1049) {
          max-width: 330px;
          width: 100%;
        }
      }

      .registration-step__class-option {
        @include break(767) {
          margin: 0 auto;
        }
      }
    }
  }

  .error__wrapper {
    left: auto;
    top: -160px;
  }

  .btn--primary {
    @include break(575) {
      margin: 0 0 $space;
    }
  }
}

.registration-step--2 {
  display: flex;
  flex-direction: column;
  margin-bottom: -$space-xxl;

  .tabs__container {
    margin-right: -$space-sm;
  }

  .tabs {
    display: flex;
    flex-wrap: wrap;
  }

  .tabs__item {
    flex-shrink: 0;
    flex-basis: calc(25% - 16px);
    margin-bottom: $space-sm;
    padding: $space-xs + $space-xxs $space-sm;
    font-size: $fz-14;
    font-weight: 600;
    color: $color-text-secondary;
    border: 2px solid $color-text-secondary;
    border-radius: 5px;
    box-sizing: border-box;
  }

  .tabs__item--active {
    color: $color-primary;
    border: 3px solid $color-primary;
  }

  .tabs__item:not(:last-child) {
    margin-right: $space-sm;
  }

  .registration-step__option-title {
    text-align: left;

    a {
      font-size: inherit;
      color: inherit;
    }
  }
}

.registration-step--email-verification {
  .registration-step__text {
    margin: 0 0 $space-sm;
    font-size: $fz-16;
    line-height: 1.5;
    text-align: center;

    @include break(575) {
      font-size: $fz-14;
    }
  }

  .registration-step__container {
    .form__group--login-buttons {
      justify-content: center;
      margin: $space 0 $space-xl;

      @include break(575) {
        margin: $space 0 $space-md;
      }
    }

    .form__group--resend {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: $space 0 0;
    }
  }

  .btn {
    margin: 0 auto;
    padding: ($space-sm - 4) $space-xxl;
  }
}

.registration-step--block,
.registration-step--pre-registration {
  .DayPicker-Body {
    pointer-events: none;
  }

  .list__item--time-select {
    position: relative;
    display: flex;
    align-items: center;
    font-size: $fz-16;

    .list__item-label {
      max-width: 130px;
      width: 100%;
      font-weight: 600;

      @include break(767) {
        max-width: 100px;
      }
    }

    .list__item-value {
      padding: ($space-sm - 4) 0;
    }

    .select {
      max-width: 230px;
      width: 100%;
    }

    .error__wrapper {
      left: 130px;
      bottom: -20px;
    }
  }

  .list__item--time-select + .list__item--time-select {
    margin-top: $space-sm;
  }
}

.registration-step__payment-group {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}