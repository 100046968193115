.account-tickets {
  min-height: 300px;
}

.account-tickets__tabs {
  margin: 0 0 $space-md;

  .tabs__container {
    margin: 0;
  }

  .tabs__item {
    margin: 0 $space-xl 0 0;
    padding: 0 0 ($space-sm - 4);

    &:hover {
      &:before {
        bottom: -0.5px;
        height: 2px;
        background: $color-primary;
        border: none;
      }
    }
  }
}

.account-tickets__date-block {
  margin: 0 0 $space-md;

  @include break-reverse(768) {
    margin: 0 0 $space-xxl;
  }
}

.account-tickets__date {
  margin: 0 0 ($space-xs + 2);
  font-size: $fz-16;
  font-weight: 400;

  @include break-reverse(768) {
    margin: 0 0 ($space-sm + 4);
    font-size: $fz-20;
    font-weight: 700;
  }
}

.account-tickets__no-tickets {
  .icon {
    width: 320px;
  }

  p {
    max-width: 430px;
  }
}

.account-tickets-card {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-template-rows: repeat(8, auto);
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  padding: ($space - 2) $space-sm;
  width: 100%;
  background: $color-background;
  border: 1px solid $color-blue;
  border-radius: 25px 0;
  box-shadow: 0 2px 18px rgba($color-text-primary, .08);

  @include break-reverse(768) {
    grid-template-columns: repeat(14, 1fr);
    grid-template-rows: repeat(7, auto);
    padding: $space;
    border-radius: 25px;
  } 
}

.account-tickets-card__ticket-title {
  grid-area: 2 / 1 / 3 / 8;
  font-size: $fz-12;

  @include break-reverse(768) {
    grid-area: 1 / 1 / 2 / 7;
    font-size: $fz-14;
  }
}
.account-tickets-card__purchase-date {
  grid-area: 1 / 1 / 2 / 6;
  font-size: $fz-12;
  text-align: start;

  @include break(767) {
    font-weight: 600;
  }

  @include break-reverse(768) {
    grid-area: 1 / 6 / 2 / 12;
    font-size: $fz-14;
    text-align: end;
  }
}
.account-tickets-card__offering-title { 
  grid-area: 3 / 1 / 4 / 10;

  @include break-reverse(768) {
    grid-area: 2 / 1 / 3 / 8;
  }

  .link,
  .btn {
    margin: 0 0 $space-xs;
  }

  .link__content,
  .btn__content {
    font-size: $fz-16;
    font-weight: 700;

    @include break-reverse(768) {
      font-size: $fz-18;
    }

    span {
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.account-tickets-card__offering-id {
  display: flex;
  align-items: center;
  margin: 0 $space-xxs 0;
  font-size: $fz-12;
  font-weight: 400;
  text-transform: uppercase;
}

.account-tickets-card__count {
  grid-area: 7 / 1 / 8 / 8;
  font-weight: 600;

  @include break(767) {
    margin: 0 0 $space;
  }

  @include break-reverse(768) {
    grid-area: 2 / 7 / 3 / 12;
    text-align: end;
  }
}

.account-tickets-card__offering-instructor {
  grid-area: 4 / 1 / 5 / 10;
  margin: 0 0 $space-xs;
  padding: 0 0 ($space-sm - 4);
  font-size: $fz-14;
  border-bottom: 1px solid $color-blue;

  @include break-reverse(768) {
    grid-area: 3 / 1 / 4 / 12;
  }
}

.account-tickets-card__schedule {
  grid-area: 5 / 1 / 6 / 10;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 8px;
  grid-row-gap: 4px;
  display: grid;
  font-size: $fz-14;

  @include break-reverse(768) {
    grid-area: 4 / 1 / 5 / 12;
  }
}

.account-tickets-card__date-label,
.account-tickets-card__date-item {
  grid-area: auto / 1 / auto / 5;
}

.account-tickets-card__time-label,
.account-tickets-card__time-item {
  grid-area: auto / 5 / auto / 9;
  width: fit-content;
}

.account-tickets-card__studio-label,
.account-tickets-card__studio-item {
  grid-area: auto / 9 / auto / 13;
}

.account-tickets-card__date-item,
.account-tickets-card__time-item,
.account-tickets-card__studio-item {
  position: relative;
  font-weight: 600;
}

.account-tickets-card__date-item,
.account-tickets-card__time-item {
  white-space: nowrap;
}

.account-tickets-card__date-item {
  @include break(767) {
    grid-area: auto / 1 / auto / 2;
    padding: 0 7.5vw 0 0;
  }

  &:before {
    @include break(767) {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(0, -50%);
      width: 2px;
      height: 30px;
      background: $color-black--dark;
    }

    @include break-and-break-reverse(401, 767) {
      height: 15px;
    }
  }
}

.account-tickets-card__time-item {
  @include break(400) {
    min-width: 90px;
    white-space: normal;
  }

  @include break(767) {
    grid-area: auto / 3 / auto / 7;
  }
}

.account-tickets-card__studio-item {
  @include break(767) {
    grid-area: auto / 1 / auto / 13;
    margin: 0 0 $space;
  }
}

.account-tickets-card__attendees {
  grid-area: 6 / 1 / 7 / 10;
  display: flex;
  flex-direction: column;
  margin: 0 0 ($space-sm - 4);
  font-size: $fz-14;

  @include break(767) {
    padding: 0 0 ($space-sm - 4);
    border-bottom: 1px solid $color-blue;
  }

  @include break-reverse(768) {
    grid-area: 5 / 1 / 6 / 12;
    margin: $space-sm 0 $space;
  }

  span {
    font-weight: 600;
  }
}

.account-tickets-card__actions {
  display: flex;
  grid-area: 8 / 1 / 9 / 6;
  margin: 0 (-$space-xs);

  @include break-reverse(768) {
    grid-area: 6 / 1 / 7 / 12;
    margin: 0 (-$space);
  }

  button {
    padding: 0 $space-xs;

    @include break-reverse(768) {
      padding: 0 $space;
    }
  }
}

.account-tickets-card__additional {
  position: relative;
  grid-area: 1 / 12 / 7 / 15;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 0 $space-sm;
  padding: 0 0 0 $space;
  border-left: 2px dashed $color-blue;

  &:after,
  &:before {
    content: "";
    position: absolute;
    left: -1px;
    width: 34px;
    height: 17px;
    background: $color-grey--lightish;
  }

  &:before {
    bottom: -21px;
    transform: translate(-50%, 50%);
    border-top: 1px solid $color-blue;
    border-radius: 50% 50% 0 0 / 100% 100% 0 0;
  }

  &:after {
    top: -17px;
    transform: translate(-50%, -50%);
    border-bottom: 1px solid $color-blue;
    border-radius: 0 0 50% 50% / 0 0 100% 100%;
  }
}

.account-tickets-card__logo {
  @include break(767) {
    grid-area: 8 / 1 / 9 / 10;
    display: flex;
    justify-content: flex-end;
  }
}

.account-tickets-card__price {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: $fz-14;
  font-weight: 600;

  b {
    font-size: $fz-32;
  }
}

.account-tickets-card__file-actions {
  display: flex;
  justify-content: center;
  width: 100%;

  @include break(767) {
    grid-area: 7 / 9 / 8 / 10;
    justify-content: flex-end;
    margin: 0 0 $space;
  }

  .btn + .btn {
    margin: 0 0 0 $space-md;
  }
}