.btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  text-decoration: none;
  border: none;
  user-select: none;
  transition: all .3s ease;
  cursor: pointer;

  path,
  span {
    transition: all .3s ease;
  }
}

.icon {
  display: block;
}

.btn__icon--left {
  margin-right: $space-xs;
}

.btn__icon--right {
  margin-left: $space-xs;
}

.btn--primary,
.btn--secondary,
.btn--outline {
  padding: ($space-sm - 3) $space-md;
  font-size: $fz-18;
  background: $color-primary;
  border-radius: 24px;

  span {
    color: $color-text-light;
  }

  .icon {
    fill: $color-text-light;
  }
}

.btn--primary {
  &:hover {
    background: $color-secondary;
  }
}

.btn--secondary {
  background: $color-secondary;
  border: 1px solid $color-secondary;

  &:hover {
    background: $color-background;

    span {
      color: $color-secondary;
    }
  }
}

.btn--secondary-outline,
.btn--primary-outline {
  font-weight: 600;
  border: 1px solid;
  border-radius: 4px;
}

.btn--primary-outline {
  border-color: $color-primary;

  span {
    color: $color-primary;
  }

  path {
    fill: $color-primary;
    stroke: $color-primary;
  }

  &:hover {
    background: $color-primary;

    span {
      color: $color-text-light;
    }

    path {
      fill: $color-text-light;
      stroke: $color-text-light;
    }
  }
}

.btn--secondary-outline {
  border-color: $color-secondary;

  span {
    color: $color-secondary;
  }

  path {
    fill: $color-secondary;
    stroke: $color-secondary;
  }

  &:hover {
    background: $color-secondary;

    span {
      color: $color-text-light;
    }

    path {
      fill: $color-text-light;
      stroke: $color-text-light;
    }
  }
}

.btn--transparent {
  padding: ($space-sm - 3) $space-md;
  font-size: $fz-18;
  background: transparent;
  border: 1px solid $color-primary;
  border-radius: 24px;

  span {
    color: $color-primary;
  }

  .icon {
    fill: $color-primary;
  }

  &:hover {
    border: 1px solid $color-secondary;

    .icon {
      path {
        fill: $color-secondary !important;
      }
    }

    .btn__content {
      color: $color-secondary;
    }
  }
}

.btn--navigation {
  padding: 0;
  font-size: $fz-14;
  background: transparent;
  border-radius: 0;

  span {
    color: $color-primary;
  }

  .icon {
    fill: $color-primary;
  }

  &:hover {
    text-decoration-color: $color-secondary;

    .icon {
      fill: $color-secondary;
    }

    span {
      color: $color-secondary;
    }
  }
}

a.btn--navigation {
  &:hover {
    text-decoration: none;

    span {
      color: $color-primary;
    }
  }
}

.btn--link {
  padding: 0;
  font-size: $fz-14;
  font-weight: 400;
  text-decoration: underline;
  color: $color-primary;
  border-radius: 0;

  span {
    color: $color-primary;
  }

  .icon {
    fill: $color-primary;
  }

  &:hover {
    text-decoration: none;
  }
}

.btn--outline {
  background: $color-background;
  border: 1px solid $color-primary;
  border-radius: ($b-rad * 2) 0;

  span {
    color: $color-primary;
  }

  &:hover {
    background: $color-primary;

    span {
      color: $color-text-light;
    }
  }
}

.btn__wrapper {
  margin-left: -$space-md;
  padding: $space-md 0;
  width: 100%;
}

.btn--disabled {
  opacity: .7;
  cursor: not-allowed;
}

.btn__content {
  font-size: inherit;
  font-weight: inherit;
  text-align: left;
  color: inherit;
}

.btn--schedule-more {
  font-size: $fz-14;
  font-weight: 600;
  line-height: 1.4;
}

.btn--filters-menu {
  padding: ($space-xs - 2) ($space-sm - 2);
  font-size: $fz-14;

  .icon {
    margin: ($space-xxs - 1);
    width: 18px;
    height: 18px;
  }

  @include break(1049) {
    .btn__icon {
      margin-right: 0;
    }

    .counter {
      margin-right: $space-xs;
    }
  }
}

.btn--sorting-menu {
  font-size: $fz-14;
  padding: ($space-xs + 1) ($space-sm - 2);

  .icon {
    width: 30px;
    height: 17px;

    path:last-of-type {
      fill: $color-grey--darkish;
    }
  }

  @include break-reverse(768) {
    margin-left: auto;
    margin-right: $space;
  }
}

.btn--sorting-menu--desc {
  .icon {
    transform: rotate(180deg);
  }
}