.header {
  .tabs__item-shadow {
    top: auto;
    bottom: -($space-xs * 1.5);
    border-bottom-width: 3px;
  }

  .tabs__item {
    font-size: $fz-16;

    &:not(:last-of-type) {
      // margin: 0 2vw 0 0;

      @include break-reverse(1441) {
        margin: 0 $space-lg 0 0;
      }
    }

    &:hover:not(.tabs__item--active) {
      &:before {
        bottom: -($space-xs * 1.5);
        border-bottom-width: 3px;
      }
    }
  }

  .tabs__item--active {
    color: $primary--70;
  }
}

.header--active {
  .header__content {
    padding: ($space - 1.5) 0;

    &:before {
      z-index: 25;
    }
  }

  .header__go-back {
    z-index: 30;
  }
}

.header__content {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: ($space-xs + 4) 0;
  background: $color-background;

  &:before {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100vw;
    background: $color-background;
    box-shadow: 0 4px 20px rgba(#cdd1d9, .35);
  }
}

.header__logo {
  display: inline-block;

  @include break(1049) {
    padding: ($space-sm - 6) 0 !important;
  }

  img {
    max-width: 225px;
  }
}

.header__nav {
  @include break-reverse(1050) {
    display: flex;
    align-items: center;
  }

  .list {
    @include break-reverse(1050) {
      display: flex;
      align-items: center;
    }

    .list__item {
      &:not(:last-of-type) {
        @include break-reverse(1050) {
          margin: 0 2.25vw 0 0;
        }

        @include break-reverse(1441) {
          margin: 0 $space-lg 0 0;
        }
      }
    }
  }

  .tabs__container {
    margin: 0;
  }
}

.header__nav--pages {
  margin: 0 2.25vw;
  
  @include break-reverse(1441) {
    margin: 0 $space-lg;
  }
}

.header__nav--auth {
  position: relative;

  @include break(1049) {
    width: 100%;
  }

  &:after {
    @include break(1049) {
      content: '';
      position: absolute;
      top: 0px;
      left: -60px;
      right: 0;
      width: calc(100% + 60px);
      height: 1px;
      background: $color-blue--medium;
    }
  }
}

.header__button {
  &.link {
    @include break(1049) {
      justify-content: flex-start;
      width: 100%;
    }
  }
}

.header__go-back {
  white-space: nowrap;

  .icon {
    transform: rotate(90deg);
  }
}

.header__donation-btn {
  .btn__content {
    color: $color-secondary;
  }
}

.header__donation-tooltip.tooltip--bottom {
  .tooltip__content {
    left: -170px;
    right: 20px;
    font-size: $fz-12;

    @include break(1049) {
      display: none;
    }
  }
  .tooltip__wrapper {
    padding: $space-xs ($space-sm + 2);
  }
}