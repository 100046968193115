.input--phone {
  .input__wrapper {
    border-bottom: 1px solid rgba($neutral--70, .2);

    &:has(.flag-dropdown.open) {
      border-bottom: 1px solid $color-primary;
    }
  }

  .input__wrapper--focused {
    border-bottom: 1px solid $color-primary;
  }

  .input__wrapper--has-error {
    border-bottom-color: $color-error;
  }

  .input__wrapper--disabled {
    opacity: .7;
  } 

  .react-tel-input  {
    display: flex;
    align-items: center;
  }

  .flag-dropdown {
    position: static;
    order: 1;
    background: transparent;
    border: none;

    &.open {
      background: transparent;

      &:before {
        content: "";
        position: absolute;
        z-index: -1;
        margin: -2px;
        width: 100%;
        height: calc(100% + 250px);
        box-shadow: 0 4px 20px $primary--30;
        transition: $transition-time * 1.5 all;
      }
    }

    .divider {
      display: none;
    }

    .selected-flag {
      padding: 0;
      width: 60px;
      height: 40px;

      &:hover,
      &:focus,
      &.open {
        background: transparent !important;
      }

      &:before {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(-9px, -50%);
        width: 1px;
        height: 65%;
        background: rgba($neutral--70, .2);
      }

      .flag {
        left: calc(50% - 5px);
        transform: translate(-50%, -50%);
        margin: 0;
      }

      .arrow {
        display: none;
      }
    }
  }

  .country-list {
    top: 100%;
    transform: translate(0, 1px);
    margin: 0;
    width: 100%;
    max-height: fit-content;
    height: 250px;
    box-shadow: none;

    .search {
      margin: 0 0 $space-xs;
      padding: $space-xs $space-xs 0;
      width: 100%;

      .search-emoji {
        display: none;
      }

      .search-box {
        margin: 0;
        padding: ($space-sm - 4) $space-sm;
        width: 100%;
        background: $primary--30;
        border: none;
        border-radius: 5px;

        &::placeholder {
          color: $color-text-secondary;
          opacity: 1;
        }
        
        &::-webkit-search-cancel-button {
          display: none;
        }
      }
    }

    .country {
      display: flex;
      align-items: center;
      padding: ($space-sm - 4) $space;

      &:hover {
        background: $primary--30;
      }

      &.highlight {
        background: transparent;

        &:hover {
          background: $primary--30;
        }

        &:before {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 1px;
          width: 1.5px;
          background: $color-primary;
          border-radius: 10px;
          transition: $transition-time * 1.5 all;
        }
      }

      .flag {
        position: static;
        margin: 0 $space-xs 0 0;
      }

      .dial-code {
        margin: 0 0 0 auto;
      }
    }
  }

  .input__element {
    order: 2;
    padding: ($space-sm - 4) 0;
    width: 100%;
    height: auto;
    font-size: $fz-16;
    line-height: 1.25;
    background: transparent;
    color: $color-text-primary;
    border: none;
    border-radius: 0;
  } 
}