.columns {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 (-$space + 4) 0 (-$space + 4);
}

.columns__item {
  padding: 0 ($space - 4) 0 ($space - 4);
  width: 100%;
}

.columns--2 {
  .columns__item {
    width: calc(100% / 2);
  }
}

.columns--3 {
  .columns__item {
    width: calc(100% / 3);
  }
}

.columns--4 {
  .columns__item {
    width: calc(100% / 4);
  }
}

.columns--5 {
  .columns__item {
    width: calc(100% / 5);
  }
}
