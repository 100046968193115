.provider-about {
  margin: (-$space-md - 4) 0 0;

  

  /*----- base styles ----- */
  .provider-about__subtitle {
    margin: 0 0 $space;
    font-size: $fz-20;
    font-weight: 600;
    line-height: 1.3;
    color: $neutral--70;

    @include break-reverse(768) {
      margin: 0 0 $space-md;
    }
  }



  /*----- title block ----- */
  .provider-about__title-block {
    padding: $space-lg 0 ($space - 4);

    .global-page__title {
      padding: 0 ($space-xs + 2);
    }
  }



  /*----- mission block ----- */
  .about-mission {
    padding: ($space - 4) ($space-xs + 2);
  }

  .about-mission__body {
    display: flex;
    flex-direction: column;

    @include break-reverse(768) {
      flex-direction: row;
    }
  }

  .about-mission__visual {
    border-radius: 50px 0;
    overflow: hidden;

    @include break-reverse(768) {
      flex-direction: row;
      width: 32%;
    }

    @include break-reverse(1441) {
      max-height: 590px;
    }
  
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .about-mission__content {
    display: flex;
    flex-direction: column;
    padding: $space-md 0 0;
  
    @include break-reverse(768) {
      width: 68%;
      padding: 0 4.2vw;
    }
    
    @include break-reverse(1441) {
      padding: 0 $space-xxxl;
    }

    .mission-text-block {
      &:not(:last-of-type) {
        margin: 0 0 $space-sm;

        @include break-reverse(768) {
          margin: 0 0 $space;
        }
      }
    }

    .mission-text-block--bold {
      padding: 0 0 0 $space-sm;
      font-size: $fz-20;
      font-weight: 600;
      line-height: 1.3;
    }
  }



  
  /*----- objectives block ----- */
  .about-objectives {
    padding: ($space - 4) 0 $space-lg;

    @include break-reverse(768) {
      padding: ($space - 4) ($space-xs + 2) $space-xl;
    }

    .provider-about__subtitle {
      padding: 0 ($space-xs + 2);

      @include break(767) {
        margin: 0;
      }
    }
  }

  .about-objectives__body {
    @include break-reverse(768) {
      display: flex;
      flex-wrap: wrap;
      margin: 0 (-$space-xs - 2);
    }
  }

  .about-objectives__slider {
    padding: $space ($space-xs + 2) $space-md;
  }

  .about-objectives__slide,
  .about-objectives__item {
    .objectives-card {
      padding: $space;

      @include break-reverse(768) {
        justify-content: space-between;
        padding: $space $space-md;
      }

      @include break-reverse(992) {
        padding: $space-md $space-lg;
      }
    }

    .objectives-card__title-block {
      margin: 0 0 $space-sm;

      h4 {
        font-weight: 600;
      }
    }
  }

  .about-objectives__item {
    @include break-reverse(768) {
      display: flex;
      margin: 0 0 $space-md;
      padding: 0 ($space-xs + 2);
      width: 50%;
    }

    @include break-reverse(992) {
      width: calc(100% / 3);
    }
  }



  /*----- testimonials block ----- */
  .about-testimonials {
    position: relative;
    padding: $space-lg ($space-xs + 2);
    background: $primary--20;

    @include break-reverse(768) {
      padding: ($space-lg * 2) ($space-xs + 2);
    }

    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
      width: 100vw;
      background: $primary--20;
    }
  }

  .about-testimonials__title-block {
    .provider-about__subtitle {
      margin: 0 0 $space-xs;
    }

    .global-page__title {
      max-width: 325px;

      @include break-reverse(768) {
        max-width: 625px;
      }
    }
  }

  .about-testimonials__slider {
    padding: $space-lg 0 $space-md;
  }

  .about-testimonials__slide {
    .testimonials-card__title-block {
      margin: 0 0 $space-xs;

      @include break-reverse(768) {
        margin: 0 0 $space-sm;
      }

      h4,
      p {
        font-weight: 600;
      }
    }
  }



  /*----- programs block ----- */
  .about-programs {
    padding: ($space - 4) 0;

    @include break-reverse(768) {
      padding: $space-lg 0;
    }
  }

  .about-programs__title-block {
    display: flex;
    flex-direction: column;
    padding: 0 ($space-xs + 2);

    @include break-reverse(768) {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
    }
  }

  .about-programs__title-block-top {
    margin: 0 0 $space;

    @include break-reverse(768) {
      margin: 0;
      max-width: 500px;
    }

    @include break-reverse(992) {
      max-width: 650px;
    }

    .provider-about__subtitle {
      margin: 0 0 $space-xs;
    }

    .global-page__title {
      margin: 0 0 $space-sm;
      max-width: 275px;

      @include break-reverse(768) {
        max-width: 400px;
      }
    }
  }
  
  .about-programs__title-block-bottom {
    .link {
      span {
        font-size: $fz-20;
        line-height: 1;
      }
    }
  }

  .about-programs__slider {
    padding: $space ($space-xs + 2) $space-md;

    @include break-reverse(768) {
      padding: $space-md ($space-xs + 2);
    }
  }



  /*----- sponsors block ----- */
  .about-sponsors {
    padding: ($space - 4) 0 $space-lg;

    @include break-reverse(768) {
      padding: $space-lg 0 ($space-lg * 2);
    }

    .sponsors-block__title-block {
      padding: 0 ($space-xs + 2);
    }

    .sponsors-block__slider {
      padding: $space-md ($space-xs + 2);
    }
  }
}