.programs__courses-column {
  flex-grow: 1;
  width: calc(100% - 348px);
}

.programs-course {
  display: flex;
  flex-direction: column;
  background: $color-background;
  border-radius: 50px 0;
  box-shadow: 0 4px 10px rgba(#cdd1d9, .7);
  overflow: hidden;

  .offering-details__badges {
    margin: 0 0 $space-xxs;
  }
}

.programs-course--full {
  .programs-course__logo {
    bottom: 0;
    left: 0;
    transform: translate(0, 50%);
    width: 104px;
    height: 56px;
    border-radius: 20px 0px;
  }
}

.programs-course--empty {
  .programs-course__logo {
    padding: 0;
    height: 100%;
    background: $primary--20;
    border-radius: 50px 0;
    overflow: hidden;
  }
}

.programs-course--programs {
  margin: 0 0 $space;

  @include break-reverse(768) {
    flex-direction: row;
    padding: $space-md;
  }

  &.programs-course--full {
    @include break-reverse(768) {
      min-height: 300px;
    }
  
    .programs-course__logo {
      @include break-reverse(768) {
        width: 88;
        height: 56;
      }
    }
  }

  .offering-details__badges {
    margin: 0 0 $space-xxs;

    @include break-reverse(768) {
      margin: 0 0 ($space-sm - 4);
    }
  }

  .programs-course__visual {
    @include break-reverse(768) {
      min-width: 200px;
      max-width: 200px;
    }
  }

  .programs-course__visual-body {
    @include break-reverse(768) {
      position: relative;
    }

    img {
      @include break-reverse(768) {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }
  }

  .programs-course__content {
    @include break-reverse(768) {
      margin: 0 0 0 $space;
      padding: 0;
      min-height: 200px;
    }
  }

  .programs-course__title-blok {
    @include break-reverse(768) {
      margin: 0 0 $space-xs;
    }
  
    h3 {
      .link,
      .btn {
        @include break-reverse(768) {
          -webkit-line-clamp: 2;
        }
  
        span {
          @include break-reverse(768) {
            max-width: 100%;
            font-size: $fz-20;
          }
        }
  
        .icon {
          @include break-reverse(768) {
            transform: translate(0, 0);
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }

  .programs-course__course-description {
    @include break-reverse(768) {
      margin: 0 0 $space-sm;
    }
  }

  .programs-course__schedule-button {
    @include break-reverse(768) {
      margin: auto 0 0 auto;
    }
  
    .btn__icon {
      @include break-reverse(768) {
        margin: $space-min 0 0 $space-xs;
      }
    }
  
    .icon {
      @include break-reverse(768) {
        width: 14px;
        height: 14px;
      }
    }
  }
}

.programs-course--about  {
  .programs-course__visual {
    @include break-reverse(992) {
      height: 225px;
    }
  }

  .programs-course__content {
    height: calc(100% - 175px);

    @include break-reverse(576) {
      height: calc(100% - 200px);
    }

    @include break-reverse(768) {
      padding: $space-lg $space-sm $space;
    }

    @include break-reverse(992) {
      padding: $space-xl $space $space;
      height: calc(100% - 225px);
    }
  }

  .programs-course__content-body {
    height: 100%;
  }
}

.programs-course__visual {
  position: relative;
  width: 100%;
  height: 175px;

  @include break-reverse(576) {
    height: 200px;
  }
}

.programs-course__visual-body {
  height: 100%;
  background: $primary--20;
  border-radius: 50px 0;
  overflow: hidden;

  img {
    margin: 0 auto;
    width: auto;
    height: 100%;
  }
}

.programs-course__content {
  padding: $space-lg $space-sm $space-sm;
  width: 100%;
}

.programs-course__content-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.programs-course__content-top {
  display: flex;
  flex-direction: column;
}

.programs-course__content-bottom {
  display: flex;
  justify-content: space-between;
  margin: $space-xs 0 0;
}

.programs-course__course-id {
  margin: 0 0 $space-xxs;
  font-size: $fz-14;
  color: $neutral--50;
}

.programs-course__title-blok {
  margin: 0 0 $space-xxs;

  @include break-reverse(768) {
    margin: 0 0 $space-xs;
  }

  h3 {
    .link,
    .btn {
      position: relative;
      display: block;
      display: -webkit-box;
      padding: 0 $space-xl 0 0;
      width: 100%;
      text-align: left;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;

      span {
        max-width: 275px;
        font-size: $fz-18;
        word-break: normal;
      }

      .icon {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-6px, 0);
        width: 25px;
        height: 25px;
      }
    }
  }
}

.programs-course__course-description {
  margin: 0 0 $space-xs;
}

.programs-course__instructors {
  display: inline-flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 0 0 $space-xs;

  .link {
    span {
      font-size: $fz-16;
    }
  }

  .link__icon {
    margin: 0 $space-xxs 0 0;
  }
}

.programs-course__requirements {
  margin: 0 0 $space-xs;;
}

.programs-course__upcomming-date {
  display: flex;
  flex-direction: column;
}

.programs-course__upcomming-title {
  margin: 0 0 $space-xxs;
  font-weight: 600;
}

.programs-course__schedule-button {
  margin: auto $space-xs 0 auto;
  font-size: $fz-16;
}

.programs-course__schedule-button--active {
  .icon {
    transform: rotate(180deg);
  }
}

.programs-course__sessions {
  padding: $space-md 0 0;

  .sessions__item {
    border: none;
    padding: 0;
    margin: 0 0 $space;

    .ftable__cell {
      flex: initial;
      min-width: 200px;
      margin: 0 0 $space-xxs;
    }

    .ftable__cell-content {
      font-size: inherit;
      line-height: inherit;
    }

    .sessions__date-time-content {
      white-space: nowrap;
    }

    .ftable__row--first {
      .sessions__date {
        @include break(767) {
          margin: 0 0 $space-xs;
        }
      }
    }
  }
}

.programs-course__sessions-title {
  margin: 0 0 $space-sm;
  font-weight: 600;
}