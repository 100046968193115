.info-box {
  border: 1px solid $color-grey--darkish;
  transition: ($transition-time * 3) all $cubic-default;
  overflow: auto;
}

.info-box--policy {
  @include break(767) {
    height: 350px!important;
  }
}

.info-box--terms {
  &.info-box--shown {
    margin: 0 0 $space;
    padding: $space-md;
  }
}

.info-box--media-waiver {
  margin: 0 0 $space-sm;
  padding: $space-sm;
  font-size: $fz-12;
  line-height: 1.5;
}
