.programs__filter-column {
  max-width: 348px;

  .form__section--action-buttons {
    margin: 0 0 $space;
  }

  .form__group {
    margin: 0 0 $space-sm;
  }

  .programs__filter-age-range-container {
    margin: 0 0 $space-xxs;
  }
}

.programs__filter-action-buttons {
  display: flex;

  .btn {
    span {
      font-size: $fz-14;
    }
  }
}

.programs__filter-clear-all {
  padding: ($space-xs + 2) ($space-sm);
}

.programs__filter-check-all {
  font-size: $fz-12;
  font-weight: 400;
}

.programs__filter-view-more {
  font-weight: 400;

  .icon {
    fill: $color-primary;
  }
}

.programs__filter-view-more--open {
  .icon {
    transform: rotate(180deg);
  }
}

.programs__filter-age-block {
  display: flex;
  justify-content: space-between;

  .programs__filter-age-inputs {
    display: flex;
  }

  .input {
    width: 40px;

    &:not(:first-of-type) {
      margin: 0 0 0 $space-xs;
    }
  }

  .input__element {
    font-weight: 600;
    color: $color-primary;
  }
}