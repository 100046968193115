.checkout-table {
  font-size: $fz-14;
  font-weight: 600;
  color: $color-text-primary;
  border: 1px solid $color-blue;
  border-radius: 10px;
  overflow: hidden;

  p {
    margin: 0;
  }
}

.checkout-table--desktop-only {
  display: grid;

  .checkout-table__row {
    display: grid;
    grid-template-columns: 35px 130px 160px 1fr 60px;
    padding: $space-sm $space-sm;
    border-top: 1px solid $color-blue;

    &:first-of-type {
      background: $neutral--10;
      color: $color-grey;
      border: 0;
    }
  }

  .checkout-table__row:not(:first-of-type) {
    background: $color-background;
  }

  .checkout-table__label,
  .checkout-table__value {
    padding: 0 $space 0 0;

    &:first-of-type {
      padding: 0 $space-xs 0 0;
    }

    &:last-of-type {
      padding: 0;
      text-align: right;
    }
  }

  .checkout-table__discount,
  .checkout-table__increase {
    display: block;
    margin: $space-sm 0 0;

    .discount__label,
    .increase__label {
      display: block;
      font-size: $fz-12;
      font-weight: 500;
      font-style: italic;
    }
  }
}

.checkout-table--mobile-only {
  .checkout-table__block {
    display: grid;
    padding: 0 0 $space;
    background: $color-background;

    &:first-of-type .checkout-table__row:first-of-type {
      border-top: none;
    }
  }

  .checkout-table__row {
    display: grid;
    grid-template-columns: 42% 58%;
    padding: $space-sm $space-sm 0 $space-sm;
  }

  .checkout-table__row:first-of-type {
    display: block;
    padding: $space-sm;
    background: $neutral--10;
    border: solid $color-blue;
    border-width: 1px 0;

    .checkout-table__value {
      font-size: $fz-16;
      font-weight: 600;
      text-align: left;
    }
  }

  .checkout-table__label {
    font-weight: 400;
  }

  .checkout-table__value {
    text-align: right;
  }
}