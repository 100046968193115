.wrong-page {
  position: relative;
  height: calc(100vh - 99px);

  .btn {
    margin: $space-lg 0 0;

    @include  break(1049) {
      margin: $space 0 0;
    }
  }
}

.wrong-page__error-code {
  font-size: 288px;
  font-weight: 900;
  line-height: 1;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 4px $color-primary;

  @include break(1049) {
    font-size: 240px;
  }

  @include break(767) {
    font-size: 140px;
  }
}

.wrong-page__title {
  font-size: $fz-28;
  font-weight: 600;
  line-height: 1.15;
  color: $color-primary;

  @include break(767) {
    font-size: $fz-22;
  }
}

.wrong-page__description {
  margin: $space-sm 0 0;
  max-width: 515px;
  width: 100%;
  line-height: 32px;

  @include break(1049) {
    margin: 0;
  }

  @include break(767) {
    font-size: $fz-14;
  }
}

.icon--not-found {
  position: absolute;
  z-index: -1;
  bottom: 25px;
  right: 0;
  width: auto;
  height: 600px;

  @include break(1049) {
    max-width: 650px;
    height: auto;
  }

  @include break(767) {
    position: static;
    max-width: 100%;
  }
}

.icon--server-error {
  position: absolute;
  bottom: 120px;
  right: 0;
  width: auto;
  height: 600px;

  @include break(1049) {
    z-index: -1;
    bottom: 50px;
  }

  @include break(767) {
    position: absolute;
    bottom: -185px;
    max-width: 100%;
  }
}
